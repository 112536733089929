import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DonloadFiles } from "../icons";
import { sec2hhmm } from "../plan/SearchDirections";

//機能説明をtooltipで表示できるInfoIcon
export const DownloadPlans = ({
  planRoutes,
  checkedItems,
  fleetName,
  carName,
}) => {
  const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  function exportPlanInfoAsCSV() {
    const checkedIDs = [];
    Object.keys(checkedItems).forEach((key) => {
      if (checkedItems[key] === true) {
        checkedIDs.push(key);
      }
    });

    //checkありのplanを配列で取得
    const exportedPlanRoutes = planRoutes.filter((plan) =>
      checkedIDs.includes(plan.plan_id)
    );

    //各planについてPointが存在すればcsv出力する
    //choromeだと一度に10ファイルしかダウンロードできないため、awaitを入れて回避する
    (async () => {
      for (let planRoute of exportedPlanRoutes) {
        await _sleep(1000);
        //console.log(planRoute.plan_name);
        const { plan_name, points, distance, time } = planRoute;
        const fileName = `${fleetName}-${carName}-${plan_name}.csv`;

        if (points === undefined) {
          return;
        }

        const origin = points.filter((p) => p.type === "origin")[0];
        const destination = points.filter((p) => p.type === "destination")[0];
        const waypoint = points.filter((p) => p.type === "waypoint");

        let csvData = "type,lat,lng,comment,distance_km,time_sec\r\n";

        const originRow = returnCSVrow({
          point: origin,
          distance_km: distance / 1000,
          time,
        });
        csvData += originRow;

        waypoint.forEach((p) => {
          const waypointRow = returnCSVrow({ point: p });
          csvData += waypointRow;
        });

        const destinationRow = returnCSVrow({ point: destination });
        csvData += destinationRow;

        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        let blob = new Blob([bom, csvData], { type: "text/csv" });
        let url = (window.URL || window.webkitURL).createObjectURL(blob);

        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.click();
      }
    })();
  }
  return (
    <>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-right`}>
            you can download checked plans infomation as csv
          </Tooltip>
        }
      >
        <div style={{ padding: "0px 5px" }} onClick={exportPlanInfoAsCSV}>
          <DonloadFiles />
        </div>
      </OverlayTrigger>
    </>
  );
};

//csvの一行分をテキストで返す
//列区切りはカンマ、最後は\r\nで終えるｓ
function returnCSVrow({ point, distance_km, time }) {
  const { type, lat, lng, comment } = point;
  const replaceComment =
    comment === undefined ? "" : comment.replace("\n", " ");
  //コメントに改行がある場合は空白におきかえる

  if ((distance_km === undefined) | (time === undefined)) {
    const rowText = `${type},${lat},${lng},${replaceComment}\r\n`;
    return rowText;
  } else {
    const rowText = `${type},${lat},${lng},${replaceComment},${distance_km},${sec2hhmm(
      time
    )}\r\n`;
    return rowText;
  }
}
