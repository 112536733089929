/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewPoi
 */
export interface NewPoi {
    /**
     * 
     * @type {string}
     * @memberof NewPoi
     */
    s3_filename: string;
    /**
     * 
     * @type {string}
     * @memberof NewPoi
     */
    title: string;
}

export function NewPoiFromJSON(json: any): NewPoi {
    return NewPoiFromJSONTyped(json, false);
}

export function NewPoiFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPoi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        's3_filename': json['s3_filename'],
        'title': json['title'],
    };
}

export function NewPoiToJSON(value?: NewPoi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        's3_filename': value.s3_filename,
        'title': value.title,
    };
}


