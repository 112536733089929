import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { ModalPointDistanceCheck } from "./modal/ModalPointDistanceCheck";
import melodykakao from "./sound/melodykakao.mp3"
import waterdrop from "./sound/waterdrop.mp3"

export const TestPositionPane = ({
  getPosition,
  location,
  stopWatch,
  watchId,
  route,
}) => {
  //ISA
  const [onWatchPositionProcess, setOnWatchPositionProcess] = useState(false);
  const [pointIndex, setPointIndex] = useState(-1);
  const [beforePosition, setBeforePosition] = useState([]);
  const [pointsList, setPointsList] = useState(()=>{
    if (route == undefined){
      return []
    }else{
      return []
    }
  });
  const [checkModalContent, setCheckModalContent] = useState("");
  const [modalShowedAlready, setModalShowedAlready] = useState(-1);
  const alertDistanceRef = useRef(null);
  const [alertDistance, setAlertDistance] = useState(-1)

  const [distanceCheckModalShow, setDistanceCheckModalShow] = useState(false);

  const [allRoadData, setAllRoadData] = useState([]);
  const [allPinData, setAllPinData] = useState([]);

  //haver sine
  function haverSine(one, two){
    const myLat=one[0]
    const myLon=one[1]
    const targetLat = two[0]
    const targetLon = two[1]
    //console.log(myLat, myLon, targetLat, targetLon )

    const radius = 6371
    const toRadian =Math.PI/180

    const deltaLat =Math.abs(myLat - targetLat) * toRadian
    const deltaLon =Math.abs(myLon - targetLon) * toRadian

    const sinDeltaLat = Math.sin(deltaLat / 2)
    const sinDeltaLon = Math.sin(deltaLon / 2)
    const squareRoot = Math.sqrt(
      sinDeltaLat * sinDeltaLat +
      Math.cos(myLat * toRadian) * Math.cos(targetLat * toRadian) * sinDeltaLon * sinDeltaLon)

    const distance = 2 * radius * Math.asin(squareRoot)

    return distance.toFixed(3)*1000
  }

  function distanceCheckModalOKClick(){
    setDistanceCheckModalShow(false)
  }

  function watchPositionOnclick(){
    if(alertDistanceRef.current.value ==""){
      alert("set Alert distance first")
    }else if(isNaN(alertDistanceRef.current.value)){
      alert("Alert distance must be integer")
    }else if(parseInt(alertDistanceRef.current.value)>1000 ||parseInt(alertDistanceRef.current.value)<50){
      alert("rule: 50 <= Alert distanc <= 1000")
    }else{
      if(route.path_geojson == undefined){
        alert("There is no route!")
        return
      }
      setAlertDistance(parseInt(alertDistanceRef.current.value))
      const startT = Date.now()
      //console.log("hey",route.points)
      let road = route.path_geojson.geometry.coordinates
      const routePoints = route.points
      const arrRoad = [] //(lat, lon, index, distance, fulldistance)
      const arrPoints =[] //(roadIndex,pinID)
      let usedIndex =0

      //Make arrRoad
      for (let s=0; s<road.length; s ++){
        if(s == 0){
          arrRoad.push([road[s][1],road[s][0],s,0,0])
        }else{
          const pointDistance = haverSine([road[s][1],road[s][0]],[road[s-1][1],road[s-1][0]])
          arrRoad.push([road[s][1],road[s][0],s,pointDistance,pointDistance+arrRoad[s-1][4]])
        }
      }

      //Make arrPoints
      for (let i=0; i<routePoints.length; i++){
        const pinInfo = routePoints[i]
        const pinId = pinInfo.id
        const pinLocation = [pinInfo.lat,pinInfo.lng]
        if (i == 0){
          arrPoints.push([0,pinId])
          usedIndex = 0
        }else if (i ==routePoints.length-1){
          arrPoints.push([arrRoad.length-1,pinId])
        }else{
          // method1(1.002s)
          // let minValue =[null,-1]
          // for(let j= usedIndex; j<arrRoad.length; j++){
          //   let thisDistance = haverSine(pinLocation,[arrRoad[j][0],arrRoad[j][1]])
          //   if(minValue[0]==null || minValue[1]>thisDistance){
          //     minValue=[j,thisDistance,arrRoad[j][0],arrRoad[j][1]]
          //   }
          // }
          // usedIndex = minValue[0]
          // arrPoints.push([usedIndex,pinId])

          //method2(0.083s)
          const filteredRoad = arrRoad.filter((point)=>
            Math.abs(pinLocation[0]-point[0])<=0.002 && Math.abs(pinLocation[1]-point[1])<=0.002 && point[2]>=usedIndex
          )
          let minValue =[null,-1]
          for(let j=0;j<filteredRoad.length;j++){
            let thisDistance = haverSine(pinLocation,[filteredRoad[j][0],filteredRoad[j][1]])
            if(minValue[0]==null || minValue[1]>thisDistance){
              minValue=[filteredRoad[j][2],thisDistance,arrRoad[j][0],arrRoad[j][1]]
            }
          }
          arrPoints.push([minValue[0],pinId])
          usedIndex=minValue[0]

        }
      }
      // console.log(routePoints)
      // console.log("arrPoints",arrPoints)
      setAllPinData(arrPoints)
      setAllRoadData(arrRoad)

      const takeT = (Date.now() - startT)/ 1000
      console.log("taking: ",takeT,"second")

      getPosition()
    }
  }

  function stopOnclick(e){
    stopWatch(e)
    setModalShowedAlready(-1)
  }

  useEffect(() => {
    if(!Boolean(route)){return}
    if(route.points ==undefined){return}
    if(route.points.length ==0){return}

    setPointsList([...route.points])
    const pointList = route.points

    if(location.latitude==null){return}
    const myPosition = [location.latitude,location.longitude]
    
    //여기부터 시작
    //method1(0.002s)
    // const startT = Date.now()
    // const aroundNow = allRoadData.filter((point)=>
    //   Math.abs(myPosition[0]-point[0])<=0.002 && Math.abs(myPosition[1]-point[1])<=0.002
    // )
    // let nowRoad = [null,-1]
    // for(let i=0;i<aroundNow.length;i++){
    //   let thisDistance = haverSine(myPosition,[aroundNow[i][0],aroundNow[i][1]])
    //   if(nowRoad[0]==null || nowRoad[1]>thisDistance){
    //     nowRoad=[aroundNow[i][2],thisDistance]
    //   }
    // }

    // const takeT = (Date.now() - startT)/ 1000
    // console.log("taking1: ",takeT,"second")
    // console.log("aa",nowRoad)

    //method2(0.009s)
    let checkcheck
    const startT = Date.now()
    //현재위치에대한 로직,이전 값이없는경우는 패스
    //이전값과 현재값의 방향벡터를 계산하고 주위의 점 스캔(lng:x lat:y)
    //주위의 점을 하버사인으로 소트함
    //순서대로 방향벡터의 부호가 같은 가장 가까운 점을 찾음
    // 그중에서 제일 가까운 인덱스를 현재인덱스 값으로 지정
    if(beforePosition == []){
      setBeforePosition(myPosition)
      return
    }
    const nowVector = [myPosition[0]-beforePosition[0],myPosition[1]-beforePosition[1]]
    //현재벡터가 0일경우도 잇지않나?

    //주변탐색
    const aroundNow = allRoadData.filter((point)=>
      Math.abs(myPosition[0]-point[0])<=0.001 && Math.abs(myPosition[1]-point[1])<=0.001
    )
    aroundNow.sort((function (a, b) {
      let aHaver = haverSine(myPosition,[a[0],a[1]])
      let bHaver = haverSine(myPosition,[b[0],b[1]])
      return aHaver-bHaver;
    }))
    
    let nowRoad = [null,-1]
    if(nowVector != [0,0] && aroundNow.length != 0){
      //최단이면서 방향벡터의 부호가 같은 점 찾기
      const roadLastIndex = allRoadData.length-1
      for(let k=0; k<aroundNow.length; k++){
        const kIndex = aroundNow[k][2]
        let thisVector
        if(kIndex==roadLastIndex){
          thisVector = [allRoadData[kIndex][0]-allRoadData[kIndex-1][0],allRoadData[kIndex][1]-allRoadData[kIndex-1][1]]
        }else{
          thisVector = [allRoadData[kIndex+1][0]-allRoadData[kIndex][0],allRoadData[kIndex+1][1]-allRoadData[kIndex][1]]
        }
        const vectorsDegree = 180*(Math.acos(((nowVector[0]*thisVector[0])+(nowVector[1]*thisVector[1]))/(Math.sqrt(nowVector[0]**2+nowVector[1]**2)*Math.sqrt(thisVector[0]**2+thisVector[1]**2))))/Math.PI
        checkcheck= vectorsDegree
        if(Math.abs(vectorsDegree)<60){
          nowRoad = [kIndex,haverSine(myPosition,[aroundNow[k][0],aroundNow[k][1]])]
          break
        }
      }
    }

    if(nowRoad[0] == null){
      //현재위치와 이전위치에 차이가없거나, 주변에 점이없을경우는 그냥 제일가까운점을 찾는다.
      for(let i=0;i<allRoadData.length;i++){
        let thisDistance = haverSine(myPosition,[allRoadData[i][0],allRoadData[i][1]])
        if(nowRoad[0]==null || nowRoad[1]>thisDistance){
          nowRoad=[i,thisDistance]
        }
      }
    }

    // console.log("now",nowRoad,allRoadData[nowRoad[0]])
    const nowIndex = nowRoad[0]
    const nextIndex = nowIndex+1

    if(nowRoad[1]>1300 && modalShowedAlready!=-100){
      setCheckModalContent(`Out of planned route\n Or\nWe lost position..`)
      setDistanceCheckModalShow(true)
      const warningAlert = new Audio(waterdrop)
      warningAlert.play()
      setModalShowedAlready(-100)
      return
    }
    let nearPinData = null//(roadIndex,PinID)
    for(let j=0;j<allPinData.length;j++){
      if(allPinData[j][0]>=nowIndex){
        nearPinData = allPinData[j]
        break
      }
    }

    const nearPinRoadIndex = nearPinData[0]
    const nearPinID = nearPinData[1]
    const nearPinArrIndex = pointList.findIndex((point)=> point.id==nearPinID)//index는 실제로 1작음
    const nearPin = pointList[nearPinArrIndex]
    // console.log("pin",nearPinArrIndex,nearPin,nearPinData,nearPinID)
    
    const distanceNowToRoadIndex = haverSine(myPosition,[allRoadData[nowIndex][0],allRoadData[nowIndex][1]])
    const distanceNowToNearPin= distanceNowToRoadIndex+(allRoadData[nearPinRoadIndex][4]-allRoadData[nowIndex][4])
    // console.log("distance",distanceNowToRoadIndex,allRoadData[nextIndex],allRoadData[nearPinRoadIndex],distanceNowToNearPin)

    const takeT = (Date.now() - startT)/ 1000
    console.log("taking2: ",takeT,"second")

    if(modalShowedAlready == -100){
      setCheckModalContent(`Out of planned route\n Or\nWe lost position..`)
    }else{
      setCheckModalContent(`Index: ${nearPinArrIndex+1}\nComment: ${nearPin.comment}\nDistance: ${distanceNowToNearPin}m\n${checkcheck}`)
    }
    
    if(distanceNowToNearPin <=alertDistance && modalShowedAlready!=nearPinArrIndex){
      setDistanceCheckModalShow(true)
      const alert = new Audio(melodykakao)
      alert.play()
      setModalShowedAlready(nearPinArrIndex)
    }

    setBeforePosition(myPosition)
  }, [location,route,modalShowedAlready,pointIndex]);

  return (
    <>
      {/* <iframe src={melodykakao} allow="autoplay" id="audio"></iframe>
      <audio id="player" autoplay controls><source src={melodykakao} type="audio/mp3"/></audio> */}
      <div>
        <Form.Control type="text" ref={alertDistanceRef} placeholder="Alert distance"/>
        <Form.Text className="text-muted">
          *Alert distance range 50~1000
        </Form.Text>
        <Button
          variant="red"
          onClick={()=>{watchPositionOnclick()}}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          disabled={watchId !== null}
        >
          watch Position
        </Button>
      </div>
      <div
        style={{
          width: "100%",
          fontSize: "15px",
          //textAlign: "center",
        }}
      >
        <p>Lat: {location.latitude ? location.latitude : "---"}</p>
        <p>Lon: {location.longitude ? location.longitude : "---"}</p>{" "}
        <p>accuracy: {location.accuracy ? location.accuracy : "---"} m</p>
      </div>
      <div>
        <Button
          variant="grey"
          onClick={stopOnclick}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          disabled={watchId === null}
        >
          Stop
        </Button>
        <br/>
      </div>
      <ModalPointDistanceCheck
        distanceCheckModalShow={distanceCheckModalShow}
        distanceCheckModalOKClick={distanceCheckModalOKClick}
        distanceCheckModalContents={checkModalContent}
      />
    </>
  );
};
