import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { GeoJsonLayer } from "@deck.gl/layers";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, useParams } from "react-router-dom";
import { useGoogleMap } from "../../../hooks/useGoogleMap";
import { useSummaryMap } from "../../../hooks/useSummaryMap";
import { BreadcrumbBar } from "../../BreadcrumbBar";
import { HdmapCoverageButton } from "../hdmap/HdmapCoverage";
import { NotTestedButton } from "../hdmap/NotTestedSegments";
import style from "../Route.module.css";
import { LinkOutVecResult } from "./LinkOutVecResult";
import { MileagePange } from "./MileagesPane";
import { NoticeZoomMax } from "./noticeZoomMax";
import { useUser } from "../../../hooks/useUser";
import { kiwiApiVehicles, kiwiApiGetAllVecPlanResult } from "../../../api/kiwiApi/kiwiApis";

const API_KEY = process.env["REACT_APP_MAP_API_KEY"];

const initialConfig = {
  center: { lat: 35.6432027, lng: 139.6729435 },
  zoom: 4,
  mapTypeControl: true,
  //maxZoom: 20,
};

export const SummaryPage = () => {
  const googleMap = useGoogleMap(API_KEY);
  const mapContainerRef = useRef(null);
  const mapMileageInfoRef = useRef(null);
  const mapNoticeZoomRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  const { user } = useUser();

  /** @type {{fleetid: string, vecid: string}}  */
  const fleetId = params.fleetid;
  const fleetName = params.fleetName;
  //const carId = params.vecid;

  const [status, setStatus] = useState(false);
  const [thisFleetVecs, setThisFleetVecs] = useState([]);

  const [zoomMax, setZoomMax] = useState(false);

  const [infoAllPlan, setInfoAllPlan] = useState({}); //{distance, lastModified}
  const [infoAllResult, setInfoAllResult] = useState({}); //{distance, lastModified}

  const [allPlanOverlay, setAllPlanOverlay] = useState(null); //allVec PlanのimageOverlay
  const [allResultOverlay, setAllResultOverlay] = useState(null); //allVec ResultのimageOverlay

  const [selectVec, setSelectVec] = useState(null);
  const [selectVecId, setSelectVecId] = useState(null);

  const [infoVecPlan, setInfoVecPlan] = useState({}); //{distance, lastModified}
  const [infoVecResult, setInfoVecResult] = useState({}); //{distance, lastModified}
  const [vecPlanOverlay, setVecPlanOverlay] = useState(null); //選択vec PlanのimageOverlay
  const [vecResultOverlay, setVecResultOverlay] = useState(null); //選択vec ResultのimageOverlay

  const [visiblePlanRoute, setVisiblePlanRoute] = useState(true);
  const [visibleResultsRoute, setVisibleResultsRoute] = useState(true);

  const [allVecData, setAllVecData] = useState(null);
  /** @type {import("../../../hooks/useCurrentPlan").Route[]} */

  const map = useSummaryMap({
    googleMap,
    mapContainerRef,
    initialConfig,
    mapMileageInfoRef,
    mapNoticeZoomRef,
    setZoomMax,
  });

  useEffect(() => {
    (async()=>{
      const rawVecsList = await kiwiApiVehicles(user.token, fleetId)
      if(rawVecsList == "error"){
        history.push(`/Fleets`);
        return
      }
      // console.log(rawVecsList.vehicles_data)
      const fixedVecsList =rawVecsList.vehicles_data.map((item) => {
        return {
          fleet_id : item.project_id.toString(),
          car_id : item.vehicle_id.toString(),
          name : item.vec
        }      
      })
      setThisFleetVecs(fixedVecsList);
    })();
  }, [fleetId]);

  const planCheckboxClick = (e) =>{
    setVisiblePlanRoute( e.currentTarget.checked)
  }

  const resultsCheckboxClick = (e) =>{
    setVisibleResultsRoute( e.currentTarget.checked)
  }

  useEffect(() => {
    if ((map === null) | (googleMap === null)) {
      return;
    }

    (async()=>{
      setStatus(true)
      if(allVecData == null){
        const allVecResponse = await kiwiApiGetAllVecPlanResult(user.token, fleetId)
        setAllVecData(allVecResponse)
      }

      if(!visiblePlanRoute){
        if(allPlanOverlay != null){
          allPlanOverlay.setMap(null)
        }
      }
      if(!visibleResultsRoute){
        if(allResultOverlay != null){
          allResultOverlay.setMap(null)
        }
      }

      overlayMaker()
      setStatus(false)
    })()
    
  }, [map, fleetId, googleMap, allVecData, visiblePlanRoute, visibleResultsRoute, selectVec]);

  if (map !== null) {
  }
  function imageToMap(setInfo, setOverlay, inputData){
    if(inputData.presigned_url != ""){
      const presignedUrl = inputData.presigned_url
      const distanceData = inputData.distance
      const lastModifiedData = inputData.last_modified
      const imageBoundsData = inputData.image_bounds
      setInfo({ distance: distanceData, lastModified: lastModifiedData });
      const overlay = new googleMap.maps.GroundOverlay(
        presignedUrl,
        imageBoundsData,
        { opacity: 0.6 }
      );
      setOverlay(overlay);
      overlay.setMap(map);
    }else{
      const tempDistance = "---";
      const tempLastModifed = "---";
      setInfo({ distance: tempDistance, lastModified: tempLastModifed }); //このImageに書かれているpathの総距離をセットする
      setOverlay(null);
    }
  }

  const overlayMaker = async () =>{
    if(allPlanOverlay != null){
      allPlanOverlay.setMap(null)
    }
    if(allResultOverlay != null){
      allResultOverlay.setMap(null)
    }
    await new Promise(f => setTimeout(f, 200));
    if(allVecData == null){return}

    // console.log("api result",allVecData)

    const planData = allVecData.plans
    const resultData = allVecData.results
    // console.log(planData)

    if(visiblePlanRoute){imageToMap(setInfoAllPlan,setAllPlanOverlay, planData.fleet)}
    if(visibleResultsRoute){imageToMap(setInfoAllResult,setAllResultOverlay, resultData.fleet)}

    if(selectVec){
      if (vecPlanOverlay != null) {
        vecPlanOverlay.setMap(null);
      }
      if (vecResultOverlay != null) {
        vecResultOverlay.setMap(null);
      }
      await new Promise(f => setTimeout(f, 200));
      if(visiblePlanRoute){imageToMap(setInfoVecPlan, setVecPlanOverlay, planData.cars[selectVecId])}
      if(visibleResultsRoute){imageToMap(setInfoVecResult, setVecResultOverlay, resultData.cars[selectVecId])}
    }
  }

  //S3から画像取得&表示
  function clickVec({ car_id, car_name }) {
    setSelectVecId(car_id)
    if(selectVec == car_name){
      setSelectVec(null)
      if (vecPlanOverlay !== null) {
        vecPlanOverlay.setMap(null);
      }
      if (vecResultOverlay !== null) {
        vecResultOverlay.setMap(null);
      }
    }
    else{
      setSelectVec(car_name);
    }
  }

  return (
    <>
      <BreadcrumbBar params={params} />
      <div style={{ position: "relative" }}>
        <LoadingOverlay active={status} spinner text="Loading your content...">
          <Container fluid>
            {/* Header/BreadcrumbBarの高さの合計が104.8px */}
            <Row
              style={{
                height: "calc(100vh - 104.8px)",
                position: "relative",
              }}
            >
              <Col sm={2} className={style.routePageCol}>
                <Card style={{ padding: 0, height: "50%" }}>
                  <Card.Header
                    style={{ margin: "0px", padding: "10px" }}
                    className="titlecustom"
                  >
                    Vehicles
                  </Card.Header>
                  <Card.Body style={{ overflowY: "scroll", padding: 0 }}>
                    <ListGroup variant="flush">
                      {thisFleetVecs.map((vec) => {
                        return (
                          <ListItemVEC
                            vec={vec}
                            fleetName={fleetName}
                            fleetId={fleetId}
                            clickVec={clickVec}
                            history={history}
                            selectVec={selectVec}
                          />
                        );
                      })}
                    </ListGroup>
                  </Card.Body>
                </Card>
                <Card style={{ padding: 0, height: "10%" }}>
                  <Card.Body style={{ padding: 0 }}>
                    <Col>
                      <input type="checkbox" onClick={planCheckboxClick} defaultChecked/>
                      {'Plan route'}
                    </Col>
                    <Col>
                      <input type="checkbox" onClick={resultsCheckboxClick} defaultChecked/>
                      {'Results route'}
                    </Col>
                  </Card.Body>
                </Card>
                <Card
                  style={{ padding: 0, height: "40%", overflowY: "scroll" }}
                >
                  <Card.Header
                    style={{ margin: "0px", padding: "10px" }}
                    className="titlecustom"
                  >
                    Options
                  </Card.Header>
                  <Card.Body>
                    <HdmapCoverageButton
                      fleetId={fleetId}
                      map={map}
                      googleMap={googleMap}
                    />
                    <NotTestedButton
                      fleetId={fleetId}
                      map={map}
                      googleMap={googleMap}
                    />
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={10} className={style.routePageCol}>
                <>
                  <div
                    style={{
                      height: "100%",
                      position: "relative",
                    }}
                    ref={mapContainerRef}
                  ></div>
                  <div
                    ref={mapMileageInfoRef}
                    style={{
                      margin: "10px",
                      fontSize: "18px",
                      // backgroundColor: "grey",
                    }}
                  >
                    <MileagePange
                      infoAllPlan={infoAllPlan}
                      infoAllResult={infoAllResult}
                      selectVec={selectVec}
                      infoVecPlan={infoVecPlan}
                      infoVecResult={infoVecResult}
                    />
                  </div>
                  <div
                    ref={mapNoticeZoomRef}
                    style={{
                      margin: "10px",
                      fontSize: "18px",
                    }}
                  >
                    {zoomMax ? (
                      <NoticeZoomMax show={zoomMax} setShow={setZoomMax} />
                    ) : null}
                  </div>
                </>
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
      </div>
    </>
  );
};

//各VECのListのコンポーネント
const ListItemVEC = ({
  vec,
  clickVec,
  fleetName,
  fleetId,
  history,
  selectVec,
}) => {
  return (
    <>
      <ListGroup.Item
        key={vec.car_id}
        className="titlecustom"
        style={{
          padding: "5px 10px",
          color: "#585858",
          display: "flex",
          backgroundColor: selectVec === vec.name ? "lightgrey" : "white",
        }}
      >
        <div
          style={{
            padding: "5px",
            color: "#585858",
            fontSize: "20px",
            display: "flex",
            width: "80%",
            cursor: "pointer",
          }}
          onClick={() => {
            clickVec({
              car_id: vec.car_id,
              car_name: vec.name,
            });
          }}
        >
          {vec.name}
        </div>
        <div
          style={{
            padding: "5px",
            marginRight: 0,
            marginLeft: "auto",
            width: "20%",
          }}
        >
          <LinkOutVecResult
            fleetName={fleetName}
            fleetId={fleetId}
            car_id={vec.car_id}
            car_name={vec.name}
            history={history}
          />
        </div>
      </ListGroup.Item>
    </>
  );
};

///////////トライアルの遺産
function displayImage(image, imageBounds, map, googleMap) {
  const overlay = new googleMap.maps.GroundOverlay(image, imageBounds, {
    opacity: 0.6,
  });

  overlay.setMap(map);
}

export function displayJson(json, map) {
  const deckOverlay = new GoogleMapsOverlay({
    layers: createLayer(json, map),
  });
  deckOverlay.setMap(map);
}

function createLayer(features, map) {
  return new GeoJsonLayer({
    id: 1,
    data: features,
    extruded: true,
    filled: true,
    getElevation: 30,
    getFillColor: [102, 255, 0],
    // getLineColor: (f) => {
    //   const hex = f.properties.color;
    //   // convert to RGB
    //   return hex
    //     ? hex.match(/[0-9a-f]{2}/g).map((x) => parseInt(x, 16))
    //     : [0, 0, 0];
    // },
    getLineWidth: 1,
    getRadius: 20,
    pointRadiusMinPixels: 2,
    pointRadiusMaxPixels: 30,
    opacity: 0.4,
    pointRadiusScale: 0.3,
    // lineJointRounded: false,
    // lineMiterLimit: 4,
    // lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
    lineWidthMinPixels: 2,
    lineWidthScale: 20,
    // lineWidthUnits: 'meters',
    // material: true,
    pointRadiusUnits: "pixel",
    stroked: false,
    // wireframe: false,

    /* props inherited from Layer class */

    // autoHighlight: false,
    // coordinateOrigin: [0, 0, 0],
    // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    // highlightColor: [0, 0, 128, 128],
    // modelMatrix: null,
    pickable: true,
    // visible: true,
    // wrapLongitude: false,
    // onDataLoad: (_) => {
    //   progress.done(); // hides progress bar
    // },
  });
}
