import destMarker from "../../../image/goalflag.png";
//import issuePinRed from "../../../image/pin_issue_red.png";
import originMarker from "../../../image/pin_red.png";
import waypointCommentMarker from "../../../image/pin_white01.png";
import waypointMarker from "../../../image/pin_white02.png";
//import issuePinYellow from "../../../image/pin_yellow.png";

/**
 * Point表示用のgeoJsonを作成する関数
 * @param {string} mode // 'plan'|'result'
 * @param {Array} points //Plan.points | Result.issue_points
 */
export function createPointsFeatures({ mode, points }) {
  const pointsFeature = points.map((p, index) => {
    const coordinates = [p.lng, p.lat];
    const featureId =
      mode === "plan"
        ? `selectedRoutePointsPlan${index + 1}${p.lng}${p.lat}`
        : `selectedRoutePointsResult${p.no}${p.lng}${p.lat}`;
    const properties =
      mode === "plan"
        ? { type: p.type, comment: p.comment, label: index + 1 }
        : {
            issue_no: p.issue_no,
            comment: p.description,
            label: p.issue_no,
            priority: p.priority,
          };
    const feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coordinates,
      },
      id: featureId, //featureIDを追加
      properties: properties,
    };
    return feature;
  });

  const pointsFeatures = {
    type: "FeatureCollection",
    features: [...pointsFeature],
  };

  return pointsFeatures;
}

export function returnInfoWindowPlan({
  label,
  type,
  comment,
  googleMap,
  event,
}) {
  const content = `<div style="font-size: 12px;">
    <p>label:<span style="font-weight:bold;"> ${label} </p>
    <p>type: <span style="font-weight:bold;">${type}</p>
    <p>comment:</p>
    <p> ${comment === undefined ? "---" : comment}</p>
    </div>`;

  const infowindow = new googleMap.maps.InfoWindow({
    content: content,
    position: event.latLng,
    pixelOffset: new googleMap.maps.Size(0, -50),
    maxWidth: 200,
  });

  return infowindow;
}

export function returnInfoWindowResult({
  priority,
  issueNo,
  comment,
  googleMap,
  event,
}) {
  const kaigyoComment = comment.replace(/\r?\n/g, "<br>"); //改行を置き換え
  const color = priority === "Priority 0" ? "red" : "gold";
  const content = `<div style="font-size: 12px;">
    <p>label:<span style="font-weight:bold;"> IssueNo: ${issueNo} </p>
    <p>type: <span style="font-weight:bold; color: ${color}">${priority}</p>
    <p>comment:</p>
    <p> ${comment === undefined ? "---" : kaigyoComment}</p>
    </div>`;

  const infowindow = new googleMap.maps.InfoWindow({
    content: content,
    position: event.latLng,
    pixelOffset: new googleMap.maps.Size(0, -25),
    maxWidth: 200,
  });

  return infowindow;
}

//Plan・Result共有
export function clearPointInfoWindows({ infoWindows }) {
  infoWindows.forEach((window) => {
    window.close();
  });
  infoWindows.length = 0;
}

// //Plan・Result共有
// export function createArrowPolyline({ selectedRoute, googleMap }) {
//   const coods = selectedRoute.path_geojson.geometry.coordinates.map((c) => {
//     return { lat: c[1], lng: c[0] };
//   });
//   const strokeColor =
//     selectedRoute.plan_name !== undefined ? "#A10202" : "#0252A1";

//   const lineSymbol = {
//     path: googleMap.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//     strokeColor: strokeColor,
//     strokeWeight: 3,
//     scale: 4,
//   };
//   const line = new googleMap.maps.Polyline({
//     path: coods,
//     strokeColor: strokeColor,
//     strokeWeight: 3,
//     icons: [
//       {
//         icon: lineSymbol,
//         offset: "100%",
//         repeat: "100px",
//       },
//     ],
//   });

//   return line;
// }

// export function returnIssuePinIcon(priority) {
//   const url = priority === "Priority 0" ? issuePinRed : issuePinYellow;

//   const anchor =
//     priority === "Priority 0"
//       ? { width: 25, height: 68 }
//       : { width: 17, height: 45 };
//   const size =
//     priority === "Priority 0"
//       ? { width: 54, height: 69 }
//       : { width: 36, height: 46 };

//   // const anchor = { width: 25, height: 68 };
//   // const size = { width: 54, height: 69 };

//   return { url, anchor, size };
// }

export function returnPlanPinIcon({ type, comment }) {
  const url =
    type === "origin"
      ? originMarker
      : type === "destination"
      ? destMarker
      : (comment === undefined) | (comment === "")
      ? waypointMarker
      : waypointCommentMarker;

  const scale = 0.8;

  const anchor =
    type === "origin"
      ? { width: 18 * scale, height: 46 * scale }
      : type === "destination"
      ? { width: 7 * scale, height: 46 * scale }
      : { width: 25 * scale, height: 68 * scale };

  const size =
    type === "origin"
      ? { width: 36 * scale, height: 46 * scale }
      : type === "destination"
      ? { width: 36 * scale, height: 46 * scale }
      : { width: 54 * scale, height: 69 * scale };

  return { url, anchor, size };

  //PlanのMarkerサイズ
  //   const anchor =
  //   type === "origin"
  //     ? { width: 18, height: 46 }
  //     : type === "destination"
  //     ? { width: 7, height: 46 }
  //     : { width: 25, height: 68 };

  // const size =
  //   type === "origin"
  //     ? { width: 36, height: 46 }
  //     : type === "destination"
  //     ? { width: 36, height: 46 }
  //     : { width: 54, height: 69 };
}
