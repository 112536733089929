import { useEffect, useRef } from "react";
import destMarker from "../../image/goalflag.png";
import destMarkerSelected from "../../image/goalflagSelected.png";
import originMarkerSelected from "../../image/pin_green.png";
import originMarker from "../../image/pin_red.png";
import waypointCommentMarker from "../../image/pin_white01.png";
import waypointCommentMarkerSelected from "../../image/pin_white01Selected.png";
import waypointMarker from "../../image/pin_white02.png";
import waypointMarkerSelected from "../../image/pin_white02Selected.png";
/**
 * @param {object} props
 * @param {number} props.lat
 * @param {number} props.lng
 * @param {string} props.type
 * @param {string} [props.label]
 * @param {(e: google.maps.MouseEvent) => void} props.onClick
 * @param {(e: google.maps.MouseEvent) => void} props.onDragEnd
 * @param {google} props.googleMap
 * @param {google.maps.Map} props.map
 */
export function Marker({
  lat,
  lng,
  type,
  comment,
  isSelected,
  onClick,
  onDragEnd,
  googleMap,
  map,
  label = "",
  draggable = true,
}) {
  const markerObject = useRef(null);

  useEffect(() => {
    //type, comment, select中,
    if (markerObject.current) {
      // console.log(comment);
      /** @type {google.maps.Marker} */
      const marker = markerObject.current;
      marker.setPosition({ lat, lng });
      marker.setIcon({
        url: returnMarkerIcon(type, isSelected, comment).url,
        anchor: new googleMap.maps.Point(
          returnMarkerIcon(type, isSelected, comment).anchor.width,
          returnMarkerIcon(type, isSelected, comment).anchor.height
        ),
        scaledSize: new googleMap.maps.Size(
          returnMarkerIcon(type, isSelected, comment).size.width,
          returnMarkerIcon(type, isSelected, comment).size.height
        ),
      });
      marker.setLabel({
        text: label,
        fontSize: "20px",
        fontWeight: "bold",
      });
      return;
    }

    /** @type {google.maps.Marker} */
    const marker = new googleMap.maps.Marker({
      position: { lat, lng },
      map: map,
      animation: googleMap.maps.Animation.DROP,
      draggable: draggable,
      icon: {
        url: returnMarkerIcon(type, isSelected, comment).url,
        anchor: new googleMap.maps.Point(
          returnMarkerIcon(type, isSelected, comment).anchor.width,
          returnMarkerIcon(type, isSelected, comment).anchor.height
        ),

        scaledSize: new googleMap.maps.Size(
          returnMarkerIcon(type, isSelected, comment).size.width,
          returnMarkerIcon(type, isSelected, comment).size.height
        ),
      },
      // label: new googleMap.maps.MarkerLabel(label,
      label: {
        text: label,
        fontSize: "20px",
        fontWeight: "bold",
      },
    });

    markerObject.current = marker;
  }, [
    comment,
    draggable,
    googleMap,
    isSelected,
    label,
    lat,
    lng,
    map,
    markerObject,
    onClick,
    onDragEnd,
    type,
  ]);

  useEffect(() => {
    return () => {
      if (markerObject.current) {
        markerObject.current.setMap(null);
      }
    };
  }, []);
  useEffect(() => {
    if (!markerObject.current) {
      return;
    }
    const listner = markerObject.current.addListener("click", (e) => {
      // onClick(e, markerObject.current);
      onClick(e);
    });

    return () => {
      listner.remove();
    };
  }, [markerObject, onClick]);

  useEffect(() => {
    if (!markerObject.current) {
      return;
    }

    const listner = markerObject.current.addListener("dragend", (e) => {
      onDragEnd(e);
    });

    return () => {
      listner.remove();
    };
  }, [markerObject, onDragEnd]);

  return null;
}

export function returnMarkerIcon(type, isSelected, comment) {
  // console.log(comment);
  // console.log(comment === undefined);
  // console.log(comment === "");
  // console.log(comment == "");
  const isCommentExist = comment ? true : false;
  // console.log(isCommentExist);

  const url =
    type === "origin" && isSelected === false
      ? originMarker
      : type === "origin" && isSelected === true
      ? originMarkerSelected
      : type === "destination" && isSelected === false
      ? destMarker
      : type === "destination" && isSelected === true
      ? destMarkerSelected
      : type === "waypoint" && isSelected === false && isCommentExist
      ? waypointCommentMarker
      : type === "waypoint" && isSelected === false && !isCommentExist
      ? waypointMarker
      : type === "waypoint" && isSelected === true && isCommentExist
      ? waypointCommentMarkerSelected
      : waypointMarkerSelected;

  const anchor =
    type === "origin"
      ? { width: 18, height: 46 }
      : type === "destination"
      ? { width: 7, height: 46 }
      : { width: 25, height: 68 };

  const size =
    type === "origin"
      ? { width: 36, height: 46 }
      : type === "destination"
      ? { width: 36, height: 46 }
      : { width: 54, height: 69 };
  /*
  return object sample
      {
        url: XXXX ,
        anchor: {width: XX, height: XX},
        size: {width:XX, height: XX}
      }
      
  */

  return { url, anchor, size };
}

// const startPinIcon = originMarker;
// const waypointPinIcon = waypointMarker;
// const destPinIcon = destMarker;

// const PinIcons = { startPinIcon, waypointPinIcon, destPinIcon };
