export const lineStyle_jsonPath_normal = /** @type {google.maps.Data.StyleOptions} */ {
  strokeColor: "blue",
  strokeWeight: 3,
  strokeOpacity: 0.6,
};

export const lineStyle_jsonPath_hover = /** @type {google.maps.Data.StyleOptions} */ {
  strokeColor: "blue",
  strokeWeight: 8,
  strokeOpacity: 0.6,
};
//色決め打ち（⇒以前Planで使用、今は使用なし）
const evalPointStyle = (googleMap: any) => {
  const icon = {
    path: googleMap.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
    scale: 5,
    fillColor: "blue",
    fillOpacity: 0.5,
    strokeWeight: 0,
  };
  return { icon };
};

//色を指定して矢印PINを作成する（⇒Plan/Resultで使用）
export const evalPointStyleRandom = (googleMap: any, color: string) => {
  const icon = {
    path: googleMap.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
    scale: 5,
    fillColor: color,
    fillOpacity: 0.8,
    strokeWeight: 0,
  };
  return { icon };
};
