// import Icon from "../image/orange.jpg";
import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useUser } from "../hooks/useUser";

export function Header() {
  const { user, signOut } = useUser();
  const history = useHistory();
  const onSignOutClicked = async () => {
    const signedOut = await signOut();
    if (signedOut) {
      history.push("/");
    }
  };

  const location = useLocation();

  const canDisplayUser = location.pathname !== "/";
  return (
    // <Navbar bg="dark" variant="dark">
    <Navbar style={{ backgroundColor: "#000000" }}>
      <Navbar.Brand>
        <Container fluid="lg">
          <Row className="justify-content-md-center">
            <Col
              md="auto"
              // className="titlecustom"
              style={{
                fontSize: "15px",
                border: "solid",
                color: "#FFFFFF",
                borderColor: "#FFFFFF",
              }}
            >
              Kiwi
            </Col>
          </Row>
        </Container>
      </Navbar.Brand>
      <Nav className="mr-auto"></Nav>
      {canDisplayUser && (
        <>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text style={{ color: "#FFFFFF" }}>{user.name}</Navbar.Text>
          </Navbar.Collapse>
          <Form inline>
            <Button
              variant="grey"
              size="sm"
              onClick={onSignOutClicked}
              style={{ margin: "5px" }}
            >
              SignOut
            </Button>
          </Form>
        </>
      )}
    </Navbar>
  );
}
