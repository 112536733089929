import XLSX from "xlsx";
import { createRouteGeoJsonbyRoutePath } from "../plan/SearchDirections";

/**
 * 処理待機する関数
 * @param {number} ms
 */
const _sleep = (ms: Number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function exportExcelasJson(
  e: ProgressEvent<FileReader>,
  googleMap: any
) {
  if (
    e.target === null ||
    e.target.result === null ||
    typeof e.target.result === "string"
  ) {
    return;
  }
  var data = new Uint8Array(e.target.result);
  var workbook = XLSX.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  const readCells = [
    { name: "pathNo", cell: "B1" },
    { name: "startPlace", cell: "Q1" },
    { name: "endPlace", cell: "R1" },
    { name: "areaPlace", cell: "V1" },
  ];

  if (sheet["!ref"] === undefined) {
    return;
  }
  const cellRange = XLSX.utils.decode_range(sheet["!ref"]);
  const startRow = 2; //決め打ち
  const lastRow = cellRange.e.r;

  const readCellsInfo = convertColNamw2No(readCells);
  const pathsInfo = pickupPathInfo({
    sheet,
    startRow,
    lastRow,
    readCellsInfo,
  });
  //console.log(pathsInfo);
  (async () => {
    for (let path of pathsInfo) {
      await _sleep(1000);
      // console.debug("await", path);
      const orgpos = `${path.startPlace} ${path.areaPlace}`;
      const destpos = `${path.endPlace} ${path.areaPlace}`;
      const fileName = `${path.pathNo}-${path.startPlace}-${path.endPlace}.json`;

      const optimizeWaypoints = false;
      const request = {
        origin: orgpos,
        destination: destpos,
        waypoints: [],
        travelMode: googleMap.maps.DirectionsTravelMode.DRIVING,
        optimizeWaypoints,
      };

      const d = new googleMap.maps.DirectionsService();
      d.route(request, function (result: any, status: any) {
        if (status === googleMap.maps.DirectionsStatus.OK) {
          // console.log(result);
          const path_geojson = createRouteGeoJsonbyRoutePath(result);
          path_geojson.properties = path;
          path_geojson.properties.memo =
            "おおよその場所を表すもので正確ではありません";
          exportJson({ originalData: path_geojson, fileName });
        } else {
          // console.log(result);
          // console.log(status);
          exportJson({
            originalData: { result },
            fileName: `${status}_${fileName}`,
          });
        }
      });
    }
  })();
}

/**
 * objectをjson形式で出力する関数
 * @param {Object} originalData
 * @param {string} fileName
 */
interface exportJsonProps {
  originalData: any;
  fileName: string;
}

function exportJson({ originalData, fileName }: exportJsonProps) {
  // データをJSON形式の文字列に変換する。
  const data = JSON.stringify(originalData);

  // HTMLのリンク要素を生成する。
  const link = document.createElement("a");

  // リンク先にJSON形式の文字列データを置いておく。
  link.href = "data:text/plain," + encodeURIComponent(data);

  // 保存するJSONファイルの名前をリンクに設定する。
  link.download = fileName;

  // ファイルを保存する。
  link.click();
}

/**
 *
 * 列名とcell名情報jsonに読取るcol番号の情報を追加する関数
 * @param {Array} readCells
 */

function convertColNamw2No(readCells: any[]) {
  const array: any[] = [];
  readCells.forEach((readCell) => {
    //読取るCol番号を取得 cell->col番号変換
    const colvalue = XLSX.utils.decode_cell(readCell.cell).c;
    readCell = { ...readCell, colvalue };
    console.log(readCell);
    array.push(readCell);
  });
  return array;
}

interface pickupPathInfoProps {
  sheet: any;
  startRow: number;
  lastRow: number;
  readCellsInfo: any[];
}
/**
 * sheetから決まった値を取得してくる関数
 **/
function pickupPathInfo({
  sheet,
  startRow,
  lastRow,
  readCellsInfo,
}: pickupPathInfoProps) {
  const paths = [];
  for (let i: number = startRow; i < lastRow + 1; i++) {
    const pathInfo: any = {};
    readCellsInfo.forEach((p) => {
      const cellName = XLSX.utils.encode_cell({ c: p.colvalue, r: i });
      const value = sheet[cellName] !== undefined ? sheet[cellName].v : "";
      pathInfo[p.name] = value;
    });

    paths.push(pathInfo);
  }
  return paths;
}
