import { Storage } from "aws-amplify";

export function mapmatchingData({ fleetId, setMisoukouJson, setlastModified }) {
  Storage.list("hdmaps/")
    .then((result) => {
      //S3のhdmapのリストを取得しマッチング結果が存在するかを確認
      const objectKey = `hdmaps/fleet_id${fleetId}_matching_result.json.gz`;
      const thisresult = result.filter((r) => r.key === objectKey);

      //存在するandサイズが25Mbyte以下なら表示させる-閾値は適当
      if (thisresult.length === 1 && thisresult[0].size < 10000000000) {
        //最終更新日をセットする
        const _lastModified = thisresult[0].lastModified; //date型
        const lastModified = _lastModified.toISOString(); //stringにする　UTC時間
        setlastModified(lastModified);

        //データの取得＆setする
        Storage.get(objectKey).then((preSignedURL) => {
          fetch(preSignedURL)
            .then((response) => response.json())
            .then((data) => {
              setMisoukouJson(data);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      } else {
        //マッチング結果はあるけどデータ大きすぎて表示無理パターン
        //infoは表示させるけど、ボタンは無し
        setMisoukouJson({});
      }
    })
    .catch((err) => console.log(err));
}

export function getMatchingData({ setMisoukouIchiran }) {
  Storage.list("hdmaps/")
    .then((result) => {
      //S3のhdmapのリストを取得しマッチング結果が存在するかを確認
      // const objectKey = `hdmaps/fleet_id${fleetId}_matching_result.json.gz`;
      const thisresult = result.filter((r) => r.key.includes("json.gz"));
      setMisoukouIchiran(thisresult);
    })
    .catch((err) => console.log(err));
}
