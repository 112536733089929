//rgb形式で色をランダムに生成する
export const randomColor = () => {
  const min = 0;
  const max = 255;
  const rgb = ["red", "green", "blue"];
  let colorArray = [255, 255, 255];

  while (isNeedChangeRGB(colorArray)) {
    const _colorArray = rgb.map((element) => {
      var num = Math.floor(Math.random() * (max + 1 - min)) + min;
      return num;
    });
    colorArray = _colorArray;
  }

  const rgbColor = `rgb(${colorArray})`;
  return rgbColor;
};

const isNeedChangeRGB = (colorArray) => {
  //rgbが同じで230以上だと薄い色になるので、それを避けるためのロジック
  //trueならRGBを変える必要がある
  const isSame = colorArray.every((c) => c === colorArray[0]);
  const isOver = colorArray[0] > 190;
  return isSame && isOver;
};
