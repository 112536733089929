/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PathGeoJSON,
    PathGeoJSONFromJSON,
    PathGeoJSONFromJSONTyped,
    PathGeoJSONToJSON,
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewPlan
 */
export interface NewPlan {
    /**
     * 
     * @type {string}
     * @memberof NewPlan
     */
    fleet_id: string;
    /**
     * 
     * @type {string}
     * @memberof NewPlan
     */
    car_id: string;
    /**
     * 
     * @type {string}
     * @memberof NewPlan
     */
    plan_name: string;
    /**
     * 
     * @type {PathGeoJSON}
     * @memberof NewPlan
     */
    path_geojson?: PathGeoJSON;
    /**
     * 
     * @type {number}
     * @memberof NewPlan
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPlan
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof NewPlan
     */
    time?: number;
    /**
     * 
     * @type {Array<Point>}
     * @memberof NewPlan
     */
    points?: Array<Point>;
}

export function NewPlanFromJSON(json: any): NewPlan {
    return NewPlanFromJSONTyped(json, false);
}

export function NewPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fleet_id': json['fleet_id'],
        'car_id': json['car_id'],
        'plan_name': json['plan_name'],
        'path_geojson': !exists(json, 'path_geojson') ? undefined : PathGeoJSONFromJSON(json['path_geojson']),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'points': !exists(json, 'points') ? undefined : ((json['points'] as Array<any>).map(PointFromJSON)),
    };
}

export function NewPlanToJSON(value?: NewPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fleet_id': value.fleet_id,
        'car_id': value.car_id,
        'plan_name': value.plan_name,
        'path_geojson': PathGeoJSONToJSON(value.path_geojson),
        'distance': value.distance,
        'comment': value.comment,
        'time': value.time,
        'points': value.points === undefined ? undefined : ((value.points as Array<any>).map(PointToJSON)),
    };
}


