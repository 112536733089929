import { useCallback, useContext, useEffect, useMemo } from "react";
import {
  resetStatus,
  retrieveCurrentSession,
  signIn,
  signOut,
  User,
} from "../components/user";
import { UserContext } from "../components/UserContextProvider";

interface UseUserProps {
  /** trueが指定された場合、セッション情報を復元します */
  autoSessionCheck?: boolean;
}

/**
 * アプリケーション内のユーザ情報を管理するhook
 *
 * 現在のユーザの状態(ユーザ名・認証済みなど)とサインイン・サインアウトを実行する関数を返します。
 */
export function useUser({ autoSessionCheck = false }: UseUserProps = {}) {
  const {
    state: { name, token, groups, status, error },
    dispatch,
  } = useContext(UserContext);

  const checkSesion = useCallback(() => retrieveCurrentSession(dispatch), [
    dispatch,
  ]);

  const isAuthenticated = Boolean(name) && Boolean(token);
  const user: User = useMemo(() => ({ name, token, groups }), [
    name,
    token,
    groups,
  ]);

  useEffect(() => {
    if (autoSessionCheck) {
      checkSesion();
    }
  }, [autoSessionCheck, checkSesion]);

  return {
    user,
    status,
    error,
    isAuthenticated,
    signIn: useCallback(
      (userData: any) => signIn(userData, dispatch),
      [dispatch]
    ),
    signOut: useCallback(() => signOut(dispatch), [dispatch]),
    resetStatus: useCallback(() => dispatch(resetStatus()), [dispatch]),
    // checkSession: checkSesion
  };
}

export type SignIn = ReturnType<typeof useUser>["signIn"];
export type SignOut = ReturnType<typeof useUser>["signOut"];
// export type CheckSession = ReturnType<typeof useUser>["checkSession"];
