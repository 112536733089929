import React from "react";
import { Button, Modal, Form } from "react-bootstrap";

export function ModalPOIDBCheck({
    poidbCheckModalShow,
    poidbCheckModalOKClick,
    poidbCheckModalContentsRef
  }) {
    return (
      <>
        <Modal
            backdrop ='static'
            keyboard = {false}
            show={poidbCheckModalShow}
            onHide={poidbCheckModalOKClick}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
            <Modal.Title className="titlecustom">
                POIDB
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
            {poidbCheckModalContentsRef}
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
            <Button variant="dark" onClick={poidbCheckModalOKClick}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }