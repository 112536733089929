/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AllVec,
    AllVecFromJSON,
    AllVecToJSON,
    HdmapMeta,
    HdmapMetaFromJSON,
    HdmapMetaToJSON,
    NewPlan,
    NewPlanFromJSON,
    NewPlanToJSON,
    NewPoi,
    NewPoiFromJSON,
    NewPoiToJSON,
    Plan,
    PlanFromJSON,
    PlanToJSON,
    PlanMeta,
    PlanMetaFromJSON,
    PlanMetaToJSON,
    PoiMeta,
    PoiMetaFromJSON,
    PoiMetaToJSON,
    PoiMetaDetail,
    PoiMetaDetailFromJSON,
    PoiMetaDetailToJSON,
    Result,
    ResultFromJSON,
    ResultToJSON,
    ResultMeta,
    ResultMetaFromJSON,
    ResultMetaToJSON,
    UpdatePlan,
    UpdatePlanFromJSON,
    UpdatePlanToJSON,
    UpdateResult,
    UpdateResultFromJSON,
    UpdateResultToJSON,
} from '../models';

export interface CreatePlanRequest {
    newPlan: NewPlan;
}

export interface CreatePoiRequest {
    newPoi: NewPoi;
}

export interface CreateallvecPlanResultRequest {
    allVec: AllVec;
}

export interface DeletePlanRequest {
    planId: string;
}

export interface DeletePoiRequest {
    poiId: string;
}

export interface DeleteResultRequest {
    resultId: string;
}

export interface GetHdMapRequest {
    fleetId?: string;
}

export interface GetPlanRequest {
    planId: string;
}

export interface GetPlansRequest {
    carId?: string;
    fleetId?: string;
}

export interface GetPoiRequest {
    poiId: string;
}

export interface GetResultRequest {
    resultId: string;
}

export interface GetResultsRequest {
    carId?: string;
    fleetId?: string;
}

export interface PlansPlanIdOptionsRequest {
    planId: string;
}

export interface PoisPoiIdOptionsRequest {
    poiId: string;
}

export interface RegisterResultRequest {
    //contentType: string;
    file: Blob;
    resultName: string;
    fleetId: string;
    carId: string;
    comment?: string;
}

export interface ResultsResultIdOptionsRequest {
    resultId: string;
}

export interface UpdatePlanRequest {
    planId: string;
    updatePlan: UpdatePlan;
}

export interface UpdateResultRequest {
    resultId: string;
    updateResult: UpdateResult;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async allVecOptionsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/all_vec`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async allVecOptions(): Promise<void> {
        await this.allVecOptionsRaw();
    }

    /**
     */
    async createPlanRaw(requestParameters: CreatePlanRequest): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.newPlan === null || requestParameters.newPlan === undefined) {
            throw new runtime.RequiredError('newPlan','Required parameter requestParameters.newPlan was null or undefined when calling createPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPlanToJSON(requestParameters.newPlan),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async createPlan(requestParameters: CreatePlanRequest): Promise<Plan> {
        const response = await this.createPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createPoiRaw(requestParameters: CreatePoiRequest): Promise<runtime.ApiResponse<PoiMeta>> {
        if (requestParameters.newPoi === null || requestParameters.newPoi === undefined) {
            throw new runtime.RequiredError('newPoi','Required parameter requestParameters.newPoi was null or undefined when calling createPoi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/pois`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPoiToJSON(requestParameters.newPoi),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiMetaFromJSON(jsonValue));
    }

    /**
     */
    async createPoi(requestParameters: CreatePoiRequest): Promise<PoiMeta> {
        const response = await this.createPoiRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createallvecPlanResultRaw(requestParameters: CreateallvecPlanResultRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.allVec === null || requestParameters.allVec === undefined) {
            throw new runtime.RequiredError('allVec','Required parameter requestParameters.allVec was null or undefined when calling createallvecPlanResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/all_vec`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllVecToJSON(requestParameters.allVec),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createallvecPlanResult(requestParameters: CreateallvecPlanResultRequest): Promise<void> {
        await this.createallvecPlanResultRaw(requestParameters);
    }

    /**
     */
    async deletePlanRaw(requestParameters: DeletePlanRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.planId === null || requestParameters.planId === undefined) {
            throw new runtime.RequiredError('planId','Required parameter requestParameters.planId was null or undefined when calling deletePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/plans/{plan_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePlan(requestParameters: DeletePlanRequest): Promise<void> {
        await this.deletePlanRaw(requestParameters);
    }

    /**
     */
    async deletePoiRaw(requestParameters: DeletePoiRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling deletePoi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/pois/{poi_id}`.replace(`{${"poi_id"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePoi(requestParameters: DeletePoiRequest): Promise<void> {
        await this.deletePoiRaw(requestParameters);
    }

    /**
     */
    async deleteResultRaw(requestParameters: DeleteResultRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resultId === null || requestParameters.resultId === undefined) {
            throw new runtime.RequiredError('resultId','Required parameter requestParameters.resultId was null or undefined when calling deleteResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/results/{result_id}`.replace(`{${"result_id"}}`, encodeURIComponent(String(requestParameters.resultId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteResult(requestParameters: DeleteResultRequest): Promise<void> {
        await this.deleteResultRaw(requestParameters);
    }

    /**
     */
    async getHdMapRaw(requestParameters: GetHdMapRequest): Promise<runtime.ApiResponse<HdmapMeta>> {
        const queryParameters: any = {};

        if (requestParameters.fleetId !== undefined) {
            queryParameters['fleet_id'] = requestParameters.fleetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/hdmap`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HdmapMetaFromJSON(jsonValue));
    }

    /**
     */
    async getHdMap(requestParameters: GetHdMapRequest): Promise<HdmapMeta> {
        const response = await this.getHdMapRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPlanRaw(requestParameters: GetPlanRequest): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.planId === null || requestParameters.planId === undefined) {
            throw new runtime.RequiredError('planId','Required parameter requestParameters.planId was null or undefined when calling getPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/plans/{plan_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async getPlan(requestParameters: GetPlanRequest): Promise<Plan> {
        const response = await this.getPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPlansRaw(requestParameters: GetPlansRequest): Promise<runtime.ApiResponse<Array<PlanMeta>>> {
        const queryParameters: any = {};

        if (requestParameters.carId !== undefined) {
            queryParameters['car_id'] = requestParameters.carId;
        }

        if (requestParameters.fleetId !== undefined) {
            queryParameters['fleet_id'] = requestParameters.fleetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanMetaFromJSON));
    }

    /**
     */
    async getPlans(requestParameters: GetPlansRequest): Promise<Array<PlanMeta>> {
        const response = await this.getPlansRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPoiRaw(requestParameters: GetPoiRequest): Promise<runtime.ApiResponse<PoiMetaDetail>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling getPoi.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/pois/{poi_id}`.replace(`{${"poi_id"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PoiMetaDetailFromJSON(jsonValue));
    }

    /**
     */
    async getPoi(requestParameters: GetPoiRequest): Promise<PoiMetaDetail> {
        const response = await this.getPoiRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPoisRaw(): Promise<runtime.ApiResponse<Array<PoiMeta>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/pois`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PoiMetaFromJSON));
    }

    /**
     */
    async getPois(): Promise<Array<PoiMeta>> {
        const response = await this.getPoisRaw();
        return await response.value();
    }

    /**
     */
    async getResultRaw(requestParameters: GetResultRequest): Promise<runtime.ApiResponse<Result>> {
        if (requestParameters.resultId === null || requestParameters.resultId === undefined) {
            throw new runtime.RequiredError('resultId','Required parameter requestParameters.resultId was null or undefined when calling getResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/results/{result_id}`.replace(`{${"result_id"}}`, encodeURIComponent(String(requestParameters.resultId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultFromJSON(jsonValue));
    }

    /**
     */
    async getResult(requestParameters: GetResultRequest): Promise<Result> {
        const response = await this.getResultRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getResultsRaw(requestParameters: GetResultsRequest): Promise<runtime.ApiResponse<Array<ResultMeta>>> {
        const queryParameters: any = {};

        if (requestParameters.carId !== undefined) {
            queryParameters['car_id'] = requestParameters.carId;
        }

        if (requestParameters.fleetId !== undefined) {
            queryParameters['fleet_id'] = requestParameters.fleetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResultMetaFromJSON));
    }

    /**
     */
    async getResults(requestParameters: GetResultsRequest): Promise<Array<ResultMeta>> {
        const response = await this.getResultsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async hdmapOptionsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hdmap`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async hdmapOptions(): Promise<void> {
        await this.hdmapOptionsRaw();
    }

    /**
     */
    async plansOptionsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plans`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async plansOptions(): Promise<void> {
        await this.plansOptionsRaw();
    }

    /**
     */
    async plansPlanIdOptionsRaw(requestParameters: PlansPlanIdOptionsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.planId === null || requestParameters.planId === undefined) {
            throw new runtime.RequiredError('planId','Required parameter requestParameters.planId was null or undefined when calling plansPlanIdOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plans/{plan_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async plansPlanIdOptions(requestParameters: PlansPlanIdOptionsRequest): Promise<void> {
        await this.plansPlanIdOptionsRaw(requestParameters);
    }

    /**
     */
    async poisOptionsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pois`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async poisOptions(): Promise<void> {
        await this.poisOptionsRaw();
    }

    /**
     */
    async poisPoiIdOptionsRaw(requestParameters: PoisPoiIdOptionsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.poiId === null || requestParameters.poiId === undefined) {
            throw new runtime.RequiredError('poiId','Required parameter requestParameters.poiId was null or undefined when calling poisPoiIdOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pois/{poi_id}`.replace(`{${"poi_id"}}`, encodeURIComponent(String(requestParameters.poiId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async poisPoiIdOptions(requestParameters: PoisPoiIdOptionsRequest): Promise<void> {
        await this.poisPoiIdOptionsRaw(requestParameters);
    }

    /**
     */
    async registerResultRaw(requestParameters: RegisterResultRequest): Promise<runtime.ApiResponse<Result>> {
        //if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
        //    throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling registerResult.');
        //}

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling registerResult.');
        }

        if (requestParameters.resultName === null || requestParameters.resultName === undefined) {
            throw new runtime.RequiredError('resultName','Required parameter requestParameters.resultName was null or undefined when calling registerResult.');
        }

        if (requestParameters.fleetId === null || requestParameters.fleetId === undefined) {
            throw new runtime.RequiredError('fleetId','Required parameter requestParameters.fleetId was null or undefined when calling registerResult.');
        }

        if (requestParameters.carId === null || requestParameters.carId === undefined) {
            throw new runtime.RequiredError('carId','Required parameter requestParameters.carId was null or undefined when calling registerResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        //if (requestParameters.contentType !== undefined && requestParameters.contentType !== null) {
        //    headerParameters['Content-Type'] = String(requestParameters.contentType);
        //}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.resultName !== undefined) {
            formParams.append('result_name', requestParameters.resultName as any);
        }

        if (requestParameters.comment !== undefined) {
            formParams.append('comment', requestParameters.comment as any);
        }

        if (requestParameters.fleetId !== undefined) {
            formParams.append('fleet_id', requestParameters.fleetId as any);
        }

        if (requestParameters.carId !== undefined) {
            formParams.append('car_id', requestParameters.carId as any);
        }

        const response = await this.request({
            path: `/results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultFromJSON(jsonValue));
    }

    /**
     */
    async registerResult(requestParameters: RegisterResultRequest): Promise<Result> {
        const response = await this.registerResultRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async resultsOptionsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/results`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resultsOptions(): Promise<void> {
        await this.resultsOptionsRaw();
    }

    /**
     */
    async resultsResultIdOptionsRaw(requestParameters: ResultsResultIdOptionsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resultId === null || requestParameters.resultId === undefined) {
            throw new runtime.RequiredError('resultId','Required parameter requestParameters.resultId was null or undefined when calling resultsResultIdOptions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/results/{result_id}`.replace(`{${"result_id"}}`, encodeURIComponent(String(requestParameters.resultId))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resultsResultIdOptions(requestParameters: ResultsResultIdOptionsRequest): Promise<void> {
        await this.resultsResultIdOptionsRaw(requestParameters);
    }

    /**
     */
    async updatePlanRaw(requestParameters: UpdatePlanRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.planId === null || requestParameters.planId === undefined) {
            throw new runtime.RequiredError('planId','Required parameter requestParameters.planId was null or undefined when calling updatePlan.');
        }

        if (requestParameters.updatePlan === null || requestParameters.updatePlan === undefined) {
            throw new runtime.RequiredError('updatePlan','Required parameter requestParameters.updatePlan was null or undefined when calling updatePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/plans/{plan_id}`.replace(`{${"plan_id"}}`, encodeURIComponent(String(requestParameters.planId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePlanToJSON(requestParameters.updatePlan),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePlan(requestParameters: UpdatePlanRequest): Promise<void> {
        await this.updatePlanRaw(requestParameters);
    }

    /**
     */
    async updateResultRaw(requestParameters: UpdateResultRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resultId === null || requestParameters.resultId === undefined) {
            throw new runtime.RequiredError('resultId','Required parameter requestParameters.resultId was null or undefined when calling updateResult.');
        }

        if (requestParameters.updateResult === null || requestParameters.updateResult === undefined) {
            throw new runtime.RequiredError('updateResult','Required parameter requestParameters.updateResult was null or undefined when calling updateResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // kiwi-api-auth authentication
        }

        const response = await this.request({
            path: `/results/{result_id}`.replace(`{${"result_id"}}`, encodeURIComponent(String(requestParameters.resultId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateResultToJSON(requestParameters.updateResult),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateResult(requestParameters: UpdateResultRequest): Promise<void> {
        await this.updateResultRaw(requestParameters);
    }

}
