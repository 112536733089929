/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PathGeoJSON,
    PathGeoJSONFromJSON,
    PathGeoJSONFromJSONTyped,
    PathGeoJSONToJSON,
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdatePlan
 */
export interface UpdatePlan {
    /**
     * 
     * @type {PathGeoJSON}
     * @memberof UpdatePlan
     */
    path_geojson?: PathGeoJSON;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlan
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlan
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePlan
     */
    time?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlan
     */
    plan_name?: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof UpdatePlan
     */
    points?: Array<Point>;
}

export function UpdatePlanFromJSON(json: any): UpdatePlan {
    return UpdatePlanFromJSONTyped(json, false);
}

export function UpdatePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path_geojson': !exists(json, 'path_geojson') ? undefined : PathGeoJSONFromJSON(json['path_geojson']),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'plan_name': !exists(json, 'plan_name') ? undefined : json['plan_name'],
        'points': !exists(json, 'points') ? undefined : ((json['points'] as Array<any>).map(PointFromJSON)),
    };
}

export function UpdatePlanToJSON(value?: UpdatePlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path_geojson': PathGeoJSONToJSON(value.path_geojson),
        'distance': value.distance,
        'comment': value.comment,
        'time': value.time,
        'plan_name': value.plan_name,
        'points': value.points === undefined ? undefined : ((value.points as Array<any>).map(PointToJSON)),
    };
}


