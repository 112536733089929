import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

export function ModalAlertDeletePlan({
  deleteModalShow,
  cancelDeletePlan,
  deletePlan,
  deleteSpinStatus,
}) {
  return (
    <>
      <Modal
        show={deleteModalShow}
        onHide={cancelDeletePlan}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
          <Modal.Title className="titlecustom">
            Comfirm delete Route
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
          Are you sure you want to delete the route?
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
          <Button variant="red" onClick={deletePlan}>
            Delete
            {deleteSpinStatus ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Button>
          <Button variant="grey" onClick={cancelDeletePlan}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
