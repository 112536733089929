import { useEffect, useState } from "react";

export function useSummaryMap({
  googleMap,
  mapContainerRef,
  initialConfig,
  mapMileageInfoRef,
  mapNoticeZoomRef,
  setZoomMax,
}) {
  const [map, setMap] = useState(null);

  useEffect(
    () => {
      // googleMapかmapContainerRefが初期化されてなければ何もしない
      if (!googleMap || !mapContainerRef.current) {
        return;
      }

      const MapTypeIdROADMAP = googleMap.maps.MapTypeId.ROADMAP;
      initialConfig.mapTypeId = MapTypeIdROADMAP;

      const map = new googleMap.maps.Map(
        mapContainerRef.current,
        initialConfig
      );

      //mileage表示部分を地図に配置
      mapMileageInfoRef.current.style.display = "block";
      map.controls[googleMap.maps.ControlPosition.TOP_RIGHT].push(
        mapMileageInfoRef.current
      );

      mapNoticeZoomRef.current.style.display = "block";
      map.controls[googleMap.maps.ControlPosition.TOP_CENTER].push(
        mapNoticeZoomRef.current
      );
      //タイルのgridを表示する【開発用】
      // class CoordMapType {
      //   constructor(tileSize) {
      //     this.tileSize = tileSize;
      //   }
      //   getTile(coord, zoom, ownerDocument) {
      //     const div = ownerDocument.createElement("div");
      //     div.innerHTML = String(coord);
      //     div.style.width = this.tileSize.width + "px";
      //     div.style.height = this.tileSize.height + "px";
      //     div.style.fontSize = "10";
      //     div.style.borderStyle = "solid";
      //     div.style.borderWidth = "1px";
      //     div.style.borderColor = "#AAAAAA";
      //     return div;
      //   }
      //   releaseTile(tile) {}
      // }

      // map.overlayMapTypes.insertAt(
      //   0,
      //   new CoordMapType(new googleMap.maps.Size(256, 256))
      // );

      setMap(map);
    },
    // googleMapかmapContainerRefが変化したらeffectが発火する。
    // initialConfigは変わったとしても再マウントするとおかしなことになるので更新対象にしない
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googleMap, mapMileageInfoRef.current]
  );
  useEffect(() => {
    if (!map) {
      return;
    }

    const listener = map.addListener("zoom_changed", function (e) {
      const currentZoom = map.getZoom();
      const max = initialConfig.maxZoom;
      //console.debug("useMap", route);
      if (currentZoom === max) {
        setZoomMax(true);
      } else {
        setZoomMax(false);
      }
    });

    return () => {
      listener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfig.maxZoom, map, mapMileageInfoRef]);
  return map;
}
