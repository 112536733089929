import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";

export function ModalPOIDBRegisterMain({
    poidbMainModalShow,
    poidbCancelRegisterClick,
    poidbRegisterClick,
    poidbRegisterFile,
    poidbRegisterFileNameRef,
    poidbCanFilterOption,
    poidbRegisterPassword,
    loadingState,
  }) {
    return (
      <>
        <Modal
          backdrop ='static'
          keyboard = {false}
          show={poidbMainModalShow}
          onHide={poidbCancelRegisterClick}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
            <Modal.Title className="titlecustom">
                POIDB Register
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
            <Form.Group>
                <Form.Label
                    className="titlecustom"
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                    Select File
                </Form.Label>
                <Form.File.Input
                    onChange={poidbRegisterFile}
                    accept=".csv"
                />
                <Form.Text className="text-muted">*recommend less than 5MB of data</Form.Text>

                <Form.Label
                    className="titlecustom"
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                    Name
                </Form.Label>
                <Form.Control type="text" ref={poidbRegisterFileNameRef} maxLength="64" placeholder="Title name" />
                <Form.Text className="text-muted">*limits to 60 characters</Form.Text>

                <Form.Label
                    className="titlecustom"
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                    Filter Option
                </Form.Label>
                <Form.Text className="text-muted">*If there are less than 100 sets of data, it is available as a filter option</Form.Text>
                {/* <br/>
                {poidbCanFilterOption.map((e)=>(
                  <>{e}<br/></>
                ))} */}
                <Form.Label
                  className="titlecustom"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                    PassWord
                </Form.Label>
                <Form.Control type="password" ref={poidbRegisterPassword} maxLength="64" placeholder="Password"/>

            </Form.Group>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
            <Button variant="outline-dark" onClick={poidbCancelRegisterClick} disabled={loadingState}>
              {loadingState? "Loading...":"Cancel"}
            </Button>
            <Button variant="dark" onClick={poidbRegisterClick} disabled={loadingState}>
              {loadingState? "Loading...":"Register"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  