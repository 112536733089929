/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    lng: number;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    type: PointTypeV4Compat;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    distanceFromBefore?: number;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    comment?: string;
}

/**
* @export
* @enum {string}
*/
export enum PointTypeV4Compat {
    Origin = 'origin',
    Waypoint = 'waypoint',
    Destination = 'destination'
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lng': json['lng'],
        'type': json['type'],
        'lat': json['lat'],
        'distanceFromBefore': !exists(json, 'distanceFromBefore') ? undefined : json['distanceFromBefore'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lng': value.lng,
        'type': value.type,
        'lat': value.lat,
        'distanceFromBefore': value.distanceFromBefore,
        'comment': value.comment,
    };
}


