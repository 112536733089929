import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//機能説明をtooltipで表示できるInfoIcon
export const InfoIcon = ({ message, placement = "right" }) => {
  return (
    <>
      <OverlayTrigger
        placement={placement}
        overlay={<Tooltip id={`tooltip-right`}>{message}</Tooltip>}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="Info"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M11.5 7h.5" />
          <path d="M10 11h2v5" />
          <path d="M10 16h4" />
        </svg>
      </OverlayTrigger>
    </>
  );
};
