import React from "react";
import { Card } from "react-bootstrap";
import { FileInputPart } from "./FileInputPart";
import { HDmapPart } from "./HDmapPart";

export const OptionPane = ({
  map,
  googleMap,
  infoWindowsFileImport,
  setInfoWindowsFileImport,
  fleetId,
}) => {
  return (
    <>
      <Card style={{ height: "34%", padding: 0 }}>
        <Card.Header
          className="titlecustom"
          style={{
            padding: "5px",
            color: "#585858",
            fontSize: "18px",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Options
          </div>
        </Card.Header>

        <Card.Body
          style={{
            height: "100%",
            overflowY: "scroll",
            padding: "5px",
            margin: 0,
          }}
        >
          <FileInputPart
            map={map}
            googleMap={googleMap}
            infoWindowsFileImport={infoWindowsFileImport}
            setInfoWindowsFileImport={setInfoWindowsFileImport}
          />

          <HDmapPart map={map} googleMap={googleMap} fleetId={fleetId} />
        </Card.Body>
      </Card>
    </>
  );
};
