import BootstrapSwitchButton from "bootstrap-switch-button-react";
import geojsonvt from "geojson-vt";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useUser } from "../../../hooks/useUser";
import { InfoIcon } from "../infoIcon";
import { kiwiApi } from "../kiwiApi";
import { getCoverageData } from "./getCoverageData";

export const HdmapCoverageButton = ({ fleetId, map, googleMap }) => {
  const { user } = useUser();
  const [hdmapInfo, setHdmapInfo] = useState(null); //Hdmap情報
  const [hdmapCoverageJson, setHdmapCoverageJson] = useState(null); //coverageJson
  const [hdmapToggle, setHdmapToggle] = useState(false); //Hdmap表示・非表示のトグル

  useEffect(() => {
    kiwiApi(user.token)
      .getHdMap({ fleetId: fleetId })
      .then((res) => {
        setHdmapInfo(res);
        //console.log(res);
        const objectKey = res.coverage_key;
        getCoverageData({ objectKey, setHdmapData: setHdmapCoverageJson }); //データを取得してセットする
      })
      .catch((error) => {
        //console.log("対応するmapなし");
        setHdmapInfo(null);
        setHdmapCoverageJson(null);
      });
  }, [fleetId, user.token]);

  useEffect(() => {
    //mapのZoomとdragイベントが起きたらマップ範囲を取得する
    if (map === null) {
      return;
    }
    //mapのマウスイベントリストは下記リンク参照
    //https://developers.google.com/maps/documentation/javascript/reference/map#Map-Events
    //
    if (hdmapToggle === false) {
      //map.overlayMapTypesはMVCArray https://developers.google.com/maps/documentation/javascript/reference/map#Map-Properties
      //MVCArrayに対する操作：https://developers.google.com/maps/documentation/javascript/reference/event#MVCArray
      const MapTypesLength = map.overlayMapTypes.getLength();
      if (MapTypesLength === 1) {
        map.overlayMapTypes.removeAt(0);
      }
    }
    if (hdmapCoverageJson !== null && hdmapToggle === true) {
      //geoJsonをベクトルタイル化
      const tileIndex = geojsonvt(hdmapCoverageJson, {
        maxZoom: 24,
      });

      class CoordMapTypeCanvas {
        constructor(tileSize) {
          this.tileSize = tileSize;
        }
        getTile(coord, zoom, ownerDocument) {
          //console.log(zoom);
          if (zoom > 23) {
            return;
          }
          const canvas = ownerDocument.createElement("canvas");
          canvas.width = 256;
          canvas.height = 256;

          const tile = tileIndex.getTile(zoom, coord.x, coord.y);
          // console.log(tile);
          if (tile === undefined || tile === null) {
            return;
          }
          const features = tile.features[0];
          const ctx = canvas.getContext("2d"); // コンテキストを取得する
          if (features === undefined) {
            return;
          }
          const circleSize = zoom < 4 ? 1 : zoom < 6 ? 2 : zoom < 10 ? 3 : 5;
          const points = features.geometry.filter((f, index) => {
            const mabiki = zoom < 5 ? 30 : zoom < 6 ? 10 : zoom < 10 ? 5 : 1;
            return index % mabiki === 0;
          });

          points.forEach((pos) => {
            const x = (256 * pos[0]) / 4096;
            const y = (256 * pos[1]) / 4096;
            // 描画開始位置を初期化する
            ctx.beginPath(); // 開始位置に移動する
            ctx.fillStyle = "rgb(0,250,0, 0.5)";
            ctx.arc(x, y, circleSize, 0, Math.PI * 2, false); // 円を描く位置を決める
            ctx.fill(); // 実際に円を書く
          });

          return canvas;
        }
        releaseTile(tile) {}
      }

      //下記はedgeのみ色を分ける場合に使用する
      // const tileIndexEdge = geojsonvt(edgeJson, {
      //   maxZoom: 24,
      // });

      // class EdgeMapTypeCanvas {
      //   constructor(tileSize) {
      //     this.tileSize = tileSize;
      //   }
      //   getTile(coord, zoom, ownerDocument) {
      //     console.log(zoom);
      //     if (zoom > 23) {
      //       return;
      //     }
      //     const canvas = ownerDocument.createElement("canvas");
      //     canvas.width = 256;
      //     canvas.height = 256;

      //     const tile = tileIndexEdge.getTile(zoom, coord.x, coord.y);
      //     // console.log(tile);
      //     if (tile === undefined || tile === null) {
      //       return;
      //     }
      //     const features = tile.features[0];
      //     const ctx = canvas.getContext("2d"); // コンテキストを取得する
      //     if (features === undefined) {
      //       return;
      //     }
      //     const circleSize = zoom < 4 ? 1 : zoom < 6 ? 2 : zoom < 10 ? 3 : 5;
      //     // const circleSize = zoom < 4 ? 1 : 2;
      //     const points = features.geometry.filter((f, index) => {
      //       const mabiki = zoom < 5 ? 30 : zoom < 6 ? 10 : zoom < 10 ? 5 : 1;
      //       return index % mabiki === 0;
      //     });

      //     points.forEach((pos) => {
      //       const x = (256 * pos[0]) / 4096;
      //       const y = (256 * pos[1]) / 4096;
      //       // 描画開始位置を初期化する
      //       ctx.beginPath(); // 開始位置に移動する
      //       ctx.fillStyle =
      //         zoom < 10 ? "rgb(0,255,0,0.5)" : "rgb(0,128,0, 0.5)";
      //       ctx.arc(x, y, circleSize, 0, Math.PI * 2, false); // 円を描く位置を決める
      //       ctx.fill(); // 実際に円を書く
      //     });

      //     return canvas;
      //   }
      //   releaseTile(tile) {}
      // }

      map.overlayMapTypes.insertAt(
        0,
        new CoordMapTypeCanvas(new googleMap.maps.Size(256, 256))
      );
      // map.overlayMapTypes.insertAt(
      //   1,
      //   new EdgeMapTypeCanvas(new googleMap.maps.Size(256, 256))
      // );
    }
  }, [googleMap, map, hdmapCoverageJson, hdmapToggle]);

  if (hdmapInfo === null) {
    return <></>;
  }
  return (
    <>
      <div
        className="titlecustom"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          padding: 0,
          margin: "5px",
        }}
      >
        HDmap
      </div>

      <div style={{ display: "flex" }}>
        <div
          className="titlecustom"
          style={{
            margin: "5px",
            background: `linear-gradient(transparent 80%, rgb(0,255,0) 80%)`,
          }}
        >
          {`${hdmapInfo.provider} - ${hdmapInfo.version}`}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
          }}
        >
          <InfoIcon message="It shows node points rode segments cross and join " />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {hdmapCoverageJson !== null ? (
            <BootstrapSwitchButton
              checked={hdmapToggle}
              size="sm"
              onstyle="dark"
              onChange={() => setHdmapToggle(!hdmapToggle)}
            />
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        </div>
      </div>
    </>
  );
};
