/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PathGeoJSONGeometry
 */
export interface PathGeoJSONGeometry {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof PathGeoJSONGeometry
     */
    coordinates?: Array<Array<number>>;
    /**
     * 
     * @type {string}
     * @memberof PathGeoJSONGeometry
     */
    type?: PathGeoJSONGeometryTypeV4Compat;
}

/**
* @export
* @enum {string}
*/
export enum PathGeoJSONGeometryTypeV4Compat {
    LineString = 'LineString'
}

export function PathGeoJSONGeometryFromJSON(json: any): PathGeoJSONGeometry {
    return PathGeoJSONGeometryFromJSONTyped(json, false);
}

export function PathGeoJSONGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathGeoJSONGeometry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function PathGeoJSONGeometryToJSON(value?: PathGeoJSONGeometry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordinates': value.coordinates,
        'type': value.type,
    };
}


