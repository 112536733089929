import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useGoogleMap } from "../../hooks/useGoogleMap";
import { useOverviewMap } from "../../hooks/useOverviewMap";
import { useUser } from "../../hooks/useUser";
import { BreadcrumbBar } from "../BreadcrumbBar";
import { kiwiApiVehicles } from "../../api/kiwiApi/kiwiApis";

const API_KEY = process.env["REACT_APP_MAP_API_KEY"];
const initialConfig = {
  center: { lat: 38.86704032, lng: -77.04010977 },
  zoom: 7,
  mapTypeControl: true,
};

function VecSelectList() {
  const [vecs, setVecs] = useState(null);
  const [rawVecs, setRawVecs] = useState([]);
  const [vecsLoadStatus, setVecsLoadStatus] = useState(true)
  const [loadingComment, setLoadingComment] = useState("Loading... data...")
  const [selectedVecId, setSelectedVecId] = useState(null);
  const history = useHistory();
  const params = useParams();
  const mapContainerRef = useRef(null);
  const mapSearchBoxRef = useRef(null);
  const googleMap = useGoogleMap(API_KEY);
  const { user } = useUser();
  const map = useOverviewMap({
    googleMap,
    mapContainerRef,
    initialConfig,
    mapSearchBoxRef,
  });
  //console.log(map);
  useEffect(() => {
    //Get Vecs from fleetid
    const fleetId = params.fleetid;
    (async()=>{
      const rawVecsList = await kiwiApiVehicles(user.token, fleetId)
      if(rawVecsList == "error"){
        history.push(`/Fleets`)
        return
      }
      setRawVecs(rawVecsList)
      // console.log(rawVecsList)
      if(rawVecsList.vehicles_data == []){
        setLoadingComment("There is no vehicles or Fail to load vehicles")
        return
      }
      const fixedVecsList =rawVecsList.vehicles_data.map((item) => {
        return {
          fleet_id : item.project_id.toString(),
          car_id : item.vehicle_id.toString(),
          name : item.vec
        }      
      })
      setVecs(fixedVecsList);
      setVecsLoadStatus(false)
    })();
    // const carId = vecsList[1].car_id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.fleetid, user]);

  function clickedVecPlan(vecid) {
    const fleetid = params.fleetid;
    const fleetName = params.fleetName;

    vecs.forEach((vec) => {
      if (vec.car_id === vecid) {
        const vecName = vec.name;
        //const viewType = "Plan";
        history.push(`/${fleetName}/${fleetid}/${vecName}/${vecid}/Plan`);
      }
    });
  }

  function clickedVecResults(vecid) {
    const fleetid = params.fleetid;
    const fleetName = params.fleetName;

    vecs.forEach((vec) => {
      if (vec.car_id === vecid) {
        const vecName = vec.name;
        history.push(`/${fleetName}/${fleetid}/${vecName}/${vecid}/Results`);
      }
    });
  }

  function clickedVecTest(vecid) {
    const fleetid = params.fleetid;
    const fleetName = params.fleetName;

    vecs.forEach((vec) => {
      if (vec.car_id === vecid) {
        const vecName = vec.name;
        history.push(`/${fleetName}/${fleetid}/${vecName}/${vecid}/FieldTest`);
      }
    });
  }

  function clickedAllResult() {
    const fleetid = params.fleetid;
    const fleetName = params.fleetName;

    history.push(`/${fleetName}/${fleetid}/summary`);
  }

  function buttonRestricted(mode){
    if (selectedVecId === null){
      return true
    }else{
      const authority = rawVecs.authority
      if (typeof authority == "string"){
        if (authority == "admin"){
          return false
        } 
      }else{
        if(authority.includes(mode)){
          return false
        }else{
          return true
        }
      }
    }
  }

  return (
    <>
      <BreadcrumbBar params={params} />
      <Container style={{ margin: 0, padding: 0 }}>
        <Row
          style={{
            margin: 0,
            padding: 0,
            width: "100vw",
            height: "calc(100vh - 104.8px)",
          }}
        >
          <Col lg={3} style={{ margin: 0, padding: 0, height: "100%" }}>
            <div
              style={{
                overflowY: "scroll",
                height: "80%",
                margin: 0,
                marginLeft: "10px",
              }}
            >
              <div
                className="titlecustom"
                style={{
                  fontSize: "30px",
                  color: "#585858",
                  margin: "0px",
                  padding: "0px",
                  textAlign: "center",
                }}
              >
                VEHICLES
              </div>
              {vecsLoadStatus ? (
                  <> 
                    {loadingComment}
                  </>
                ):(
                  <>
                    {vecs.map((vec, key) => (
                      <Card
                        key={key}
                        id={vec.car_id}
                        style={{
                          margin: "5px",
                          fontSize: "15px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Card.Body
                          onClick={() => setSelectedVecId(vec.car_id)}
                          style={{
                            backgroundColor:
                              selectedVecId === vec.car_id ? "lightgrey" : null,
                            padding: "10px",
                          }}
                        >
                          {vec.name}
                        </Card.Body>
                      </Card>
                    ))}
                  </>
              )}
            </div>
            <div
              style={{
                height: "20%",
                margin: 0,
                marginLeft: "0",
              }}
            >
              <div
                style={{
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: "87%",
                  textAlign: "center",
                }}
              >
                <Button
                  variant="red"
                  size="sm"
                  style={{
                    margin: "5px",
                    width: "28%",
                    fontSize: "15px",
                    minWidth: "80px",
                  }}
                  onClick={() => clickedVecPlan(selectedVecId)}
                  disabled={buttonRestricted("Plan")}
                >
                  PLAN
                </Button>

                <Button
                  variant="red"
                  size="sm"
                  style={{
                    margin: "5px",
                    width: "28%",
                    fontSize: "15px",
                    minWidth: "80px",
                  }}
                  onClick={() => clickedVecResults(selectedVecId)}
                  disabled={buttonRestricted("Result")}
                >
                  RESULT
                </Button>

                <Button
                  variant="red"
                  size="sm"
                  style={{
                    margin: "5px",
                    width: "28%",
                    fontSize: "15px",
                    minWidth: "80px",
                  }}
                  onClick={() => clickedVecTest(selectedVecId)}
                  disabled={buttonRestricted("Test")}
                  // disabled={true}
                >
                  TEST
                </Button>
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Button
                  // className="titlecustom"
                  variant="outline-dark"
                  style={{
                    margin: "10px auto",
                    width: "87%",
                    fontSize: "15px",
                  }}
                  onClick={() => clickedAllResult()}
                  disabled={false}
                  block
                >
                  ALL VECS RESULT
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={9} style={{ margin: 0, padding: 0 }}>
            <div ref={mapSearchBoxRef}></div>
            {/* <div style={{ height: "70vh", width: "100%" }}> */}
            <div
              style={{
                height: "100%",
                // width: "100%",
                position: "relative",
              }}
              ref={mapContainerRef}
            ></div>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { VecSelectList };
