import { lineStyle_result_normal } from "./lineStyles";

export function addGeoJson({ map, addData }) {
  if (addData.path_geojson !== undefined) {
    const category = "result";
    const comment = addData.comment;
    const distance = addData.distance;
    const result_id = addData.result_id;
    const result_name = addData.result_name;
    const time = addData.time;

    addData.path_geojson.properties = {
      category,
      comment,
      distance,
      time,
      result_id,
      result_name,
    };

    addData.path_geojson.id = result_id; //FeatureIDを追加する(plan_idで追加)

    map.data.addGeoJson(addData.path_geojson);
  }
  map.data.forEach((feature) => {
    const category = feature.getProperty("category");
    if (category === "result") {
      map.data.overrideStyle(feature, lineStyle_result_normal);
    }
  });
}

export function removeGeoJson({ map, removeId }) {
  map.data.forEach((feature) => {
    const category = feature.getProperty("category");
    if (category === "result") {
      const result_id = feature.getProperty("result_id");
      if (result_id === removeId) {
        map.data.remove(feature);
      }
    }
  });
}
