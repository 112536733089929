import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, useParams } from "react-router-dom";
import { nextPointId, useCurrentRoute } from "../../../hooks/useCurrentPlan";
import { useGoogleMap } from "../../../hooks/useGoogleMap";
import { useOverviewMap } from "../../../hooks/useOverviewMap";
import { useUser } from "../../../hooks/useUser";
import { BreadcrumbBar } from "../../BreadcrumbBar";
import { getPlan } from "../getPlan";
import { getPlans } from "../getPlans";
import { MapSearchBox } from "../MapSearchBox";
import { Marker } from "../Marker";
import { NaviPane } from "../NaviPane";
import { PlanPointDetail } from "../PlanPointDetail";
import { PlanPointList } from "../PlanPointList";
import style from "../Route.module.css";
import { kiwiApiVehicles } from "../../../api/kiwiApi/kiwiApis";

const API_KEY = process.env["REACT_APP_MAP_API_KEY"];

const initialConfig = {
  center: { lat: 35.6432027, lng: 139.6729435 },
  zoom: 9,
  mapTypeControl: true,
};

export const FieldTestPage = () => {
  const mode = "fieldTest";
  const googleMap = useGoogleMap(API_KEY);
  const mapContainerRef = useRef(null);
  const mapSearchBoxRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  /** @type {{fleetid: string, vecid: string}}  */
  const fleetId = params.fleetid;
  const carId = params.vecid;
  const {
    route,
    setRoute,
    maximumPointsAlert,
    setMaximumPointAlert,
    setName,
    setRouteComment,
    updatePointComment,
    updatePointsOrder,
  } = useCurrentRoute();
  const { user } = useUser();

  const pointCommentArea = useRef(null);

  const [isPointCommentOpened, setIsPointCommentOpened] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);
  const [getPlanStatus, setGetPlanStatus] = useState(false);
  const [watchId, setWatchId] = useState(null);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    timestamp: null,
    accuracy: null,
  });

  /** @type {import("../../../hooks/useCurrentPlan").Route[]} */
  const [plans, setPlans] = useState(null);

  //router
  useEffect(()=>{
    (async()=>{
      const fleetid = params.fleetid;
      const fleetName = params.fleetName;
      const rawVecsList = await kiwiApiVehicles(user.token, fleetid)
      if(rawVecsList == "error"){
        history.push(`/Fleets`);
        return
      }
      const authority = rawVecsList.authority
      if (typeof authority == "string"){
        if (authority != "admin"){
          history.push(`/${fleetName}/${fleetid}/Vecs`);
        } 
      }else{
        if(!authority.includes("Test")){
          history.push(`/${fleetName}/${fleetid}/Vecs`);
        }
      }
    })();
  },[])
  //router

  useEffect(() => {
    getPlans({ fleetId, carId, setPlans, user, setGetPlanStatus });
  }, [fleetId, user, carId]);
  useEffect(() => {
    if (plans === null) {
      return;
    }

    if (plans.length !== 0) {
      const planId = plans[0].plan_id;
      getPlan({ planId, user, nextPointId, setRoute, setGetPlanStatus });
    } else {
      //routeが1つもない場合はundefinedをsetする
      setRoute(undefined);
      setGetPlanStatus(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const map = useOverviewMap({
    googleMap,
    mapContainerRef,
    initialConfig,
    mapSearchBoxRef,
  });

  //現在地取得
  function getPosition() {
    function success(position) {
      const { timestamp } = position;
      const { latitude, longitude, accuracy } = position.coords;
      //console.log("getPosition", latitude, longitude);
      setLocation({ latitude, longitude, timestamp, accuracy });
    }

    function error(error) {
      window.alert(`Sorry, no position available. ${error.message}`);
      console.log("Sorry, no position available.");
      navigator.geolocation.clearWatch(watchId);
      setWatchId(null);
      setCurrentPoint(null);
    }

    const timeout = 500;
    const options = {
      enableHighAccuracy: true,
      maximumAge: 60000,
      timeout: Number(timeout),
    };

    const watchID = navigator.geolocation.watchPosition(
      success,
      error,
      options
    );
    console.log("watchID", watchID);
    setWatchId(watchID);
  }
  function stopWatch() {
    console.log("stop");
    navigator.geolocation.clearWatch(watchId);
    setLocation({
      latitude: null,
      longitude: null,
      timestamp: null,
      accuracy: null,
    });
  }

  useEffect(() => {
    //現在値表示
    if (map === null || watchId === null) {
      return;
    }

    const currentPointFeature = map.data.getFeatureById(watchId);
    if (location.latitude === null && currentPointFeature === undefined) {
      return;
    } else if (
      location.latitude === null &&
      currentPointFeature !== undefined
    ) {
      console.log("clearFeature");
      map.data.remove(currentPointFeature);
      setWatchId(null);
    } else if (
      location.latitude !== null &&
      currentPointFeature === undefined
    ) {
      const pointJson = {
        type: "Feature",
        id: watchId,
        geometry: {
          type: "Point",
          coordinates: [location.longitude, location.latitude],
        },
        properties: {
          timestamp: location.timestamp,
          type: "currentPosition",
        },
      };
      map.data.addGeoJson(pointJson);
      const feature = map.data.getFeatureById(watchId);
      //console.log("map feature",map,feature)
      map.data.overrideStyle(feature, {
        icon: {
          path: googleMap.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillColor: "blue",
          fillOpacity: 0.8,
          strokeWeight: 1,
        },
      });
      //現在位置を中心にする
      map.panTo({ lat: location.latitude, lng: location.longitude });
    } else {
      //すでに描画されている位置を更新する
      //console.log("map, currentfeature",map,currentPointFeature)
      currentPointFeature.setGeometry({
        lng: location.longitude,
        lat: location.latitude,
      });
      map.data.overrideStyle(currentPointFeature, {
        icon: {
          path: googleMap.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillColor: "blue",
          fillOpacity: 0.8,
          strokeWeight: 1,
        },
      });
      map.panTo({ lat: location.latitude, lng: location.longitude });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, location.latitude]);

  //方向付でpath表示するためのObject
  const polylineObject = useRef(null);

  useEffect(() => {
    if (map === null || route === undefined) {
      return;
    } else if (route.path_geojson === undefined) {
      polylineObject.current = null;
    } else {
      const coods = route.path_geojson.geometry.coordinates.map((c) => {
        // let mark = new googleMap.maps.Marker({
        //   position: {lat: c[1], lng: c[0]},
        //   label: ""
        //   })
        //   mark.setMap(map)
        return { lat: c[1], lng: c[0] };
      });

      const lineSymbol = {
        path: googleMap.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeColor: "#A10202",
        strokeWeight: 3,
        scale: 4,
      };
      const line = new googleMap.maps.Polyline({
        path: coods,
        strokeColor: "#A10202",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        icons: [
          {
            icon: lineSymbol,
            offset: "100%",
            repeat: "100px",
          },
        ],
      });
      polylineObject.current = line;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, route]);

  useEffect(() => {
    if (polylineObject.current === null) {
      return;
    } else {
      polylineObject.current.setMap(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polylineObject.current, route]);

  useEffect(() => {
    return () => {
      if (polylineObject.current) {
        polylineObject.current.setMap(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  if (plans === null || route === null) {
    // return <p>Loading...</p>;
  }
  if (map !== null) {
  }

  return (
    <>
      <BreadcrumbBar params={params} />
      <div style={{ position: "relative" }}>
        <LoadingOverlay
          active={getPlanStatus}
          spinner
          text="Loading your content..."
        >
          <Container fluid>
            {/* Header/BreadcrumbBarの高さの合計が104.8px */}
            <Row
              style={{
                height: "calc(100vh - 104.8px)",
                position: "relative",
              }}
            >
              <Col sm={2} className={style.routePageCol}>
                <NaviPane
                  fleetId={params.fleetid}
                  vecId={params.vecid}
                  plans={plans}
                  setPlans={setPlans}
                  route={route}
                  setRoute={setRoute}
                  setName={setName}
                  setRouteComment={setRouteComment}
                  map={map}
                  googleMap={googleMap}
                  setGetPlanStatus={setGetPlanStatus}
                  getPosition={getPosition}
                  location={location}
                  mode={mode}
                  watchId={watchId}
                  stopWatch={stopWatch}
                />
              </Col>
              <Col sm={1} className={style.routePageCol}>
                <PlanPointList
                  map={map}
                  route={route}
                  routePoints={Boolean(route) ? route.points : null}
                  updatePointsOrder={updatePointsOrder}
                  // deletePoint={deletePoint}
                  currentPoint={currentPoint}
                  setCurrentPoint={setCurrentPoint}
                  setIsPointCommentOpened={setIsPointCommentOpened}
                  maximumPointsAlert={maximumPointsAlert}
                  setMaximumPointAlert={setMaximumPointAlert}
                  mode={mode}
                />
                {/* <div>
                  <input type="number" defaultValue={1000} id="timeoutValue" />
                </div> */}
              </Col>
              <Col sm={9} className={style.routePageCol}>
                <>
                  <div
                    style={{
                      height: "100%",
                      position: "relative",
                    }}
                    ref={mapContainerRef}
                  >
                    {map === null ||
                    googleMap === null ||
                    route === undefined ||
                    route.points === undefined
                      ? null
                      : route.points.map((point, index) => (
                          <Marker
                            key={point.id}
                            lat={point.lat}
                            lng={point.lng}
                            type={point.type}
                            label={(index + 1).toString()}
                            comment={point.comment}
                            //currentPointはnullのこともある
                            isSelected={
                              Boolean(currentPoint) &&
                              currentPoint.id === point.id
                            }
                            googleMap={googleMap}
                            map={map}
                            onClick={(e) => {
                              setCurrentPoint(point);
                              setIsPointCommentOpened(true);
                            }}
                            draggable={false}
                          ></Marker>
                        ))}
                  </div>
                  <div ref={mapSearchBoxRef} style={{ margin: "10px" }}>
                    {route === undefined ||
                    route === null ||
                    route.points === undefined ? null : (
                      <MapSearchBox
                        googleMap={googleMap}
                        map={map}
                        points={route.points}
                        mode={mode}
                      />
                    )}
                  </div>
                  <div
                    ref={pointCommentArea}
                    style={{
                      position: "fixed",
                      bottom: "0",
                      width: "calc(900vw / 12)",
                      right: "0",
                      margin: 0,
                    }}
                  >
                    <PlanPointDetail
                      isPointCommentOpened={isPointCommentOpened}
                      setIsPointCommentOpened={setIsPointCommentOpened}
                      currentPoint={currentPoint}
                      setCurrentPoint={setCurrentPoint}
                      setComment={updatePointComment}
                      mode={mode}
                    />
                  </div>
                </>
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
      </div>
    </>
  );
};
