/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PathGeoJSON,
    PathGeoJSONFromJSON,
    PathGeoJSONFromJSONTyped,
    PathGeoJSONToJSON,
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    plan_name: string;
    /**
     * 
     * @type {PathGeoJSON}
     * @memberof Plan
     */
    path_geojson: PathGeoJSON;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    fleet_id: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    car_id: string;
    /**
     * 
     * @type {object}
     * @memberof Plan
     */
    plan_id: object;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    distance?: number;
    /**
     * 
     * @type {Array<Point>}
     * @memberof Plan
     */
    points?: Array<Point>;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    time?: number;
}

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': json['created_at'],
        'created_by': json['created_by'],
        'plan_name': json['plan_name'],
        'path_geojson': PathGeoJSONFromJSON(json['path_geojson']),
        'updated_at': json['updated_at'],
        'updated_by': json['updated_by'],
        'fleet_id': json['fleet_id'],
        'car_id': json['car_id'],
        'plan_id': json['plan_id'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'points': !exists(json, 'points') ? undefined : ((json['points'] as Array<any>).map(PointFromJSON)),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'time': !exists(json, 'time') ? undefined : json['time'],
    };
}

export function PlanToJSON(value?: Plan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': value.created_at,
        'created_by': value.created_by,
        'plan_name': value.plan_name,
        'path_geojson': PathGeoJSONToJSON(value.path_geojson),
        'updated_at': value.updated_at,
        'updated_by': value.updated_by,
        'fleet_id': value.fleet_id,
        'car_id': value.car_id,
        'plan_id': value.plan_id,
        'distance': value.distance,
        'points': value.points === undefined ? undefined : ((value.points as Array<any>).map(PointToJSON)),
        'comment': value.comment,
        'time': value.time,
    };
}


