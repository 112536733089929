import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useUser } from "../../../hooks/useUser";
import Plus from "../../../image/plus.png";
import { Result } from "../../../types";
import { kiwiApi } from "../kiwiApi";
import { addGeoJson } from "./addRemoveGeoJson";

interface ModalCopyRouteProps {
  show: boolean;
  onHide: () => void; //void=何も返さない
  fleetId: string;
  carId: string;
  fleetName: string;
  carName: string;
  resultRoutes: Result[];
  setResultRoutes: (results: Result[]) => void;
  setIsShowMessage: (isShowMessage: boolean) => void;
  setModalMessageConfirm: (modalMessageConfirm: string) => void;
  setModalTitleConfirm: (modalTitleConfirm: string) => void;
  map: any;
}

function ModalUploadRoute({
  //   copiedRouteId,
  show,
  onHide,
  fleetId,
  carId,
  fleetName,
  carName,
  resultRoutes,
  setResultRoutes,
  setIsShowMessage,
  setModalMessageConfirm,
  setModalTitleConfirm,
  map,
}: ModalCopyRouteProps) {
  const { user } = useUser();
  const [file, setFile] = useState<any>(undefined);
  const [newResultName, setNewResultName] = useState<string>("");
  const [resultComment, setResultComment] = useState<string>("");

  const [duringRegisterResult, setDuringRegisterResult] = useState<boolean>(
    false
  );

  function closeModal() {
    //Modalを閉じる＆選択肢の中身の初期化
    onHide();
    setNewResultName("");
    setResultComment("");
    setFile(undefined);
  }
  type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
  function onChangeResultName(e: ChangeEvent) {
    const newResultName = e.target.value;
    //console.log(newResultName);
    setNewResultName(newResultName);
  }
  function onChangeResultComment(e: ChangeEvent) {
    const newResultComment = e.target.value;
    setResultComment(newResultComment);
  }
  // const { user } = useUser();

  function uploadResult() {
    //console.log(fleetId, carId);
    if (newResultName === "" || file === undefined || file === "") {
      //登録NG
      setIsShowMessage(true);
      setModalTitleConfirm("Error");
      setModalMessageConfirm(`You must input "Name" and "KML File" of result.`);
    } else {
      setDuringRegisterResult(true);
      const resultName: string = newResultName;
      const comment: string = resultComment;

      const requestParams =
        comment === ""
          ? { fleetId, carId, resultName, file }
          : { fleetId, carId, resultName, file, comment };

      kiwiApi(user.token)
        .registerResult(requestParams)
        .then((result) => {
          //console.log(result);
          if (
            result.path_geojson !== undefined &&
            result.path_geojson.geometry !== undefined &&
            result.path_geojson.geometry.coordinates !== undefined
          ) {
            //pathから(0,0)を除外する処理
            const _removeZero = [...result.path_geojson.geometry.coordinates];
            //console.log("pre", _removeZero.length);
            const removeZero = _removeZero.filter((coord) => {
              return !(coord[0] === 0 && coord[1] === 0);
            });
            result.path_geojson.geometry.coordinates = removeZero;
          }

          // resultをRouteResultsに入れる
          const newResults = [...resultRoutes];
          //result_idの型がObjになっていて以下回避している
          const _newResult: any = { ...result };
          delete _newResult.result_id;
          const result_id = result.result_id;

          const newResult = { ..._newResult, result_id };
          //console.log(result);
          addGeoJson({ map, addData: result });
          newResults.push(newResult);
          //(0,0)をスキップしたデータがsetされている
          setResultRoutes(newResults);

          setDuringRegisterResult(false);
          //MSGを出す
          setIsShowMessage(true);
          setModalTitleConfirm("SUCCESS! register Result");
          setModalMessageConfirm(
            "The result has been regietered successfully!"
          );

          //名前とコメントをリセット
          setNewResultName("");
          setResultComment("");
          //このmodalを閉じる
          closeModal();
        })
        .catch((error) => {
          //console.log(error);
          setDuringRegisterResult(false);
          //MSGを出す
          setIsShowMessage(true);
          setModalTitleConfirm("ERROR! register Result");
          setModalMessageConfirm(error.message);
        });
    }
  }
  //type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
  function changeFileInput(e: any) {
    const file = e.target.files[0];
    //console.log(file);
    setFile(file);
    if (newResultName === "") {
      //ユーザーがファイル名を入力していなければ
      //ファイル名から自動インプットする
      const filename = file.name.split(".")[0];
      setNewResultName(filename);
    }
  }

  return (
    <Modal
      show={show}
      onHide={closeModal}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="titlecustom">
          Register Result Route
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
        <Form.Group>
          <h5>File:</h5>
          <Form.File onChange={(e: any) => changeFileInput(e)} />
          <h5>Fleet:</h5>
          <Form.Control type="text" value={fleetName} disabled />
          <h5>Car:</h5>
          <Form.Control type="text" value={carName} disabled />
          <h5>Name:</h5>
          <Form.Control
            type="text"
            maxLength={256}
            value={newResultName}
            onChange={(e: ChangeEvent) => onChangeResultName(e)}
          />
          <Form.Text className="text-muted">
            *limits to 250 characters
          </Form.Text>
          <h5>Comment:</h5>
          <Form.Control
            type="text"
            maxLength={1000}
            onChange={(e: ChangeEvent) => onChangeResultComment(e)}
          />
          <Form.Text className="text-muted">
            *limits to 1000 characters
          </Form.Text>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
        <Button variant="red" onClick={uploadResult}>
          Register Result
          {duringRegisterResult ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Button>
        <Button onClick={closeModal} variant="grey">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface ModalbtnUploadResultProps {
  fleetId: string;
  carId: string;
  fleetName: string;
  carName: string;
  setPlans: () => void;
  setConfirmModalShow: () => void;
  setConfirmTitle: () => void;
  setConfirmMsg: () => void;
  resultRoutes: Result[];
  setResultRoutes: (results: Result[]) => void;
  setIsShowMessage: (isShowMessage: boolean) => void;
  setModalMessageConfirm: (modalMessageConfirm: string) => void;
  setModalTitleConfirm: (modalTitleConfirm: string) => void;
  map: any;
}

function ModalbtnUploadResult({
  fleetId,
  carId,
  fleetName,
  carName,
  resultRoutes,
  setResultRoutes,
  setIsShowMessage,
  setModalTitleConfirm,
  setModalMessageConfirm,
  map,
}: ModalbtnUploadResultProps) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <style type="text/css">
        {`
    .btn-red {
      background-color: #A10202;
      color: white;
    }
    `}
      </style>

      <Button
        size="sm"
        variant="grey"
        onClick={() => setModalShow(true)}
        style={{ margin: "0 auto", padding: 0, width: "100%" }}
        title="Register result with kml file"
      >
        <img src={Plus} alt="add result" width="20" height="20" />
      </Button>

      <ModalUploadRoute
        show={modalShow}
        onHide={() => setModalShow(false)}
        fleetId={fleetId}
        carId={carId}
        fleetName={fleetName}
        carName={carName}
        resultRoutes={resultRoutes}
        setResultRoutes={setResultRoutes}
        setIsShowMessage={setIsShowMessage}
        setModalTitleConfirm={setModalTitleConfirm}
        setModalMessageConfirm={setModalMessageConfirm}
        map={map}
      />
    </>
  );
}

export { ModalbtnUploadResult };
