import React, { useEffect, useState } from "react";
import { ModalMessage } from "./ModalMessage";
import { OptionPane } from "./OptionPane/OptionPane";
import { PlanRoutesPane } from "./PlanRoutesPane";
import { ResultRoutesPane } from "./ResultRoutesPane";
export function ResultsNaviPane({
  fleetId,
  carId,
  fleetName,
  carName,
  googleMap,
  map,
  setGetPlanStatus,
  selectedRoute,
  setSelectedRoute,
  selectedRouteResult,
  setSelectedRouteResult,
  planRoutes,
  resultRoutes,
  setResultRoutes,
  infoWindowsFileImport,
  setInfoWindowsFileImport,
}) {
  useEffect(() => {
    if (map === null || selectedRoute === undefined) {
      return;
    }
    //console.log(selectedRoute);
  }, [googleMap, map, selectedRoute]);

  //ModalMessageの中身＆状態管理　--処理後のmessage表示
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [modalTitleConfirm, setModalTitleConfirm] = useState("");
  const [modalMessageConfirm, setModalMessageConfirm] = useState("");

  return (
    <div style={{ height: "100%" }}>
      {/* <MileagesPane planRoutes={planRoutes} resultRoutes={resultRoutes} /> */}
      <PlanRoutesPane
        planRoutes={planRoutes}
        selectedRoute={selectedRoute}
        setSelectedRoute={setSelectedRoute}
        map={map}
        fleetName={fleetName}
        carName={carName}
      />
      <ResultRoutesPane
        resultRoutes={resultRoutes}
        setResultRoutes={setResultRoutes}
        selectedRoute={selectedRouteResult}
        setSelectedRoute={setSelectedRouteResult}
        map={map}
        fleetId={fleetId}
        carId={carId}
        fleetName={fleetName}
        carName={carName}
        setIsShowMessage={setIsShowMessage}
        setModalMessageConfirm={setModalMessageConfirm}
        setModalTitleConfirm={setModalTitleConfirm}
      />
      <OptionPane
        map={map}
        googleMap={googleMap}
        fleetId={fleetId}
        infoWindowsFileImport={infoWindowsFileImport}
        setInfoWindowsFileImport={setInfoWindowsFileImport}
      />
      <ModalMessage
        modalTitle={modalTitleConfirm}
        message={modalMessageConfirm}
        showModal={isShowMessage}
        closeModal={() => setIsShowMessage(false)}
      />
    </div>
  );
}
