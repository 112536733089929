import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { nextPointId } from "../../hooks/useCurrentPlan";
import { useUser } from "../../hooks/useUser";
import { maximumDistanceValueOfSaved } from "../../LimitsValue";
import { getPlan } from "./getPlan";
import { kiwiApi } from "./kiwiApi";
import { ModalConfirm } from "./ModalConfirm";
import { ModalAlertChangePlan } from "./plan/ModalAlertChangePlan";
import { ModalAlertDeletePlan } from "./plan/ModalAlertDeletePlan";
import { ModalbtnCopyPlan } from "./plan/ModalbtnCopyPlan";
import { ModalbtnCreateNewPlan } from "./plan/ModalbtnCreateNewPlan";
import { ModalbtnDiscardEditPlan } from "./plan/ModalbtnDiscardEditPlan";
import { sec2hhmm } from "./plan/SearchDirections";

/**
 * ルートの情報表示、保存、削除をするコンポーネント.
 * ルートの距離制限アラートも出す.
 * modeがfieldTestの場合、Testモード画面用に切り替わる.
 *
 */
export function PlanDetail({
  fleetId,
  vecId,
  plans,
  setPlans,
  route,
  setRoute,
  setName,
  setRouteComment,
  map,
  setGetPlanStatus,
  mode,
  googleMap,
}) {
  const refSelectFleets = useRef(null);
  const plannameRef = useRef(null);
  const planCommnetRef = useRef(null);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmMsg, setConfirmMsg] = useState(null);
  const [saveSpinStatus, setSaveSpinStatus] = useState(false);
  const [deleteSpinStatus, setDeleteSpinStatus] = useState(false);
  //redux導入時にbooleanから複数状態管理（ロード中、成功、失敗etc）に変更する

  const { user } = useUser();

  useEffect(() => {
    if (route === null || route === undefined) {
      return;
    }
    refSelectFleets.current.value = route.plan_id;
    plannameRef.current.value = route.plan_name;
    planCommnetRef.current.value = route.comment ? route.comment : "";

    // pathの更新
    if (map === null) {
      return;
    }
    //PathGeoJson({ route, map });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, map]);

  function notChangePlan() {
    setAlertModalShow(false);
    refSelectFleets.current.value = route.plan_id;
    //console.debug("Route Selector", route);
  }
  function changePlan() {
    setAlertModalShow(false);
    //console.log("ルート選択");

    // const routeId = refSelectFleets.current.value;
    const planId = refSelectFleets.current.value;
    getPlan({ planId, user, nextPointId, setRoute, setGetPlanStatus, map });
  }
  function changeName() {
    setName(plannameRef.current.value);
  }
  function changeComment() {
    setRouteComment(planCommnetRef.current.value);
  }
  function savePlan() {
    console.debug("save plan", route);
    //console.log("save plan", route)
    if (route.distance > maximumDistanceValueOfSaved) {
      // console.log("500km以上です");
      setConfirmModalShow(true);
      setConfirmTitle(`ALERT!  over ${maximumDistanceValueOfSaved / 1000} km`);
      setConfirmMsg(
        `The route over ${
          maximumDistanceValueOfSaved / 1000
        } km can't be saved.Please reduce the distance.`
      );
    } else {
      //routeを保存する
      const planId = route.plan_id;

      setConfirmTitle("SAVE ROUTE");
      setSaveSpinStatus(true);

      kiwiApi(user.token)
        .updatePlan({ planId, updatePlan: route })
        .then((response) => {
          console.debug("updateRoute", response);
          setConfirmModalShow(true);
          setConfirmTitle("SAVE ROUTE");
          setConfirmMsg("The route has been saved successfully!");

          //表示するrouteを変更
          const index = plans.findIndex((route) => route.plan_id === planId);
          plans.splice(index, 1, route);
          setPlans(plans);
        })
        .catch((error) => {
          console.debug("error updateRoute", error);
          setConfirmModalShow(true);
          setConfirmTitle("Failed SAVE ROUTE");
          setConfirmMsg(error.message);
        });
    }
  }
  function cancelDeletePlan() {
    setDeleteModalShow(false);
  }
  function deletePlan() {
    console.debug("delete plan", route.plan_id);
    //route idでdeleteする

    const planId = route.plan_id;

    setDeleteSpinStatus(true);
    setConfirmMsg(null);

    kiwiApi(user.token)
      .deletePlan({ planId })
      .then((response) => {
        console.debug("success deleteRoute", response);
        setDeleteModalShow(false);
        setConfirmModalShow(true);
        setConfirmTitle("SUCCESS! delete route");

        const after = plans.filter((plan) => {
          return plan.plan_id !== planId;
        });
        //console.log(after.length);
        setPlans(after);
        //一番最後のrouteをsetRouteする

        setConfirmMsg("The route has been deleted successfully!!");
      })
      .catch((error) => {
        console.debug("Error deleteRoute", error);
        setDeleteModalShow(false);
        setConfirmModalShow(true);
        setConfirmTitle("ERROR! delete route");
        setConfirmMsg(error.message);
      });
  }
  function resetPlan() {
    // console.log("ルートリセット");
    //originalルートを取得してsetRouteにする

    const planId = route.plan_id;
    getPlan({
      planId,
      user,
      nextPointId,
      setRoute,
      setGetPlanStatus,
      map,
    });
  }
  function closeConfirmModal() {
    setConfirmModalShow(false);
    setConfirmTitle(null);
    setConfirmMsg(null);
    setSaveSpinStatus(false);
    setDeleteSpinStatus(false);
  }
  if (plans === null) {
  }
  plans.sort(function (a, b) {return a.plan_name.localeCompare(b.plan_name);})

  return (
    <>
      <Card>
        {/* <Card.Header
          className="titlecustom"
          style={{ color: "#585858", fontSize: "18px" }}
        > */}
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          className="titlecustom"
          style={{ color: "#585858", fontSize: "18px" }}
        >
          Route
        </Accordion.Toggle>
        {/* </Card.Header> */}
        <Accordion.Collapse eventKey="0">
          <Card.Body id="route_part">
            <Form>
              <Form.Group controlId="Form.RouteSelector">
                {route === undefined ? null : (
                  <Form.Control
                    as="select"
                    onChange={() => setAlertModalShow(true)}
                    ref={refSelectFleets}
                  >
                    {plans.map((plan, key) => (
                      <option value={plan.plan_id} key={key}>
                        {plan.plan_name}
                      </option>
                    ))}
                  </Form.Control>
                )}
                {mode === "fieldTest" ? null : (
                  <ModalbtnCreateNewPlan
                    fleetId={fleetId}
                    vecId={vecId}
                    setConfirmModalShow={setConfirmModalShow}
                    plans={plans}
                    setPlans={setPlans}
                    setConfirmTitle={setConfirmTitle}
                    setConfirmMsg={setConfirmMsg}
                  />
                )}
                {mode === "fieldTest" || route === undefined ? null : (
                  <ModalbtnCopyPlan
                    copiedPlanId={route.plan_id}
                    basePlanName={route.plan_name}
                    plans={plans}
                    setPlans={setPlans}
                    setConfirmModalShow={setConfirmModalShow}
                    setConfirmTitle={setConfirmTitle}
                    setConfirmMsg={setConfirmMsg}
                  />
                )}
                {mode === "fieldTest" || route === undefined ? null : (
                  <ModalbtnDiscardEditPlan resetPlan={resetPlan} />
                )}
              </Form.Group>
              {route === undefined ? null : (
                <>
                  <Form.Group controlId="Form.RouteNameTextarea">
                    <Form.Text>Route</Form.Text>
                    <Form.Control
                      as="textarea"
                      ref={plannameRef}
                      onChange={changeName}
                      disabled={mode === "fieldTest"}
                    />
                    {mode === "fieldTest" ? null : (
                      <Form.Text className="text-muted">
                        *limits to 250 characters
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group controlId="Form.RouteDistance">
                    <Form.Text>distance</Form.Text>
                    <Col>
                      <span
                        style={{
                          color:
                            route.distance > maximumDistanceValueOfSaved
                              ? "red"
                              : "black",
                        }}
                      >
                        {route.distance ? route.distance / 1000 : "---"}
                      </span>{" "}
                      km
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="Form.RouteTime">
                    <Form.Text>time</Form.Text>
                    <Col>
                      <span>
                        {route.time ? sec2hhmm(route.time) : "--- min"}
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="Form.RouteCommentTextarea">
                    <Form.Text>Comment</Form.Text>
                    <Form.Control
                      as="textarea"
                      ref={planCommnetRef}
                      onChange={changeComment}
                      maxLength="1000"
                      disabled={mode === "fieldTest"}
                    />
                    {mode === "fieldTest" ? null : (
                      <Form.Text className="text-muted">
                        *limits to 1000 characters
                      </Form.Text>
                    )}
                  </Form.Group>
                  {mode === "fieldTest" ? null : (
                    <Button
                      size="sm"
                      variant="red"
                      onClick={savePlan}
                      style={{ width: "100%", margin: "5px" }}
                    >
                      Save
                      {saveSpinStatus ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                    </Button>
                  )}{" "}
                  {mode === "fieldTest" ? null : (
                    <Button
                      size="sm"
                      variant="grey"
                      onClick={() => setDeleteModalShow(true)}
                      style={{ width: "100%", margin: "5px" }}
                    >
                      Delete
                      {deleteSpinStatus ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                    </Button>
                  )}
                </>
              )}
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <ModalAlertChangePlan
        alertModalShow={alertModalShow}
        notChangePlan={notChangePlan}
        changePlan={changePlan}
      />
      <ModalAlertDeletePlan
        deleteModalShow={deleteModalShow}
        cancelDeletePlan={cancelDeletePlan}
        deletePlan={deletePlan}
        deleteSpinStatus={deleteSpinStatus}
      />
      <ModalConfirm
        modalTitle={confirmTitle}
        showModal={confirmModalShow}
        closeModal={closeConfirmModal}
        message={confirmMsg}
      />
    </>
  );
}
