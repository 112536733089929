/**
 * 読み込んだcsv/txtファイルをGeoJson のpoint collectionへ変換する
 * @param {string} fileName
 * @param {string} fileReadResult
 */
export function createPointGeoJson(fileName, fileReadResult) {
  const cases = parseCases(fileName, fileReadResult);
  // console.log(cases);

  const features = cases.map(function (c) {
    return new GeoJsonFeature(c);
  });

  const pointsGeoJson = new GeoJsonFeatureCollection(features);
  // console.log(pointsGeoJson);
  return pointsGeoJson;
}

/**
 * geoJson feature collectionのクラス
 * @param {object} features
 */
function GeoJsonFeatureCollection(features) {
  this.type = "FeatureCollection";
  this.features = features;
}

/**
 * pointのgeoJson featureのクラス
 * @param {string} props.category
 * @param {string} props.carNo
 * @param {string} props.datetime
 * @param {string} props.caseNo
 * @param {object} props.latlng
 * @param {string} props.fileName
 */
function GeoJsonFeature({
  category,
  carNo,
  datetime,
  caseNo,
  latLng,
  fileName,
  type,
  comment,
}) {
  this.type = "Feature";
  this.properties = {
    category,
    carNo,
    datetime,
    caseNo,
    fileName,
    type,
    comment,
  };
  this.geometry = { type: "Point", coordinates: [latLng.lng, latLng.lat] };
}

/**
 * ExportCaseのクラス関数
 * @param {string} category
 * @param {string} carNo
 * @param {string} datetime
 * @param {string} caseNo
 * @param {object} latLng
 * @param {string} fileName
 */
function Case({
  category,
  carNo,
  datetime,
  caseNo,
  latLng,
  fileName,
  type,
  comment,
}) {
  this.category = category;
  this.carNo = carNo;
  this.datetime = datetime;
  this.caseNo = caseNo;
  this.latLng = latLng;
  this.fileName = fileName;
  this.type = type;
  this.comment = comment;
}

/**
 * 読み込んだcsvのtextデータからcasesを生成
 * @param {string} fileName
 * @param {string} text
 */
function parseCases(fileName, text) {
  const lines = text.split("\n");

  const columnNames = lines.shift().replace(/\s+/g, "").split(",");
  //最後のカラム名に半角スペースが入るため置換する
  //console.log(columnNames[7].replace(/\s+/g, ""));

  const categoryIndex = columnNames.indexOf("category");
  const carNoIndex = columnNames.indexOf("VEC");
  const datetimeIndex = columnNames.indexOf("ExportCaseStarttime");
  const caseNoIndex = columnNames.indexOf("ExportNo");
  const latIndex = columnNames.indexOf("lat_gps");
  const lngIndex = columnNames.indexOf("lon_gps");
  const commentIndex = columnNames.indexOf("comment");
  const type = "csvPoint";

  const cases = lines
    .filter(function (l) {
      return l.split(",").length > 1;
    })
    .map(function (l) {
      //const tokens = l.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/);
      const tokens = l.split(",");
      
      const category = tokens[categoryIndex];
      const carNo = tokens[carNoIndex];
      const datetime = tokens[datetimeIndex];
      const caseNo = tokens[caseNoIndex];
      const latLng = {
        lat: parseFloat(tokens[latIndex]),
        lng: parseFloat(tokens[lngIndex]),
      };
      const comment =
        commentIndex === -1 ? "" : tokens[commentIndex].replace(/\s+/g, "");
      return new Case({
        category,
        carNo,
        datetime,
        caseNo,
        latLng,
        fileName,
        type,
        comment,
      });
    });

  return cases;
}
