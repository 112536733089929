import { kiwiApi } from "./kiwiApi";

/**
 * ルートを取得してsetRouteする
 * @param {string} planId
 * @param {Object} user
 *
 */
export async function getPlan({
  planId,
  user,
  nextPointId,
  setRoute,
  setGetPlanStatus,
  map,
}) {
  try {
    setGetPlanStatus(true);
    const selectRoute = await kiwiApi(user.token).getPlan({
      planId,
    });
    selectRoute.points = selectRoute.points?.map((point, key) => {
      point.id = nextPointId();
      return point;
    });
    setRoute(selectRoute);
    //console.log('selectroute',selectRoute)
    //地図を変更
    const { lat, lng } = selectRoute.points[0];
    map.panTo({ lat, lng });

    setGetPlanStatus(false);
  } catch (error) {
    console.debug("getPlan", error);
    setGetPlanStatus(false);
  }
}
