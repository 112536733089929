/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateResult
 */
export interface UpdateResult {
    /**
     * 
     * @type {string}
     * @memberof UpdateResult
     */
    result_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResult
     */
    comment?: string;
}

export function UpdateResultFromJSON(json: any): UpdateResult {
    return UpdateResultFromJSONTyped(json, false);
}

export function UpdateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result_name': !exists(json, 'result_name') ? undefined : json['result_name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function UpdateResultToJSON(value?: UpdateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result_name': value.result_name,
        'comment': value.comment,
    };
}


