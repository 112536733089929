import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Row, Col, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import Loupe from "../../image/loupe.png";
import { BreadcrumbBar } from "../BreadcrumbBar";
import { kiwiApiProjects } from "../../api/kiwiApi/kiwiApis";

export function FleetsSelect() {
  const { user } = useUser();
  const [fleetLoadStatus, setFleetLoadStatus] = useState(true)
  const [fixedFleetDatas, setFixedFleetDatas] = useState([]);
  const [loadingComment, setLoadingComment] = useState("Loading... data...")
  const [fleets, setFleets] = useState([]);
  const InputProjectCode = useRef(null);
  const selectedFleet = useRef(null);
  const history = useHistory();
  const params = useParams();

  useEffect(()=>{
    (async()=>{
      try{
        // console.log(user)
        // console.log("this",user.token)
        const rawList = await kiwiApiProjects(user.token)
        // console.log(rawList)
        if(rawList == []){
          setFixedFleetDatas([])
          setFleetLoadStatus(true)
          setLoadingComment("There is no data or Fail to load data...")
          return
        }
        const metaDataFixedList =rawList.map((item) => {
          return {
            code : item.project_name,
            dest : item.destination,
            model_year : item.model_year,
            fleet : item.fleet,
            id : item.project_id.toString(),
            lot : item.lot,
          }      
        })
        const fixedList = metaDataFixedList.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        setFixedFleetDatas(fixedList)
        setFleets(fixedList)
        setFleetLoadStatus(false)
      }catch(e){
        console.log(e)
        setLoadingComment("Can't read data!")
      }
    })();
  },[user]);

  function SearchFleetsbyProjCode() {
    //console.log("↑inputeされた値で一覧をGETする");
    if (InputProjectCode.current.value === "") {
      setFleets(fixedFleetDatas);
    } else {
      //GET fleets
      const fleetsOfThisProject = fixedFleetDatas.filter((fleet) =>
        fleet.code.toLowerCase().includes(InputProjectCode.current.value.toLowerCase())
      );
      setFleets(fleetsOfThisProject);
    }
  }

  function SelectFleet(fleet) {
    const fleet_id = fleet.id;

    fleets.forEach((fleet) => {
      if (fleet.id === fleet_id) {
        const fleetName = `${fleet.code}-${fleet.lot}-${fleet.dest}-${fleet.fleet}`;
        history.push(`/${fleetName}/${fleet_id}/Vecs`);
      }
    });
  }

  return (
    <>
      <BreadcrumbBar params={params} />
      <Container>
        <Row
          className="titlecustom"
          style={{ fontSize: "30px", color: "#585858" }}
        >
          PROJECT
        </Row>
        <Row>
          <Form inline>
            <Form.Control
              type="text"
              placeholder="Project code"
              id="fleetSearch"
              ref={InputProjectCode}
            />
            <Button
              onClick={SearchFleetsbyProjCode}
              variant="red"
              style={{ margin: "5px" }}
            >
              <img src={Loupe} width="20" height="20" alt="loupe" />
            </Button>
          </Form>
        </Row>
        {fleetLoadStatus? (
            <Row>
              {loadingComment}
            </Row>
          ):(
            <Row>
              <Table
                bordered
                hover
                style={{ margin: "5px", fontSize: "15px", textAlign: "center" }}
              >
              <thead style={{ backgroundColor: "#000000", color: "#FFFFFF" }}>
                <tr>
                  <th>id</th>
                  <th>Proj</th>
                  <th>MY</th>
                  <th>Lot</th>
                  <th>Dest</th>
                  <th>Fleets</th>
                </tr>
              </thead>
              <tbody ref={selectedFleet}>
                {fleets.map((fleet, key) => (
                  <tr key={key} id={fleet.id} onClick={() => SelectFleet(fleet)}>
                    <td>{fleet.id}</td>
                    <td>{fleet.code}</td>
                    <td>{fleet.model_year}</td>
                    <td>{fleet.lot}</td>
                    <td>{fleet.dest}</td>
                    <td>{fleet.fleet}</td>
                  </tr>
                ))}
              </tbody>
              </Table>
            </Row>
        )}
      </Container>
    </>
  );
}
