import React from "react";
import { Button, Modal } from "react-bootstrap";

export function ModalAlertChangePlan({
  alertModalShow,
  notChangePlan,
  changePlan,
}) {
  return (
    <>
      <Modal
        show={alertModalShow}
        onHide={notChangePlan}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
          <Modal.Title className="titlecustom">
            Comfirm change route
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
          <p>The route have not been saved yet. </p>
          <p>Are you sure you want to change route?</p>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
          <Button variant="red" onClick={changePlan}>
            Change route
          </Button>
          <Button variant="grey" onClick={notChangePlan}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
