/**
 * path情報表示するInfoWindowを生成する
 * @param {google} googleMap
 * @param {(e: google.maps.Data.MouseEvent) => void} event
 */

export function pathInfoWindow(googleMap, event) {
  //console.log(event.feature);
  const hoverPathNo = event.feature.getProperty("pathNo");
  const hoverPathStartName = event.feature.getProperty("startPlace");
  const hoverPathEndName = event.feature.getProperty("endPlace");
  const hoverPathMemo = event.feature.getProperty("memo");

  const content = `<div>
  <p style="font-weight: bold;">${hoverPathNo} : ${hoverPathStartName}～${hoverPathEndName}</p>
  <p style="font-size: 1px;"}>※${hoverPathMemo}</p>
  </div>`;

  const infowindow = new googleMap.maps.InfoWindow({
    content: content,
    position: event.latLng,
  });
  return infowindow;
}
