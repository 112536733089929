import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { DiscardIcon } from "../icons";

function ModalDiscardEditPlan({ resetPlan, show, onHide }) {
  function descardEditRoute() {
    resetPlan();
    onHide();
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="titlecustom">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Reset Route?</div>
        <div>If you have edited this route, these will be discarded.</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="red" onClick={descardEditRoute}>
          Reset
        </Button>
        <Button onClick={onHide} variant="grey">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalbtnDiscardEditPlan({ resetPlan }) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        size="sm"
        variant="grey"
        onClick={() => setModalShow(true)}
        style={{ margin: "5px" }}
        title="reset this plan route"
      >
        {/* Discard */}
        <DiscardIcon />
      </Button>

      <ModalDiscardEditPlan
        resetPlan={resetPlan}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
