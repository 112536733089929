import Amplify, { Auth } from "aws-amplify";
import awsConf from "../../awsConf";

Amplify.configure(awsConf);

export type UserName = string;
export type AuthStatus =
  | "idle"
  | "signinning"
  | "signin_succeed"
  | "signin_failure"
  | "signouting"
  | "signout_succeed"
  | "signout_failure"
  | "retrieving"
  | "retrieve_succeed"
  | "retrieve_failure";

export interface User {
  name: UserName;
  token: string;
  // payload: object;
  groups: Array<string>;
}

export interface UserState extends User {
  status: AuthStatus;
  error: string | null;
}

type RequestType = "signin" | "signout" | "retrieve";
const actions = {
  resetStatus: () => ({ type: "user/StatusReset" } as const),
  authRequest: (reqType: RequestType) => ({
    type: "user/AuthRequest" as const,
    payload: reqType,
  }),
  authSucceed: (reqType: RequestType, user: User) => ({
    type: "user/AuthSucceed" as const,
    payload: { reqType, user },
  }),
  authFailure: (reqType: RequestType, error: Error) => ({
    type: "user/AuthFailure" as const,
    payload: { reqType, error },
  }),
} as const;

export type AuthRequest = ReturnType<typeof actions.authRequest>;
export type AuthSucceed = ReturnType<typeof actions.authSucceed>;
export type AuthFailure = ReturnType<typeof actions.authFailure>;
export type StatusReset = ReturnType<typeof actions.resetStatus>;

export type UserAction = ReturnType<typeof actions[keyof typeof actions]>;

export async function signIn(
  userData: any,
  dispatch: UserDispatch
) {
  dispatch(actions.authRequest("signin"));
  try {
    let cognitoUser = userData
    //console.log(cognitoUser);
    dispatch(
      actions.authSucceed("signin", {
        name: cognitoUser.signInUserSession.idToken.payload["email"],
        token: cognitoUser.signInUserSession.idToken.jwtToken,
        // payload: cognitoUser.signInUserSession.idToken.payload,
        groups: cognitoUser.signInUserSession.idToken.payload["cognito:groups"]
          ? cognitoUser.signInUserSession.idToken.payload["cognito:groups"]
          : [],
      })
    );

    return true;
  } catch (error) {
    console.log(error);
    dispatch(
      actions.authFailure(
        "signin",
        new Error(
          "Your authentication information is incorrect. Please try again."
        )
      )
    );
    return false;
  }
}

export const { resetStatus } = actions;

export async function signOut(dispatch: UserDispatch) {
  dispatch(actions.authRequest("signout"));
  try {
    await Auth.signOut();

    dispatch(
      actions.authSucceed("signout", {
        name: initialState.name,
        token: initialState.token,
        // payload: initialState.payload,
        groups: initialState.groups,
      })
    );
    return true;
  } catch (error) {
    dispatch(actions.authFailure("signout", error as any));
    return false;
  }
}

export async function retrieveCurrentSession(dispatch: UserDispatch) {
  dispatch(actions.authRequest("retrieve"));
  try {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

    dispatch(
      actions.authSucceed("retrieve", {
        name: cognitoUser.attributes.email,
        token: cognitoUser.signInUserSession.idToken.jwtToken,
        // payload: cognitoUser.signInUserSession.idToken.payload,
        groups: cognitoUser.signInUserSession.idToken.payload["cognito:groups"]
          ? cognitoUser.signInUserSession.idToken.payload["cognito:groups"]
          : [],
      })
    );
  } catch (error) {
    dispatch(actions.authFailure("retrieve", error as any));
  }
}

export const initialState: UserState = {
  name: "",
  token: "",
  status: "idle",
  error: null,
  // payload: {},
  groups: [],
};

export function reducer(
  state: UserState | undefined = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case "user/AuthRequest": {
      const status: Record<RequestType, AuthStatus> = {
        signin: "signinning",
        signout: "signouting",
        retrieve: "retrieving",
      };
      return { ...state, status: status[action.payload] };
    }
    case "user/AuthSucceed": {
      const status: Record<RequestType, AuthStatus> = {
        signin: "signin_succeed",
        signout: "signout_succeed",
        retrieve: "retrieve_succeed",
      };
      return {
        ...state,
        ...action.payload.user,
        status: status[action.payload.reqType],
      };
    }
    case "user/AuthFailure": {
      const statusmap: Record<RequestType, AuthStatus> = {
        signin: "signin_failure",
        signout: "signout_failure",
        retrieve: "retrieve_failure",
      };
      return {
        ...initialState,
        status: statusmap[action.payload.reqType],
        error: action.payload.error.message,
      };
    }
    case "user/StatusReset":
      return {
        ...state,
        status: initialState.status,
        error: initialState.error,
      };
  }
}

export type UserDispatch = React.Dispatch<UserAction>;

// Auth.signIn関数の返り値のオブジェクトがCognitoUserとは異なるため、
// console.logの出力結果を元にアプリで必要となる属性のみ定義
interface CognitoUser {
  attributes: {
    email: string;
    email_verified: boolean;
    sub: string;
  };
  signInUserSession: {
    accessToken: {
      jwtToken: string;
    };
    idToken: {
      jwtToken: string;
      payload: any;
    };
    refreshToken: {
      jwtToken: string;
    };
  };
  challengeName?: string;
}
