import { useEffect, useState } from "react";
import GoogleMapsApiLoader from "google-maps-api-loader";

// Google Mapのオブジェクトを呼び出すだけのhooks
/**
 * @param {string} apiKey
 * @returns {google} //jsdocで定義の仕方を見ておくといいかも
 */

export function useGoogleMap(apiKey) {
  const [googleMap, setGoogleMap] = useState(null);
  useEffect(() => {
    GoogleMapsApiLoader({ libraries: ["places"], apiKey }).then((google) => {
      setGoogleMap(google);
    });
  }, [apiKey]);
  // useEffectの第二引数を[]にすることで、初回1回目だけ実行される
  //console.log(googleMap);

  return googleMap;
}
