import geojson from "geojson";

/**
 * ルート検索時のrequestを生成する
 * @param {object} props
 * @param {object} props.orgpos
 * @param {object} props.destpos
 * @param {Array} props.waypos
 * @param {boolean} props.optimizeWaypoints
 * @param {google} props.googleMap
 */
export function createSearchRouteRequest({
  orgpos,
  destpos,
  waypos,
  googleMap,
  optimizeWaypoints,
  avoidHighways,
}) {
  const origin = new googleMap.maps.LatLng(orgpos.lat, orgpos.lng);
  const destination = new googleMap.maps.LatLng(destpos.lat, destpos.lng);
  const waypoints = [];
  waypos.forEach((w) => {
    const location = new googleMap.maps.LatLng(w.lat, w.lng);
    const waylocation = { location };
    waypoints.push(waylocation);
  });

  var request = {
    origin, // 出発地
    destination, // 目的地
    waypoints, //経由地
    travelMode: googleMap.maps.DirectionsTravelMode.DRIVING,
    optimizeWaypoints, //ここをtrueにすると中継点自動並べ替えON
    avoidHighways,
  };
  return request;
}

//中継点が25個以上ある時のポイント分けロジック
export function separateRoutePoints(reorderpoints) {
  const numberOfLimits = 27;

  //console.log(reorderpoints.slice(0, 27));
  //console.log(reorderpoints.slice(26));

  const requestPointsGroup = [];

  //waypointが25個以内となるように分ける and パスがつながるようにDestとOriginを重複させる
  for (let i = 0; i <= (reorderpoints.length - 1) / (numberOfLimits - 1); i++) {
    //console.log(i);
    //console.log("start index", i * 26);
    //console.log("end index", i * 26 + 26);
    // console.log("points", reorderpoints.slice(i * 26, i * 26 + 26 + 1));
    //1～27個目 / 27～53個目　/　53～80個目　・・・・・と分けたい。
    const pointsGroup = reorderpoints.slice(i * 26, i * 26 + 26 + 1);
    requestPointsGroup.push(pointsGroup);
  }
  return requestPointsGroup;
}

// requestを代入してルート検索結果を返すpromise関数
export function DirectionsServiceGoogle(googleMap, request) {
  return new Promise((resolve) => {
    const d = new googleMap.maps.DirectionsService();
    // const r = [];
    d.route(request, function (result, status) {
      if (status === googleMap.maps.DirectionsStatus.OK) {
        resolve(result);
      } else {
        resolve(result);
      }
    });
  });
}

//ルート検索結果の処理関数

/**
 * ルートの総距離、所要時間を算出する
 * @param {googleMapDirectionResults} result
 */
export function returnTotalDistanceTime(result) {
  let route_legs = result.routes[0].legs;
  //console.log(route_legs)
  let time_sec = 0;
  let distance_m = 0;
  for (let i = 0; i < route_legs.length; i++) {
    time_sec = time_sec + route_legs[i].duration.value;
    distance_m = distance_m + route_legs[i].distance.value;
  }

  const cal_res = { time_sec, distance_m };
  return cal_res;
}
/**
 * ルート結果からgeoJsonを生成する
 * @param {googleMapDirectionResults} result
 */
export function createRouteGeoJsonbyRoutePath(result) {
  let route_legs = result.routes[0].legs;

  let pointsOfRoutePath = [];
  for (let i = 0; i < route_legs.length; i++) {
    // console.debug("routePath", route_legs[i].steps);
    const thisLegSteps = route_legs[i].steps;

    const pointsOfThisLeg = createPointsOfLeg(thisLegSteps);
    pointsOfRoutePath = pointsOfRoutePath.concat(pointsOfThisLeg);
  }
  //console.debug("this Step Path points", pointsOfRoutePath);
  let geoJSON_route = geojson.parse(
    { line: pointsOfRoutePath },
    { LineString: "line" }
  );

  return geoJSON_route;
}

function createPointsOfLeg(stepsOfLeg) {
  let points = [];
  stepsOfLeg.forEach((step) => {
    step.path.forEach((latlng) => {
      const lat = latlng.lat();
      const lng = latlng.lng();

      points = [...points, [lng, lat]];
    });
  });
  return points;
}

/**
 * 秒を時分に変換する
 * @param {number} seconds
 */
export function sec2hhmm(seconds) {
  let ss = seconds % 60;
  let mm = Math.floor(seconds / 60) % 60;
  let hh = Math.floor(seconds / 3600);

  //時分まで、秒は四捨五入
  if (ss > 30) {
    mm = mm + 1;
  }

  return `${hh} hour ${mm} min`;
}

/**
 * 複数回に分けてルート検索リクエストを投げた時の結果をまとめる関数
 * 距離ver.
 * @param {Object} pickUpResults
 */
export function returnSumDistance(pickUpResults) {
  let d = 0;
  pickUpResults.forEach((r) => {
    d += r.result_time_distance.distance_m;
  });
  return d;
}
/**
 * 複数回に分けてルート検索リクエストを投げた時の結果をまとめる関数
 * 時間ver.
 * @param {Object} pickUpResults
 */
export function returnSumTime(pickUpResults) {
  let t = 0;
  pickUpResults.forEach((r) => {
    t += r.result_time_distance.time_sec;
  });
  return t;
}
/**
 * 複数回に分けてルート検索リクエストを投げた時の結果をまとめる関数
 * geoJson ver.
 * @param {Object} pickUpResults
 */
export function returnSumRouteGeoJson(pickUpResults) {
  let coordinates;
  let geometry;
  let geoJson;
  pickUpResults.forEach((r, index) => {
    // console.log(r.routeGeoJson.geometry.coordinates.length);
    if (index === 0) {
      coordinates = r.routeGeoJson.geometry.coordinates;
      geoJson = { ...r.routeGeoJson };
      geometry = { ...r.routeGeoJson.geometry };
    } else {
      coordinates = coordinates.concat(r.routeGeoJson.geometry.coordinates);
    }
  });
  geometry = { ...geometry, coordinates };
  return { ...geoJson, geometry };
}
