/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoiMetaDetail
 */
export interface PoiMetaDetail {
    /**
     * 
     * @type {string}
     * @memberof PoiMetaDetail
     */
    s3_filename: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMetaDetail
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMetaDetail
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMetaDetail
     */
    created_at: string;
    /**
     * 
     * @type {object}
     * @memberof PoiMetaDetail
     */
    poi_id: object;
    /**
     * 
     * @type {string}
     * @memberof PoiMetaDetail
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMetaDetail
     */
    created_by: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PoiMetaDetail
     */
    filtered_columns?: { [key: string]: object; };
}

export function PoiMetaDetailFromJSON(json: any): PoiMetaDetail {
    return PoiMetaDetailFromJSONTyped(json, false);
}

export function PoiMetaDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiMetaDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        's3_filename': json['s3_filename'],
        'updated_at': json['updated_at'],
        'updated_by': json['updated_by'],
        'created_at': json['created_at'],
        'poi_id': json['poi_id'],
        'title': json['title'],
        'created_by': json['created_by'],
        'filtered_columns': !exists(json, 'filtered_columns') ? undefined : json['filtered_columns'],
    };
}

export function PoiMetaDetailToJSON(value?: PoiMetaDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        's3_filename': value.s3_filename,
        'updated_at': value.updated_at,
        'updated_by': value.updated_by,
        'created_at': value.created_at,
        'poi_id': value.poi_id,
        'title': value.title,
        'created_by': value.created_by,
        'filtered_columns': value.filtered_columns,
    };
}


