import React from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { roundDistance } from "../roundDistance";

export const MileagePange = ({
  infoAllPlan,
  infoAllResult,
  selectVec,
  infoVecPlan,
  infoVecResult,
}) => {
  return (
    <>
      <Card style={{ width: "20vw" }}>
        <Card.Body style={{ padding: "10px" }}>
          <Card.Title className="titlecustom">Mileages</Card.Title>

          <Card.Subtitle className="titlecustom">
            <span style={{ padding: "0px 5px" }}>All Vecs</span>
            <UpdatedDateInfo
              message={
                <div style={{ textAlign: "left" }}>
                  <p>plan updated at</p>
                  <p> {infoAllPlan.lastModified}</p>
                  <p>result updated at</p>
                  <p> {infoAllResult.lastModified}</p>
                </div>
              }
            />
          </Card.Subtitle>
          <Card.Text>
            <MileageRow type="plan" distance={infoAllPlan.distance} />
            <MileageRow type="result" distance={infoAllResult.distance} />
          </Card.Text>
          <Card.Subtitle className="titlecustom">
            <span style={{ padding: "0px 5px" }}>
              {selectVec === null ? "------" : selectVec}
            </span>
            <UpdatedDateInfo
              message={
                <div style={{ textAlign: "left" }}>
                  <p>plan updated at</p>
                  <p>{infoVecPlan.lastModified}</p>
                  <p>result updated at</p>
                  <p> {infoVecResult.lastModified}</p>
                </div>
              }
            />
          </Card.Subtitle>
          <Card.Text>
            <MileageRow type="plan" distance={infoVecPlan.distance} />
            <MileageRow type="result" distance={infoVecResult.distance} />
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

//距離表示部分のコンポーネント
const MileageRow = ({ type, distance }) => {
  const fontColor = type === "plan" ? "#a10202" : "#0252A1";
  const title = type === "plan" ? "Plan" : "Result";
  return (
    <>
      <Row style={{ margin: "0px" }}>
        <Col
          sm={5}
          className="titlecustom"
          style={{ color: fontColor, padding: "0px", fontWeight: "bold" }}
        >
          {title}
        </Col>
        <Col sm={7}>
          <div style={{ textAlign: "right", fontSize: "15px" }}>
            {distance === undefined ? "---" : `${roundDistance(distance)}`}
            km
          </div>
        </Col>
      </Row>
    </>
  );
};

const UpdatedDateInfo = ({ message, placement = "bottom" }) => {
  return (
    <>
      <OverlayTrigger
        placement={placement}
        overlay={<Tooltip id={`tooltip-right`}>{message}</Tooltip>}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="Info"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M11.5 7h.5" />
          <path d="M10 11h2v5" />
          <path d="M10 16h4" />
        </svg>
      </OverlayTrigger>
    </>
  );
};
