import React from "react";
import { Toast } from "react-bootstrap";

export function NoticeZoomMax({ show, setShow }) {
  return (
    <Toast
      style={{ width: "300px", backgroundColor: "#fffaf0" }}
      onClose={() => setShow(false)}
      show={show}
      delay={3000}
      autohide
    >
      <Toast.Body
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          color: "#a10202",
        }}
      >
        Max zoom!
      </Toast.Body>
    </Toast>
  );
}
