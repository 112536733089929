import { useState } from "react";
import { maximumNumberOfPoints } from "../LimitsValue";

export function nextPointId() {
  // 000000-999999ランダムなID生成
  //万が一pointのidが被った場合、PINセレクト時に同じidの複数PINが緑色になる
  return Math.floor(Math.random() * 1_000_000)
    .toString()
    .padStart(6, "0");
}

// // Action creator
// function addPoint(lat, lng) {
//   return {
//     id: nextPointId(),
//     lat,
//     lng,
//   };
// }
// // Action creator
// function updatePoint({id: "xxxx", }) {
//   // return ...
// }
// // Action creator
// function updateType(id, type) {
//   return updatePoint({ id, type })
// }

// dispatch(updatePoint({id: "xxxx", lat: 0, lng: 0})
// dispatch(updatePoint({id: "xxxx", type: "start" })
// dispatch(updatePoint({id: "xxxx", comment: "..." })

// Reducer
function pointsReducer(prevPoints = [], action) {
  /*
   *  action = {
   *   type: "add",
   *   payload: { id: "xxxx", lat: 30.0, lng: 40.0, comment: "aaa" }
   *  }
   *  action = {
   *   type: "delete",
   *   payload: point_id
   *  }
   *  action = {
   *   type: "update",
   *   payload: { id: "xxxx", ....}
   *  }
   */
  // console.debug({ actionType: action.type });
  switch (action.type) {
    case "add": {
      // prettier-ignore
      const type = 
        prevPoints.length === 0 ? "origin" 
      : prevPoints.length === 1 ? "destination" // 目標点
      : "waypoint" // 中継点

      //console.log(prevPoints);
      if (prevPoints.length >= 2) {
        //中継点は最後から2番目に追加していく
        const firstPoints = prevPoints.slice(0, prevPoints.length - 1);
        const secondPoints = prevPoints.slice(
          prevPoints.length - 1,
          prevPoints.length
        );
        const nextPoints = [
          ...firstPoints,
          { ...action.payload, type },
          ...secondPoints,
        ];
        return nextPoints;
      } else {
        const nextPoints = [...prevPoints, { ...action.payload, type }];

        return nextPoints;
      }

      // console.debug("[pointReducer]", " nextPoints", nextPoints);
    }
    case "delete":
      return prevPoints.filter((point) => point.id !== action.payload);
    case "update": {
      const index = prevPoints.findIndex(
        (point) => point.id === action.payload.id
      );
      return [
        ...prevPoints.slice(0, index),
        { ...prevPoints[index], ...action.payload },
        ...prevPoints.slice(index + 1),
      ];
    }
    default:
      return prevPoints;
  }
}

/**
 * @typedef {object} Point
 * @property {string} id
 * @property {number} lat
 * @property {number} lng
 * @property {string} type
 * @property {string} [comment]
 *
 * @typedef {object} Route
 * @property {string} plan_id
 * @property {Point[]} [points]
 * @property {string} comment
 * @property {string} name
 * @property {any} [path]
 * @property {number} [distance]
 * @property {number} [time]
 */
export function useCurrentRoute() {
  /** @type {[Route, (route: Route) => void]} */
  const [route, setRoute] = useState({ plan_id: "", name: "", comment: "" });
  /** pointのmax値を超えたか否かを管理するState*/
  const [maximumPointsAlert, setMaximumPointAlert] = useState(false);

  /**
   * @param {number} lat
   * @param {number} lng
   */
  function addPoint(lat, lng) {
    //ルート新規作成時はrouteにpointsがない
    //pointsがある かつ　ポイントの数がMax値 の場合アラートに変更
    if (
      Boolean(route.points) &&
      route.points.length === maximumNumberOfPoints
    ) {
      setMaximumPointAlert(true);
      setRoute({ ...route });
    } else {
      const nextPoints = pointsReducer(route.points, {
        type: "add",
        payload: { id: nextPointId(), lat, lng },
      });
      // console.debug("[route]", "nextPoints", nextPoints);
      const nextRoute = {
        ...route,
        points: nextPoints,
      };

      //console.debug("[route]", "nextRoute", nextRoute);

      setRoute(nextRoute);
    }
  }

  //ポイントを一括で追加する {lat:xxx, lng:xxx}の配列
  function addPoints(points) {
    //ルート新規作成時はrouteにpointsがない
    //pointsがある かつ　ポイントの数がMax値 の場合アラートに変更
    //console.log(points);
    if (
      Boolean(route.points) &&
      route.points.length + points.length >= maximumNumberOfPoints
    ) {
      setMaximumPointAlert(true);
      setRoute({ ...route });
    } else if (points.length >= maximumNumberOfPoints) {
      //console.log(points.length);
      setMaximumPointAlert(true);
      setRoute({ ...route });
    } else {
      if (route.points === undefined || route.points.length === 0) {
        //追加する配列順にorigin/destination含めて設定する
        const nextPoints = points.map((p, index) => {
          if (index === 0) {
            return { ...p, id: nextPointId(), type: "origin" };
          } else if (index === points.length - 1) {
            return { ...p, id: nextPointId(), type: "destination" };
          } else {
            return { ...p, id: nextPointId(), type: "waypoint" };
          }
        });
        //console.log(nextPoints);

        const nextRoute = {
          ...route,
          points: nextPoints,
        };
        setRoute(nextRoute);
      } else {
        const _nextPoints = [...route.points]; //今のルートポイント
        if (_nextPoints.length === 1) {
          //originのみある場合
          const newPoints = points.map((p, index) => {
            if (index === points.length - 1) {
              return { ...p, id: nextPointId(), type: "destination" };
            } else {
              return { ...p, id: nextPointId(), type: "waypoint" };
            }
          });
          const nextPoints = [..._nextPoints, ...newPoints];
          //console.log(nextPoints);

          const nextRoute = {
            ...route,
            points: nextPoints,
          };
          setRoute(nextRoute);
        } else if (_nextPoints.length >= 2) {
          //oritin&destinationがすでにある場合
          const newPoints = points.map((p) => {
            return { ...p, id: nextPointId(), type: "waypoint" };
          });

          const prevPoints = _nextPoints.slice(0, -1);
          const afterPoints = _nextPoints.slice(-1);
          //console.log(prevPoints, afterPoints);

          const nextPoints = [...prevPoints, ...newPoints, ...afterPoints];
          //console.log(nextPoints);
          const nextRoute = {
            ...route,
            points: nextPoints,
          };
          setRoute(nextRoute);
        }
      }

      // console.debug("[route]", "nextPoints", nextPoints);
      // const nextRoute = {
      //   ...route,
      //   points: nextPoints,
      // };

      //console.debug("[route]", "nextRoute", nextRoute);
      // setRoute(nextRoute);
    }
  }

  function setPointsDistance(legs,newPoints){
    console.log('legs',legs)
    let withDistancePoints = [];
    let pointDistance = 0
    for(let i=0;i<newPoints.length;i++){
      if(i!=0){
        const str = legs[i-1].distance.value
        pointDistance = str/1000
      }
      withDistancePoints.push({...newPoints[i], distanceFromBefore:pointDistance})
    }
    console.log('before',withDistancePoints)

    return withDistancePoints
  }

  function updatePointLatLng(id, lat, lng) {
    //console.log(route,id,lat,lng);
    const nextPoints = pointsReducer(route.points, {
      type: "update",
      payload: { id, lat, lng },
    });
    //console.log(nextPoints)

    // console.debug("updatePointLatLng", nextPoints);
    setRoute({ ...route, points: nextPoints });
  }

  function updatePointType(id, type) {
    //console.log(route);
    const nextPoints = pointsReducer(route.points, {
      type: "update",
      payload: { id, type },
    });

    // console.debug("updatePointType", nextPoints);
    setRoute({ ...route, points: nextPoints });
  }
  // /**
  //  *
  //  * @param {*} newPoints
  //  */
  // function updatePointsTypes(new)

  /**
   * ルートのPointsの順序をsetする
   * ルート検索withOptimizeの結果格納時に使用
   * @param {Array} newPoints
   */
  function updatePointsOrder(newPoints) {
    // const origin = route.points.filter((p) => p.type === "origin");
    // const dest = route.points.filter((p) => p.type === "destination");
    // console.log(origin, orderedWaypoints, dest);
    // const newPoints = origin.concat(orderedWaypoints).concat(dest);

    setRoute({ ...route, points: newPoints });
  }

  function updatePointComment(id, comment) {
    //console.log(route);
    const nextPoints = pointsReducer(route.points, {
      type: "update",
      payload: { id, comment },
    });

    // console.debug("updatePointType", nextPoints);
    setRoute({ ...route, points: nextPoints });
  }

  function deletePoint(id) {
    //console.log(route);
    //削除＝必ずMax値より少ないためfalse
    setMaximumPointAlert(false);
    const _nextPoints = pointsReducer(route.points, {
      type: "delete",
      payload: id,
    });

    //delete後の配列の最初と最後のtypeをチェックして変更する
    const nextPoints = _nextPoints.map((p, index) => {
      if (index === 0 && p.type !== "origin") {
        return { ...p, type: "origin" };
      } else if (index === _nextPoints.length - 1 && p.type !== "destination") {
        return { ...p, type: "destination" };
      } else {
        return { ...p };
      }
    });

    setRoute({ ...route, points: nextPoints });
  }

  /**
   * @param {string} name
   */
  function setName(plan_name) {
    setRoute({ ...route, plan_name });
  }
  /**
   * distanceとsecondsとPathは連動して変更する 0921追加
   * @param {number} distance
   * @param {number} seconds
   */
  function setDistanceTimePath(distance, seconds, path_geojson) {
    setRoute({ ...route, distance, time: seconds, path_geojson });
  }
  /**
   * route optimization ONの時、
   * distanceとsecondsとPathとpointsは連動して変更する 1109追加
   * @param {number} distance
   * @param {number} seconds
   * @param {array} orderedWaypoints
   */
  function setDistanceTimePathWaypoints(
    distance,
    seconds,
    path_geojson,
    newPoints
  ) {
    // const origin = route.points.filter((p) => p.type === "origin");
    // const dest = route.points.filter((p) => p.type === "destination");
    //console.log(origin, orderedWaypoints, dest);
    // const newPoints = origin.concat(orderedWaypoints).concat(dest);
    //console.log(newPoints);

    setRoute({
      ...route,
      distance,
      time: seconds,
      path_geojson,
      points: newPoints,
    });
  }

  /**
   * @param {number} distance
   */
  function setDistance(distance) {
    setRoute({ ...route, distance });
  }

  /**
   * @param {number} seconds
   */
  function setTime(seconds) {
    setRoute({ ...route, time: seconds });
  }

  function setPath(path) {
    setRoute({ ...route, path });
  }

  function setRouteComment(comment) {
    setRoute({ ...route, comment });
  }

  return {
    route,
    setRoute,
    maximumPointsAlert,
    setMaximumPointAlert,
    setName,
    setDistance,
    setDistanceTimePath,
    setDistanceTimePathWaypoints,
    setTime,
    setPath,
    setRouteComment,
    addPoint,
    addPoints,
    updatePointLatLng,
    updatePointType,
    updatePointComment,
    deletePoint,
    updatePointsOrder,
    setPointsDistance,
  };
}
