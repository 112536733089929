import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

/**
 *
 * @param {string} modalTitle
 * @param {boolean} showModal
 * closeModalはモーダルを閉じたときに実行する関数
 * @param  {string} message
 */

interface modalAlertDeleteResultProps {
  deleteModalShow: boolean;
  cancelDeleteResult: () => void;
  deleteResult: () => void;
  deleteSpinStatus: boolean;
}

export function ModalAlertDeleteResult({
  deleteModalShow,
  cancelDeleteResult,
  deleteResult,
  deleteSpinStatus,
}: modalAlertDeleteResultProps) {
  return (
    <>
      <Modal
        show={deleteModalShow}
        onHide={cancelDeleteResult}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
          <Modal.Title className="titlecustom">
            Comfirm delete Result
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
          Are you sure you want to delete the result?
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
          <Button variant="red" onClick={deleteResult}>
            Delete
            {deleteSpinStatus ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Button>
          <Button variant="grey" onClick={cancelDeleteResult}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
