import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, useParams } from "react-router-dom";
import { useGoogleMap } from "../../../hooks/useGoogleMap";
import { useOverviewMap } from "../../../hooks/useOverviewMap";
import { useUser } from "../../../hooks/useUser";
import { BreadcrumbBar } from "../../BreadcrumbBar";
import { pointInfoWindow } from "../evalPoints/pointInfoWindow";
import { MapSearchBox } from "../MapSearchBox";
import style from "../Route.module.css";
import {
  clearPointInfoWindows,
  createPointsFeatures,
  returnInfoWindowPlan,
  returnInfoWindowResult,
  returnPlanPinIcon,
} from "./drawRouteDetail";
import { EachPathDetail } from "./EachPathDetail";
import { getVecPlans } from "./getVecPlans";
import { getVecResults } from "./getVecResults";
import { MileagesPane } from "./MileagesPane";
import { Polyline } from "./PolyLine";
import { ResultsNaviPane } from "./ResultsNaviPane";
import { kiwiApiVehicles } from "../../../api/kiwiApi/kiwiApis";

const API_KEY = process.env["REACT_APP_MAP_API_KEY"];

const initialConfig = {
  center: { lat: 35.6432027, lng: 139.6729435 },
  zoom: 6,
  mapTypeControl: true,
};

//point表示の際にコメント外す
const infoWindowsPlan = []; //表示したinfoWindowを格納しておく
const infoWindowsResult = []; //表示したinfoWindowを格納しておく

export const ResultsPage = () => {
  const mode = "results";
  const googleMap = useGoogleMap(API_KEY);
  const mapContainerRef = useRef(null);
  const mapSearchBoxRef = useRef(null);
  const mapMileageBoxRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  /** @type {{fleetid: string, vecid: string}}  */
  const fleetId = params.fleetid;
  const carId = params.vecid;
  const fleetName = params.fleetName;
  const carName = params.vecName;

  const [getPlanStatus, setGetPlanStatus] = useState(false);
  const [getResultStatus, setGetResultStatus] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState({}); //選択したPlanを格納
  const [selectedRouteResult, setSelectedRouteResult] = useState({}); //選択したResultを格納
  const [planRoutes, setPlanRoutes] = useState([]);
  const [resultRoutes, setResultRoutes] = useState([]); //このVecの実績ルートを保持

  const [infoWindowsFileImport, setInfoWindowsFileImport] = useState([]);

  const { user } = useUser();
  const map = useOverviewMap({
    googleMap,
    mapContainerRef,
    initialConfig,
    mapSearchBoxRef,
    mapMileageBoxRef,
    returnInfoWindowPlan,
    returnInfoWindowResult,
    pointInfoWindow,
    infoWindowsPlan,
    infoWindowsResult,
    infoWindowsFileImport,
  });

  if (map !== null) {
  }

  //router
  useEffect(()=>{
    (async()=>{
      const fleetid = params.fleetid;
      const fleetName = params.fleetName;
      const rawVecsList = await kiwiApiVehicles(user.token, fleetid)
      if(rawVecsList == "error"){
        history.push(`/Fleets`);
        return
      }
      const authority = rawVecsList.authority
      if (typeof authority == "string"){
        if (authority != "admin"){
          history.push(`/${fleetName}/${fleetid}/Vecs`);
        } 
      }else{
        if(!authority.includes("Result")){
          history.push(`/${fleetName}/${fleetid}/Vecs`);
        }
      }
    })();
  },[])
  //router

  useEffect(() => {
    if (map === null || undefined) {
      return;
    }
    //Plans,Resultsを取得して地図表示する
    getVecPlans({
      fleetId,
      carId,
      user,
      map,
      setPlanRoutes,
      setGetPlanStatus,
    });
    getVecResults({
      fleetId,
      carId,
      user,
      setResultRoutes,
      setGetResultStatus,
      map,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  // useEffect(() => {
  //   //mapのZoomとdragイベントが起きたらマップ範囲を取得する
  //   if (map === null) {
  //     return;
  //   }
  //   //mapのマウスイベントリストは下記リンク参照
  //   //https://developers.google.com/maps/documentation/javascript/reference/map#Map-Events

  //   map.addListener("zoom_changed", function (e) {
  //     // const zoom = map.getZoom();
  //     const La = map.getBounds().La;
  //     const Ua = map.getBounds().Ua;
  //     console.log(La, Ua);
  //   });

  //   map.addListener("dragend", function (e) {
  //     // const zoom = map.getZoom();
  //     console.log(map.getBounds());
  //     const La = map.getBounds().La;
  //     const Ua = map.getBounds().Ua;
  //     console.log(La, Ua);
  //   });
  // }, [map]);

  //selected Planの時
  useEffect(() => {
    //あるパスが選択された時の描画変更
    if (map === null || selectedRoute === undefined) {
      return;
    } else if (selectedRoute.path_geojson === undefined) {
      //pointを消す
      map.data.forEach((feature) => {
        const id = feature.getId();
        if (id === undefined) {
          return;
        }
        if (id.startsWith("selectedRoutePointsPlan")) {
          const prevPointsFeature = map.data.getFeatureById(id);
          if (prevPointsFeature !== undefined) {
            map.data.remove(prevPointsFeature);
          }
        }
      });
      clearPointInfoWindows({ infoWindows: infoWindowsPlan }); //infoWindowを閉じる
    } else {
      /**Pointの表示*/
      //pointを消す
      map.data.forEach((feature) => {
        const id = feature.getId();
        if (id === undefined) {
          return;
        }
        if (id.startsWith("selectedRoutePointsPlan")) {
          const prevPointsFeature = map.data.getFeatureById(id);
          map.data.remove(prevPointsFeature);
        }
      });
      clearPointInfoWindows({ infoWindows: infoWindowsPlan }); //infoWindowを閉じる

      //新しいPointを描画
      const mode = "plan_id" in selectedRoute ? "plan" : "result";
      // console.log(mode);
      const points = selectedRoute.points;
      if (points === undefined) {
        return;
      }
      const pointsFeatures = createPointsFeatures({ mode, points });
      map.data.addGeoJson(pointsFeatures);
      points.forEach((p, index) => {
        const featureId = `selectedRoutePointsPlan${index + 1}${p.lng}${p.lat}`;
        const pointFeature = map.data.getFeatureById(featureId);

        const label = pointFeature.getProperty("label");
        const type = pointFeature.getProperty("type");
        const comment = pointFeature.getProperty("comment");

        const planPinIcon = returnPlanPinIcon({ type, comment });
        map.data.overrideStyle(pointFeature, {
          icon: {
            url: planPinIcon.url,
            anchor: new googleMap.maps.Point(
              planPinIcon.anchor.width,
              planPinIcon.anchor.height
            ),
            scaledSize: new googleMap.maps.Size(
              planPinIcon.size.width,
              planPinIcon.size.height
            ),
          },
          label: {
            text: label,
            fontSize: "12px",
            fontWeight: "bold",
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, selectedRoute]);

  //selected Resultの時
  useEffect(() => {
    //あるパスが選択された時の描画変更
    if (map === null || selectedRouteResult === undefined) {
      return;
    } else if (selectedRouteResult.path_geojson === undefined) {
      //pointを消す
      map.data.forEach((feature) => {
        const id = feature.getId();
        if (id === undefined) {
          return;
        }
        if (id.startsWith("selectedRoutePointsResult")) {
          const prevPointsFeature = map.data.getFeatureById(id);
          if (prevPointsFeature !== undefined) {
            map.data.remove(prevPointsFeature);
          }
        }
      });

      //infoWindowを閉じる
      clearPointInfoWindows({ infoWindows: infoWindowsResult });
    } else {
      /**Pointの表示*/
      //pointを消す
      map.data.forEach((feature) => {
        const id = feature.getId();
        if (id === undefined) {
          return;
        }
        if (id.startsWith("selectedRoutePointsResult")) {
          const prevPointsFeature = map.data.getFeatureById(id);
          map.data.remove(prevPointsFeature);
        }
      });

      //infoWindowを閉じる
      clearPointInfoWindows({ infoWindows: infoWindowsResult });

      //新しいPointを描画
      const mode = "result";
      const points = selectedRouteResult.issue_points;
      if (points === undefined) {
        return;
      }
      const pointsFeatures = createPointsFeatures({ mode, points });
      map.data.addGeoJson(pointsFeatures);
      points.forEach((p, index) => {
        const featureId = `selectedRoutePointsResult${p.no}${p.lng}${p.lat}`;
        const pointFeature = map.data.getFeatureById(featureId);

        //resultのIssuePointの場合のstyle変更
        const label = pointFeature.getProperty("label");
        const priority = pointFeature.getProperty("priority");

        map.data.overrideStyle(pointFeature, {
          icon: {
            path: googleMap.maps.SymbolPath.CIRCLE,
            scale: priority === "Priority 0" ? 15 : 10,
            fillColor: priority === "Priority 0" ? "red" : "yellow",
            fillOpacity: 0.4,
            strokeWeight: 0,
          },
          label: {
            text: label,
            fontSize: priority === "Priority 0" ? "12px" : "12px",
            color: priority === "Priority 0" ? "black" : "black",
            fontWeight: "bold",
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, selectedRouteResult]);

  return (
    <>
      <BreadcrumbBar params={params} />
      <div style={{ position: "relative" }}>
        <LoadingOverlay
          active={getPlanStatus || getResultStatus}
          spinner
          text="Loading your content..."
        >
          <Container fluid>
            {/* Header/BreadcrumbBarの高さの合計が104.8px */}
            <Row
              style={{
                height: "calc(100vh - 104.8px)",
                position: "relative",
              }}
            >
              <Col sm={2} className={style.routePageCol}>
                <ResultsNaviPane
                  fleetId={fleetId}
                  carId={carId}
                  fleetName={fleetName}
                  carName={carName}
                  googleMap={googleMap}
                  map={map}
                  setGetPlanStatus={setGetPlanStatus}
                  selectedRoute={selectedRoute}
                  setSelectedRoute={setSelectedRoute}
                  selectedRouteResult={selectedRouteResult}
                  setSelectedRouteResult={setSelectedRouteResult}
                  planRoutes={planRoutes}
                  resultRoutes={resultRoutes}
                  setResultRoutes={setResultRoutes}
                  infoWindowsFileImport={infoWindowsFileImport}
                  setInfoWindowsFileImport={setInfoWindowsFileImport}
                />
              </Col>
              <Col sm={10} className={style.routePageCol}>
                <>
                  <div
                    style={{
                      height: "100%",
                      position: "relative",
                    }}
                    ref={mapContainerRef}
                  >
                    {selectedRoute === undefined ||
                    selectedRoute.path_geojson === undefined ? null : (
                      <Polyline
                        selectedRoute={selectedRoute}
                        map={map}
                        googleMap={googleMap}
                      ></Polyline>
                    )}
                    {selectedRouteResult === undefined ||
                    selectedRouteResult.path_geojson === undefined ? null : (
                      <Polyline
                        selectedRoute={selectedRouteResult}
                        map={map}
                        googleMap={googleMap}
                      ></Polyline>
                    )}
                  </div>
                  <div ref={mapSearchBoxRef} style={{ margin: "10px" }}>
                    <MapSearchBox
                      googleMap={googleMap}
                      map={map}
                      points={[]}
                      mode={mode}
                    />
                  </div>
                  <div ref={mapMileageBoxRef} style={{ margin: "10px" }}>
                    <MileagesPane
                      planRoutes={planRoutes}
                      resultRoutes={resultRoutes}
                    />
                  </div>
                  <div
                    style={{
                      position: "fixed",
                      bottom: "0",
                      //width: "calc(1000vw / 12)",
                      marginRight: "auto",
                      marginLeft: "auto",
                      margin: 0,
                      //display: "flex",
                    }}
                  >
                    {"plan_name" in selectedRoute ? (
                      <EachPathDetail
                        selectedRoute={selectedRoute}
                        setSelectedRoute={setSelectedRoute}
                        resultRoutes={resultRoutes}
                        setResultRoutes={setResultRoutes}
                        map={map}
                      />
                    ) : null}
                    {"result_name" in selectedRouteResult ? (
                      <EachPathDetail
                        selectedRoute={selectedRouteResult}
                        setSelectedRoute={setSelectedRouteResult}
                        resultRoutes={resultRoutes}
                        setResultRoutes={setResultRoutes}
                        map={map}
                      />
                    ) : null}
                  </div>
                </>
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
      </div>
    </>
  );
};
