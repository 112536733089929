import React from "react";

//ゴミ箱Icon
export const TrashIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="TrashCan"
      >
        <path d="M4 6h16l-1.58 14.22A2 2 0 0116.432 22H7.568a2 2 0 01-1.988-1.78L4 6z" />
        <path d="M7.345 3.147A2 2 0 019.154 2h5.692a2 2 0 011.81 1.147L18 6H6l1.345-2.853z" />
        <path d="M2 6h20" />
        <path d="M10 11v5" />
        <path d="M14 11v5" />
      </svg>
    </>
  );
};

//ボタン用PINアイコン
export const PinButtonIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="Pin"
      >
        <path d="M16.115 2h-8.23C6.97 2 6.2 2.572 6.086 3.333L6 4c-.08.539.174.985.584 1.363.125.115.251.23.375.347.776.732.85 1.858.508 2.869l-.429 1.269a2.948 2.948 0 01-.09.3c-.099.269-.33.862-.63 1.379-.19.327-.478.58-.793.79-.756.504-.4 1.683.51 1.683H10.5v2.111c0 1.242-.21 2.467.345 3.578L12 22l1.155-2.31c.556-1.112.345-2.337.345-3.579V14h4.465c.91 0 1.266-1.18.51-1.684-.315-.21-.603-.462-.793-.79a9.33 9.33 0 01-.63-1.379 2.97 2.97 0 01-.09-.3l-.429-1.269c-.34-1.01-.267-2.136.508-2.868.124-.117.25-.232.375-.347.41-.378.664-.824.584-1.363l-.086-.667C17.8 2.572 17.029 2 16.115 2z" />
      </svg>
    </>
  );
};

//丸囲みバツアイコン
export const CircleXIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="CircleX"
      >
        <path d="M15 15L9 9m6 0l-6 6" />
        <circle cx="12" cy="12" r="10" />
      </svg>
    </>
  );
};

export const CircleXIconLarge = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        display="block"
        id="CircleX"
      >
        <path d="M15 15L9 9m6 0l-6 6" />
        <circle cx="12" cy="12" r="10" />
      </svg>
    </>
  );
};

//戻るアイコン
export const DiscardIcon = () => {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="ArrowBack"
      >
        <path d="M8 5l-5 5 5 5" />
        <path d="M3 10h8c5.523 0 10 4.477 10 10v1" />
      </svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="ArrowCounterClockwise"
      >
        <path d="M4.266 16.06a8.923 8.923 0 003.915 3.978 8.706 8.706 0 005.471.832 8.796 8.796 0 004.887-2.64 9.067 9.067 0 002.388-5.079 9.137 9.137 0 00-1.044-5.53 8.904 8.904 0 00-4.068-3.815 8.7 8.7 0 00-5.5-.608c-1.85.401-3.367 1.313-4.62 2.755-.152.16-.736.806-1.22 1.781" />
        <path d="M8.931 7.813l-5.04.907L3 3.59" />
      </svg>
    </>
  );
};

//fileダウンロードアイコン
export const DonloadFiles = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="Download"
      >
        <path d="M12 15V3m0 12l-4-4m4 4l4-4" />
        <path d="M2 17l.621 2.485A2 2 0 004.561 21H19.439a2 2 0 001.94-1.515L22 17" />
      </svg>
    </>
  );
};

//小さくするアイコン
export const ReduceIcon = ({ style }) => {
  return (
    <>
      <div style={style}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          display="block"
          id="Reduce"
        >
          <path d="M3 21l7-7" />
          <path d="M14 10l7-7" />
          <path d="M20 10h-6V4" />
          <path d="M10 20v-6H4" />
        </svg>
      </div>
    </>
  );
};

//広げるアイコン
export const ExpandIcon = ({ style }) => {
  return (
    <>
      <div style={style}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          display="block"
          id="Enlarge"
        >
          <path d="M3 21l6.5-6.5" />
          <path d="M14.5 9.5L21 3" />
          <path d="M15 3h6v6" />
          <path d="M3 15v6h6" />
        </svg>
      </div>
    </>
  );
};
