import React, { useEffect, useRef, useState } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import {
  createPointGeoJsonKML,
  returnPointsFromKML,
} from "../../evalPoints/kmlParser";
import { evalPointStyleRandom } from "../../evalPoints/lineStylesEvalPoints";
import { createPointGeoJson } from "../../evalPoints/pointcsvParser";
import { randomColor } from "../../evalPoints/randomColor";
import { CircleXIcon } from "../../icons";
import { InfoIcon } from "../../infoIcon";

/**
 * 評価ポイントcsv読み込みなどするコンポーネント
 * @param {google.maps.Map} map
 * @param {google} googleMap
 */
export function FileInputPart({
  map,
  googleMap,
  infoWindowsFileImport,
  setInfoWindowsFileImport,
}) {
  const [filesInfo, setFilesInfo] = useState([]);
  const sampleRef = useRef(null);
  useEffect(() => {
    if (map === null || map === undefined || filesInfo.length === 0) {
      return;
    }
    //fileaInfoのVisibleに伴ってmap上の表示・非表示を切り替える
    filesInfo.forEach((file) => {
      const { name, visible } = file;
      map.data.forEach((feature) => {
        if (feature.getProperty("fileName") === name) {
          map.data.overrideStyle(feature, {
            visible,
          });
        }
        if (visible === false) {
          //infoWindowのisOpendを全てfalseにする
          feature.setProperty("isOpend", visible);
        }
      });
      if (visible === false) {
        const deleteWindows = infoWindowsFileImport.filter((window) => {
          return window.fileName === name;
        });
        //console.log(deleteWindows);
        deleteWindows.forEach((window) => {
          window.infoWindow.close();
        });
        const _new = infoWindowsFileImport.filter((window) => {
          return window.fileName !== name;
        });
        setInfoWindowsFileImport(_new);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesInfo, map]);

  //fileを読み込んでaddGeoJsonする関数
  function drawingFilesData(e) {
    const files = e.target.files;
    const importFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = e.target.files[i];

      const pointColor = randomColor();
      file.pointColor = pointColor;
      file.visible = true;

      importFiles.push(file);
      const fileName = file.name; //ファイル名
      const fileExtension = file.name.split(".")[1]; //拡張子
      const reader = new FileReader();
      reader.onload = function (e) {
        if (fileExtension === "csv") {
          // csvの場合
          const pointsGeoJson = createPointGeoJson(fileName, reader.result);
          const _addFeatureID = pointsGeoJson.features.map((feature) => {
            const id = `importedFromCSV${fileName}${feature.geometry.coordinates[0]}${feature.geometry.coordinates[1]}`;
            return { ...feature, id };
          });
          pointsGeoJson.features = _addFeatureID;
          map.data.addGeoJson(pointsGeoJson);
        } else if (fileExtension === "kml") {
          //kmlの場合
          const fileName = file.name;
          const kmlStr = reader.result;
          const kmlPoints = returnPointsFromKML({ kmlStr, fileName });
          const geoJson = createPointGeoJsonKML(kmlPoints);
          const _addFeatureID = geoJson.features.map((feature) => {
            const id = `importedFromKML${fileName}${feature.geometry.coordinates[0]}${feature.geometry.coordinates[1]}`;
            return { ...feature, id };
          });
          geoJson.features = _addFeatureID;
          map.data.addGeoJson(geoJson);
        } else if (fileExtension === "json") {
          const _geojson = reader.result; //string型
          const geojson = JSON.parse(_geojson); //object型にする
          // console.log(geojson);
          const featureId = fileName;
          geojson.properties.fileName = fileName;
          geojson.id = featureId;
          map.data.addGeoJson(geojson);
        } else {
          console.warn("対応していないfile形式");
        }

        //線のスタイルなどを変更する

        //json内のLineのスタイル変更
        if (fileExtension === "json") {
          const feature = map.data.getFeatureById(fileName);
          //console.log(feature);
          const featureGeoType = feature.getGeometry().getType();
          //console.log(featureGeoType);
          if (featureGeoType === "MultiLineString" || "LineString") {
            map.data.overrideStyle(feature, {
              strokeColor: pointColor,
              strokeWeight: "5",
            });
          }
        }
        //csvやkmlのPointのスタイル変更
        //各ポイント1つ1つをpointのgeojsonでaddFeatureしているため、forEachで回している
        //各ポイントに吹き出しを付けるために、1つずつpointで定義して描画している
        map.data.forEach((feature) => {
          const id = feature.getId();
          //MultiLineStringの時に色を変える件、1025
          const featureIdStartsWith =
            fileExtension === "csv"
              ? `importedFromCSV${fileName}`
              : fileExtension === "kml"
              ? `importedFromKML${fileName}`
              : `none`;

          if (id.startsWith(featureIdStartsWith)) {
            map.data.overrideStyle(
              feature,
              evalPointStyleRandom(googleMap, pointColor)
            );
          }
        });
      };
      reader.readAsText(file);
    }
    const newFiles = filesInfo.concat(importFiles);
    setFilesInfo(newFiles);

    //console.log(sampleRef.current.value);
    //ファイルinputの履歴をリセットする
    sampleRef.current.value = null;
  }

  function changeFileVisible(e) {
    //該当ファイルのvisibleを変更する
    const fileName = e.target.id;

    const newfilesInfo = filesInfo.map((file) => {
      if (file.name === fileName) {
        file.visible = !file.visible;
        return file;
      } else {
        return file;
      }
    });
    setFilesInfo(newfilesInfo);
  }
  function removeFile(fileName) {
    const removeFileInfo = filesInfo.filter((f) => f.name !== fileName);
    setFilesInfo(removeFileInfo);
    map.data.forEach((feature) => {
      if (feature.getProperty("fileName") === fileName) {
        map.data.remove(feature);
      }
    });
    //開いているinfoWindowがあれば閉じる＆infoWindowの内容も削除する
    const deleteWindows = infoWindowsFileImport.filter((window) => {
      return window.fileName === fileName;
    });

    deleteWindows.forEach((window) => {
      window.infoWindow.close();
    });
    const _new = infoWindowsFileImport.filter((window) => {
      return window.fileName !== fileName;
    });
    setInfoWindowsFileImport(_new);
  }
  return (
    <>
      <Form.Group>
        <Form.Label
          className="titlecustom"
          style={{ fontWeight: "bold", fontSize: "15px" }}
        >
          Import Files
        </Form.Label>
        <InfoIcon message="This is available for a specified csv and kml files." />
        <Form.File
          id="custom-file"
          variant="grey"
          custom
          style={{ margin: 0, padding: 0, fontSize: "8px" }}
        >
          <Form.File.Label>
            {`files: ${filesInfo.length}`}
            <Form.File.Input
              multiple="true"
              onChange={drawingFilesData}
              ref={sampleRef}
            />
          </Form.File.Label>
        </Form.File>
      </Form.Group>
      <Form.Group>
        <ListGroup variant="flush" style={{ fontSize: "12px" }}>
          {filesInfo.map((file, key) => (
            <ListGroup.Item
              key={key}
              style={{
                margin: "2px",
                // background: "linearGradient(transparent 0%, red 0%)",
              }}
            >
              {/* <Form.Check
                type="checkbox"
                checked={file.visible}
                onChange={changeFileVisible}
                id={file.name}
                label={file.name}
                style={{ margin: "5px", height: "20px", width: "20px" }}
              /> */}
              <input
                id={file.name}
                type="checkbox"
                checked={file.visible}
                onChange={changeFileVisible}
                style={{
                  margin: "5px",
                  padding: "2px",
                  width: "15px",
                  height: "15px",
                }}
              />
              <span
                style={{
                  background: `linear-gradient(transparent 60%, ${file.pointColor} 60%)`,
                }}
              >
                {file.name}
              </span>
              <Button
                size="sm"
                style={{ fontSize: "5px", margin: "1px" }}
                variant="grey"
                onClick={() => removeFile(file.name)}
                title="remove file"
              >
                <CircleXIcon />
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Form.Group>
    </>
  );
}
