import { kiwiApi } from "../kiwiApi";
import { lineStyle_plan_normal } from "./lineStyles";

/**
 * ルートのPathをGETして地図に描画する
 * @param {Promise} getPathPlanRoutes
 * @param {google.maps.Map} map
 */
async function drawPlanRoutePath({
  getPathPlanRoutes,
  map,
  setPlanRoutes,
  setGetPlanStatus,
}) {
  const _planRoutes = []; //取得したplanRoutePath結果を格納
  for await (let r of getPathPlanRoutes) {
    //console.log(r);
    _planRoutes.push(r);

    //地図に表示
    if (r.path_geojson !== undefined) {
      const category = "plan";
      const comment = r.comment;
      const distance = r.distance;
      const plan_id = r.plan_id;
      const plan_name = r.plan_name;
      const time = r.time;

      r.path_geojson.properties = {
        category,
        comment,
        distance,
        time,
        plan_id,
        plan_name,
      };

      r.path_geojson.id = plan_id; //FeatureIDを追加する(plan_idで追加)

      map.data.addGeoJson(r.path_geojson);

      map.data.setStyle((feature) => {
        return lineStyle_plan_normal;
      });
    }
  }
  setPlanRoutes(_planRoutes);
  setGetPlanStatus(false);

  //地図をplanの1つ目の出発点に移動する
  if (
    _planRoutes.length !== 0 &&
    _planRoutes[0].points !== undefined &&
    _planRoutes[0].points[0] !== undefined
  ) {
    const { lat, lng } = _planRoutes[0].points[0];
    map.panTo({ lat, lng });
  }
}

/**
 * planルート地図に描画する
 * @param {String} fleetId
 * @param {String} carId
 * @param {Object} user
 * @param {useState} setPlanRoutes
 */
export async function getVecPlans({
  fleetId,
  carId,
  user,
  setPlanRoutes,
  setGetPlanStatus,
  map,
}) {
  setGetPlanStatus(true);
  kiwiApi(user.token)
    .getPlans({
      fleetId,
      carId,
    })
    .then((list_planRoutes) => {
      setPlanRoutes(list_planRoutes);
      const getPathPlanRoutes = list_planRoutes.map((result) => {
        const planId = result.plan_id;
        return kiwiApi(user.token)
          .getPlan({
            planId,
          })
          .then((r) => {
            return r;
          });
      });
      drawPlanRoutePath({
        getPathPlanRoutes,
        map,
        setPlanRoutes,
        setGetPlanStatus,
      });
    })
    .catch((error) => {
      console.log(error);
      setGetPlanStatus(false);
    });
}
