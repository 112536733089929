import { useEffect, useState } from "react";

export function useMap({
  googleMap,
  mapContainerRef,
  initialConfig,
  addPoint,
  mapSearchBoxRef,
  route,
}) {
  const [map, setMap] = useState(null);

  useEffect(
    () => {
      // googleMapかmapContainerRefが初期化されてなければ何もしない
      if (!googleMap || !mapContainerRef.current) {
        return;
      }

      const MapTypeIdROADMAP = googleMap.maps.MapTypeId.ROADMAP;
      initialConfig.mapTypeId = MapTypeIdROADMAP;

      const map = new googleMap.maps.Map(
        mapContainerRef.current,
        initialConfig
      );

      //検索Windowを地図に配置
      mapSearchBoxRef.current.style.display = "block";
      map.controls[googleMap.maps.ControlPosition.TOP_RIGHT].push(
        mapSearchBoxRef.current
      );
      //console.debug("useMap", map);
      setMap(map);
    },
    // googleMapかmapContainerRefが変化したらeffectが発火する。
    // initialConfigは変わったとしても再マウントするとおかしなことになるので更新対象にしない
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googleMap, mapContainerRef.current]
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    const listener = map.addListener("click", function (e) {
      //console.log(`Pinned: ${e.latLng.lat()},${e.latLng.lng()}`);
      addPoint(e.latLng.lat(), e.latLng.lng());
      //console.debug("useMap", route);
    });

    return () => {
      listener.remove();
    };
  }, [map, addPoint]);

  // useEffect(()=>{
  //   if(!map || !route){
  //     return;
  //   }
  //   // console.log(route)
  //   if(route.points !== undefined){
  //     const originPos = route.points.find(point=> point.type==="origin")
  //     //originがない場合は一番最初にあるポイントをcenterにする
  //     const centerPos = originPos!==undefined ? originPos: route.points[0]
  //     const {lat, lng} = centerPos
  //     map.setCenter({lat, lng});
  //   }else{
  //     //pointsがない場合には東京
  //     map.setCenter({ lat: 35.6432027, lng: 139.6729435 });
  //   }

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[route])
  // あとで使えるようにmapを返すようにする
  return map;
}
