import { useEffect, useRef } from "react";

export function Polyline({ selectedRoute, googleMap, map }) {
  const polyLineObject = useRef(null);

  useEffect(() => {
    if (
      selectedRoute === undefined ||
      selectedRoute.path_geojson === undefined
    ) {
      //selectedRouteがないため、Objectをnullにする
      polyLineObject.current = null;
      return;
    }

    if (polyLineObject.current) {
      //前回のpolylineがある場合は消す
      polyLineObject.current.setMap(null);
    }

    /** @type {google.maps.Marker} */
    const coods = selectedRoute.path_geojson.geometry.coordinates.map((c) => {
      return { lat: c[1], lng: c[0] };
    });
    const strokeColor =
      selectedRoute.plan_name !== undefined ? "#A10202" : "#0252A1";

    const lineSymbol = {
      path: googleMap.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      strokeColor: strokeColor,
      strokeWeight: 3,
      scale: 4,
    };
    const line = new googleMap.maps.Polyline({
      map: map,
      path: coods,
      strokeColor: strokeColor,
      strokeWeight: 3,
      icons: [
        {
          icon: lineSymbol,
          offset: "100%",
          repeat: "100px",
        },
      ],
    });

    polyLineObject.current = line;
  }, [
    googleMap,
    map,
    polyLineObject,
    selectedRoute,
    selectedRoute.path_geojson,
  ]);

  useEffect(() => {
    return () => {
      if (polyLineObject.current) {
        polyLineObject.current.setMap(null);
      }
    };
  }, []);

  return null;
}
