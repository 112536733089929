import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  useAccordionToggle,
} from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useUser } from "../../../hooks/useUser";
import Pencil from "../../../image/pencil.png";
import { Plan, Result } from "../../../types";
import { CircleXIconLarge, ExpandIcon, ReduceIcon } from "../icons";
import { kiwiApi } from "../kiwiApi";
import { sec2hhmm } from "../plan/SearchDirections";
import { roundDistance } from "../roundDistance";
import { removeGeoJson } from "./addRemoveGeoJson";
import { ModalAlertDeleteResult } from "./ModalAlertDeleteResult";
import { ModalMessage } from "./ModalMessage";

interface EachPathDetailProps {
  selectedRoute: Plan | Result;
  setSelectedRoute: (selectedRoute: Plan | Result | {}) => void;
  resultRoutes: Result[];
  setResultRoutes: (results: Result[]) => void;
  map: any;
}
/**
 * Result画面で各pathの詳細情報を表示するコンポーネント
 *
 */
export function EachPathDetail({
  selectedRoute,
  setSelectedRoute,
  resultRoutes,
  setResultRoutes,
  map,
}: EachPathDetailProps) {
  const [isEditResult, setEditResult] = useState<boolean>(false);
  //spin表示用
  const [duringUpdateResult, setDuringUpdateResult] = useState<boolean>(false);
  const [duringDeleteResult, setDuringDeleteResult] = useState<boolean>(false);

  //ModalMessageの中身＆状態管理　--処理後のmessage表示
  const [isShowMessage, setIsShowMessage] = useState<boolean>(false);
  const [modalTitleConfirm, setModalTitleConfirm] = useState<string>("");
  const [modalMessageConfirm, setModalMessageConfirm] = useState<string>("");
  //削除前の警告モーダル
  const [isAlertDeleteShow, setIsAlertDeleteShow] = useState<boolean>(false);

  const selectedRouteNameRef = useRef<HTMLInputElement>(null);
  const selectedRouteCommentRef = useRef<HTMLTextAreaElement>(null);
  const { user } = useUser();

  useEffect(() => {
    if (selectedRoute === null) {
      return;
    }
    if (!selectedRouteNameRef.current) {
      return;
    } else if ("plan_name" in selectedRoute) {
      selectedRouteNameRef.current.value = selectedRoute.plan_name;
    } else {
      selectedRouteNameRef.current.value = selectedRoute.result_name;
    }

    if (!selectedRouteCommentRef.current) {
      return;
    } else if (selectedRoute.comment === undefined) {
      selectedRouteCommentRef.current.value = "";
    } else {
      selectedRouteCommentRef.current.value = selectedRoute.comment;
    }
  }, [selectedRoute]);

  function putResultMetadata(selectedRoute: Plan | Result) {
    setDuringUpdateResult(true);
    const result_name = selectedRouteNameRef.current
      ? selectedRouteNameRef.current.value
      : "";
    const comment = selectedRouteCommentRef.current
      ? selectedRouteCommentRef.current.value
      : "";

    if ("result_id" in selectedRoute) {
      const resultId = selectedRoute.result_id;
      const updateResult = { result_name, comment };

      kiwiApi(user.token)
        .updateResult({ resultId, updateResult })
        .then((response) => {
          //console.log("success", response);
          //resultRoutesからupdateしたresultを検索して更新してSetする
          const _newResults = [...resultRoutes];
          const newResults = _newResults.map((r) => {
            if (r.result_id === resultId) {
              const _r = { ...r };
              _r.result_name = result_name;
              _r.comment = comment;
              return _r;
            } else {
              return r;
            }
          });
          setResultRoutes(newResults);
          setDuringUpdateResult(false);
          //更新OKのmsg表示
          setIsShowMessage(true);
          setModalTitleConfirm("SUCCESS! update Results");
          setModalMessageConfirm(`The result has been updated successfully!.`);
        })
        .catch((error) => {
          console.log("error", error);
          //更新失敗のmsg表示
          setIsShowMessage(true);
          setModalTitleConfirm("ERROR! update Results");
          setModalMessageConfirm(error.message);
          setDuringUpdateResult(false);
        });
    }
  }

  function deleteResultRoute(selectedRoute: Plan | Result) {
    setDuringDeleteResult(true);

    //モーダルが上手く開かない
    if ("result_id" in selectedRoute) {
      const resultId = selectedRoute.result_id;

      kiwiApi(user.token)
        .deleteResult({ resultId })
        .then((response) => {
          //console.log("success", response);
          //resultRoutesからupdateしたresultを削除してSetする
          const _newResults = [...resultRoutes];
          const newResults = _newResults.filter((r) => {
            return r.result_id !== resultId;
          });
          setIsAlertDeleteShow(false);
          setResultRoutes(newResults);
          removeGeoJson({ map, removeId: resultId });
          setDuringDeleteResult(false);

          //削除成功のmsg表示
          setIsShowMessage(true);
          setModalTitleConfirm("SUCCESS! delete Result");
          setModalMessageConfirm(`The result has been deleted successfully!`);
          // setSelectedRoute({}); //編集欄を閉じる
        })
        .catch((error) => {
          console.log("error", error);

          //削除失敗のmsg表示
          setIsShowMessage(true);
          setModalTitleConfirm("ERROR! delete Result");
          setModalMessageConfirm(error.message);
          setDuringDeleteResult(false);
        });
    }
  }
  //Delete時に用いるCloseModal
  function closeModalConfirm(modalTitleConfirm: string) {
    if (modalTitleConfirm.includes("Fail")) {
      setIsShowMessage(false);
    } else {
      setIsShowMessage(false);
      setSelectedRoute({}); //windowを閉じる
    }
  }

  return (
    <>
      <PathInfoPane
        type={"plan_name" in selectedRoute ? "plan" : "result"}
        setSelectedRoute={setSelectedRoute}
        bodyComponent={
          <BodyComponent
            selectedRouteNameRef={selectedRouteNameRef}
            selectedRouteCommentRef={selectedRouteCommentRef}
            isEditResult={isEditResult}
            setEditResult={setEditResult}
            selectedRoute={selectedRoute}
            //setSelectedRoute={setSelectedRoute}
            duringUpdateResult={duringUpdateResult}
            duringDeleteResult={duringDeleteResult}
            putResultMetadata={putResultMetadata}
            setIsAlertDeleteShow={setIsAlertDeleteShow}
          />
        }
      />

      <ModalMessage
        modalTitle={modalTitleConfirm}
        message={modalMessageConfirm}
        showModal={isShowMessage}
        closeModal={() => closeModalConfirm(modalTitleConfirm)}
      />
      <ModalAlertDeleteResult
        deleteModalShow={isAlertDeleteShow}
        cancelDeleteResult={() => setIsAlertDeleteShow(false)}
        deleteResult={() => deleteResultRoute(selectedRoute)}
        deleteSpinStatus={duringDeleteResult}
      />
    </>
  );
}

interface BodyComponentProps {
  selectedRouteNameRef: React.RefObject<HTMLInputElement>;
  selectedRouteCommentRef: React.RefObject<HTMLTextAreaElement>;
  isEditResult: boolean;
  setEditResult: (isEditResult: boolean) => void;
  selectedRoute: Plan | Result;
  // setSelectedRoute: (selectedRoute: Plan | Result | {}) => void;
  duringUpdateResult: boolean;
  duringDeleteResult: boolean;
  putResultMetadata: (selectedRoute: Plan | Result) => void;
  setIsAlertDeleteShow: (isAlertDeleteShow: boolean) => void;
}
//各pathの詳細情報を表示するコンポーネント
//resultの場合は編集・削除も可
function BodyComponent({
  selectedRouteNameRef,
  selectedRouteCommentRef,
  isEditResult,
  setEditResult,
  selectedRoute,
  // setSelectedRoute,
  duringUpdateResult,
  duringDeleteResult,
  putResultMetadata,
  setIsAlertDeleteShow,
}: BodyComponentProps) {
  const EditButton = () => {
    return (
      <>
        {"result_name" in selectedRoute ? (
          <Button
            size="sm"
            variant={isEditResult ? "red" : "grey"}
            style={{ margin: "5px" }}
            onClick={() => setEditResult(!isEditResult)}
          >
            <img
              src={Pencil}
              alt="Edit"
              title="edit result data."
              width="20"
              height="20"
            />{" "}
            {/* {isEditResult ? "ON" : "OFF"} */}
          </Button>
        ) : null}
      </>
    );
  };

  const DistanceTimePart = () => {
    return (
      <>
        <Col sm={6}>
          <Form.Text>
            {"distance" in selectedRoute && selectedRoute.distance !== undefined
              ? `${roundDistance(selectedRoute.distance)}km`
              : "---"}
          </Form.Text>
        </Col>
        <Col sm={6}>
          <Form.Text>
            {selectedRoute.time !== undefined
              ? sec2hhmm(selectedRoute.time)
              : "---"}
          </Form.Text>
        </Col>
      </>
    );
  };

  const SaveDeleteButton = () => {
    return (
      <>
        {isEditResult ? (
          <Button
            variant="red"
            style={{ margin: "5px" }}
            size="sm"
            onClick={() => putResultMetadata(selectedRoute)}
          >
            Save
            {duringUpdateResult ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Button>
        ) : null}
        {isEditResult ? (
          <Button
            variant="grey"
            size="sm"
            style={{ margin: "5px" }}
            onClick={() => setIsAlertDeleteShow(true)}
          >
            Delete
            {duringDeleteResult ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Form.Group>
        <Row style={{ justifyContent: "flex-end" }}>
          <EditButton />
        </Row>
        <Row>
          <Form.Text>Name</Form.Text>
          <Form.Control
            type="text"
            maxLength={256}
            size="sm"
            ref={selectedRouteNameRef}
            disabled={!isEditResult}
          />
          {isEditResult ? (
            <Form.Text className="text-muted">
              *limits to 250 characters
            </Form.Text>
          ) : null}
        </Row>
        <Row>
          <DistanceTimePart />
        </Row>
        <Row>
          <Form.Text>Comment:</Form.Text>
          <Form.Control
            as="textarea"
            maxLength={1000}
            size="sm"
            ref={selectedRouteCommentRef}
            disabled={!isEditResult}
            style={{ resize: "none" }}
          />
          {isEditResult ? (
            <Form.Text className="text-muted">
              *limits to 1000 characters
            </Form.Text>
          ) : null}
        </Row>
        <Row>
          <SaveDeleteButton />
        </Row>
      </Form.Group>
    </>
  );
}

interface AccordionToggleIconProps {
  // children: React.ReactNode;
  eventKey: string;
  callback?: (eventkey: string) => void;
  type: string;
  setSelectedRoute: (selectedRoute: Plan | Result | {}) => void;
}

//AccordionのカスタマイズToggle
//AkarsのアイコンでExpand&Reduceを使用
function AccordionToggleIcon({
  // children,
  eventKey,
  callback,
  type,
  setSelectedRoute,
}: AccordionToggleIconProps) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <>
      <div
        className="titlecustom"
        style={{
          padding: "5px",
          color: type === "plan" ? "#a10202" : "#0252A1",
          fontSize: "18px",
          display: "flex",
        }}
      >
        {isCurrentEventKey ? (
          <div onClick={decoratedOnClick} style={{ margin: "auto 0px" }}>
            <ReduceIcon style={{ margin: "0px 10px", cursor: "pointer" }} />
          </div>
        ) : (
          <div onClick={decoratedOnClick} style={{ margin: "auto 0px" }}>
            <ExpandIcon style={{ margin: "0px 10px", cursor: "pointer" }} />
          </div>
        )}
        <div style={{ padding: "0px 5px" }}>
          {type === "plan" ? "Plan DETAILS" : "Result DETAILS"}
        </div>

        <div
          style={{ margin: "auto 0px", cursor: "pointer" }}
          onClick={() => {
            setSelectedRoute({});
          }}
        >
          <CircleXIconLarge />
        </div>
      </div>
    </>
  );
}

interface PathInfoPaneProps {
  type: string;
  bodyComponent: React.ReactNode;
  setSelectedRoute: (selectedRoute: Plan | Result | {}) => void;
}

function PathInfoPane({
  type,
  bodyComponent,
  setSelectedRoute,
}: PathInfoPaneProps) {
  return (
    <Accordion defaultActiveKey="0" style={{ maxWidth: "300px" }}>
      <Card>
        <Card.Header style={{ padding: "5px" }}>
          <AccordionToggleIcon
            eventKey="0"
            type={type}
            setSelectedRoute={setSelectedRoute}
          />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body style={{ paddingBottom: "2px", paddingTop: "2px" }}>
            {bodyComponent}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
