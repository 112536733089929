import React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Plan, Result } from "../../../types";
import { roundDistance } from "../roundDistance";

interface MileagesPaneProps {
  planRoutes: Plan[];
  resultRoutes: Result[];
}

export const MileagesPane = ({
  planRoutes,
  resultRoutes,
}: MileagesPaneProps) => {
  //ルートの総距離を算出する
  function return_distance_all(routes: Plan[] | Result[]) {
    let distance = 0;
    routes.forEach((r: Plan | Result) => {
      if (r?.distance) {
        distance += r.distance;
      }
    });
    const roundedDistance = roundDistance(distance); //m未満四捨五入&桁数調整
    return roundedDistance;
  }

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Card style={{ height: "auto", width: "20vw" }}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="titlecustom"
            style={{
              padding: "5px",
              color: "#585858",
              fontSize: "18px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Mileages
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body style={{ padding: "5px", margin: "5px" }}>
              <Card.Text>
                <MileageRow
                  type="plan"
                  distance={return_distance_all(planRoutes)}
                />
                <MileageRow
                  type="results"
                  distance={return_distance_all(resultRoutes)}
                />
              </Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

interface MileageRowProps {
  type: string;
  distance: string | number;
}

const MileageRow = ({ type, distance }: MileageRowProps) => {
  const fontColor = type === "plan" ? "#a10202" : "#0252A1";
  const title = type === "plan" ? "Plan" : "Result";
  return (
    <>
      <Row style={{ margin: "2px" }}>
        <Col
          sm={5}
          className="titlecustom"
          style={{
            color: fontColor,
            padding: "0px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          {title}
        </Col>
        <Col sm={7}>
          <div style={{ textAlign: "right", fontSize: "15px" }}>
            {distance === undefined ? "---" : `${distance}`}
            km
          </div>
        </Col>
      </Row>
    </>
  );
};
