import React from "react";
import { Button, Modal, Form } from "react-bootstrap";

export function ModalMessage({
    messageModalVisible,
    messageModalOKClick,
    messageModalMessage
  }) {
    return (
      <>
        <Modal
            backdrop ='static'
            keyboard = {false}
            show={messageModalVisible}
            onHide={messageModalOKClick}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
            <Modal.Title className="titlecustom">
                Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#EDEDED", fontSize: "large"  }}>
            <pre>
              {messageModalMessage}
            </pre>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
            <Button variant="dark" onClick={messageModalOKClick}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }