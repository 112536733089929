//距離の表示桁数などを整える関数
//--meter未満四捨五入&kmに変換&小数点3桁までは0表示
export function roundDistance(distance_m) {
  const round = Math.round(distance_m); //1m以下は四捨五入する
  const thirdDecimalkm = m2km(round); //kmに変換して小数点3桁までは0表示する
  //console.log({ distance_m, round, thirdDecimalkm });
  return thirdDecimalkm;
}

function m2km(roundedDistance_m) {
  //kmにして小数点以下3桁までは0表示する
  return (roundedDistance_m / 1000).toFixed(3);
}
