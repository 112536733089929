import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, useParams } from "react-router-dom";
import { nextPointId, useCurrentRoute } from "../../../hooks/useCurrentPlan";
import { useGoogleMap } from "../../../hooks/useGoogleMap";
import { useMap } from "../../../hooks/useMap";
import { useUser } from "../../../hooks/useUser";
import { BreadcrumbBar } from "../../BreadcrumbBar";
import { getPlan } from "../getPlan";
import { getPlans } from "../getPlans";
import { MapSearchBox } from "../MapSearchBox";
import { Marker } from "../Marker";
import { NaviPane } from "../NaviPane";
import { PlanPointDetail } from "../PlanPointDetail";
import { PlanPointList } from "../PlanPointList";
import style from "../Route.module.css";
import { judgeCreatedNewPlan } from "./judgeCreatedNewPlan";
import { kiwiApiVehicles } from "../../../api/kiwiApi/kiwiApis";

const API_KEY = process.env["REACT_APP_MAP_API_KEY"];

const initialConfig = {
  center: { lat: 35.6432027, lng: 139.6729435 },
  zoom: 9,
  mapTypeControl: true,
};

export const PlanPage = () => {
  const mode = "plan";
  const googleMap = useGoogleMap(API_KEY);
  const mapContainerRef = useRef(null);
  const mapSearchBoxRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  /** @type {{fleetid: string, vecid: string}}  */
  const fleetId = params.fleetid;
  const carId = params.vecid;
  const {
    route,
    setRoute,
    maximumPointsAlert,
    setMaximumPointAlert,
    setName,
    setDistanceTimePath,
    setDistanceTimePathWaypoints,
    setRouteComment,
    addPoint,
    addPoints,
    updatePointLatLng,
    // updatePointType,
    updatePointComment,
    deletePoint,
    updatePointsOrder,
    setPointsDistance,
  } = useCurrentRoute();
  const { user } = useUser();

  const pointCommentArea = useRef(null);
  const [isPointCommentOpened, setIsPointCommentOpened] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);
  const [getPlanStatus, setGetPlanStatus] = useState(false);
  const [routeOptimization, setRouteOptimization] = useState(true);
  /** @type {import("../../../hooks/useCurrentPlan").Route[]} */
  const [plans, setPlans] = useState(null);

  //router
  useEffect(()=>{
    (async()=>{
      const fleetid = params.fleetid;
      const fleetName = params.fleetName;
      const rawVecsList = await kiwiApiVehicles(user.token, fleetid)
      if(rawVecsList == "error"){
        history.push(`/Fleets`);
        return
      }
      const authority = rawVecsList.authority
      if (typeof authority == "string"){
        if (authority != "admin"){
          history.push(`/${fleetName}/${fleetid}/Vecs`);
        } 
      }else{
        if(!authority.includes("Plan")){
          history.push(`/${fleetName}/${fleetid}/Vecs`);
        }
      }
    })();
  },[])
  //router

  useEffect(() => {
    getPlans({
      fleetId,
      carId,
      setPlans,
      user,
      setGetPlanStatus,
    });
  }, [fleetId, user, carId]);
  useEffect(() => {
    if (plans === null) {
      return;
    }

    if (plans.length !== 0) {
      const setPlanId = judgeCreatedNewPlan(plans);
      const planId = setPlanId.plan_id;
      getPlan({
        planId,
        user,
        nextPointId,
        setRoute,
        setGetPlanStatus,
        map,
      });
    } else {
      //routeが1つもない場合はundefinedをsetする
      setRoute(undefined);
      setGetPlanStatus(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const map = useMap({
    googleMap,
    mapContainerRef,
    initialConfig,
    addPoint,
    mapSearchBoxRef,
    route,
  });
  //方向付でpath表示するためのObject
  const polylineObject = useRef(null);

  useEffect(() => {
    if (map === null || route === undefined) {
      return;
    } else if (route.path_geojson === undefined) {
      polylineObject.current = null;
    } else {
      const coods = route.path_geojson.geometry.coordinates.map((c) => {
        return { lat: c[1], lng: c[0] };
      });
      const lineSymbol = {
        path: googleMap.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeColor: "#A10202",
        scale: 4,
        strokeWeight: 3,
      };
      const line = new googleMap.maps.Polyline({
        path: coods,
        strokeColor: "#A10202",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        icons: [
          {
            icon: lineSymbol,
            offset: "100%",
            repeat: "100px",
          },
        ],
      });
      polylineObject.current = line;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, route]);

  useEffect(() => {
    if (polylineObject.current === null) {
      return;
    } else {
      polylineObject.current.setMap(map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polylineObject.current, route]);

  useEffect(() => {
    return () => {
      if (polylineObject.current) {
        polylineObject.current.setMap(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  if (plans === null || route === null) {
    // return <p>Loading...</p>;
  }
  if (map !== null) {
  }

  return (
    <>
      <BreadcrumbBar params={params} />
      <div style={{ position: "relative" }}>
        <LoadingOverlay
          active={getPlanStatus}
          spinner
          text="Loading your content..."
        >
          <Container fluid>
            {/* Header/BreadcrumbBarの高さの合計が104.8px */}
            <Row
              style={{
                height: "calc(100vh - 104.8px)",
                position: "relative",
              }}
            >
              <Col sm={2} className={style.routePageCol}>
                <NaviPane
                  fleetId={params.fleetid}
                  vecId={params.vecid}
                  plans={plans}
                  setPlans={setPlans}
                  route={route}
                  setRoute={setRoute}
                  setName={setName}
                  setRouteComment={setRouteComment}
                  map={map}
                  googleMap={googleMap}
                  setGetPlanStatus={setGetPlanStatus}
                  mode={mode}
                  addPoints={addPoints} //evalPointの一括PIN置換に使う
                />
              </Col>
              <Col sm={2} className={style.routePageCol}>
                <PlanPointList
                  map={map}
                  route={route}
                  routePoints={Boolean(route) ? route.points : null}
                  updatePointsOrder={updatePointsOrder}
                  deletePoint={deletePoint}
                  currentPoint={currentPoint}
                  setCurrentPoint={setCurrentPoint}
                  setIsPointCommentOpened={setIsPointCommentOpened}
                  maximumPointsAlert={maximumPointsAlert}
                  setMaximumPointAlert={setMaximumPointAlert}
                  mode={mode}
                />
              </Col>
              <Col sm={8} className={style.routePageCol}>
                <>
                  <div
                    style={{
                      height: "100%",
                      position: "relative",
                    }}
                    ref={mapContainerRef}
                  >
                    {map === null ||
                    googleMap === null ||
                    route === undefined ||
                    route.points === undefined
                      ? null
                      : route.points.map((point, index) => (
                          <Marker
                            key={point.id}
                            lat={point.lat}
                            lng={point.lng}
                            type={point.type}
                            label={(index + 1).toString()}
                            comment={point.comment}
                            //currentPointはnullのこともある
                            isSelected={
                              Boolean(currentPoint) &&
                              currentPoint.id === point.id
                            }
                            googleMap={googleMap}
                            map={map}
                            onClick={(e) => {
                              setCurrentPoint(point);

                              setIsPointCommentOpened(true);
                            }}
                            onDragEnd={(e) => {
                              setIsPointCommentOpened(false);
                              setCurrentPoint(null);
                              updatePointLatLng(
                                point.id,
                                e.latLng.lat(),
                                e.latLng.lng()
                              );
                            }}
                          ></Marker>
                        ))}
                  </div>
                  <div ref={mapSearchBoxRef} style={{ margin: "10px" }}>
                    {route === undefined ||
                    route === null ||
                    route.points === undefined ? null : (
                      <MapSearchBox
                        googleMap={googleMap}
                        setRoute = {setRoute}
                        map={map}
                        points={route.points}
                        setDistanceTimePath={setDistanceTimePath}
                        updatePointsOrder={updatePointsOrder}
                        setDistanceTimePathWaypoints={
                          setDistanceTimePathWaypoints
                        }
                        routeOptimization={routeOptimization}
                        setRouteOptimization={setRouteOptimization}
                        mode={mode}
                        addPoint={addPoint}
                        setPointDistance={setPointsDistance}
                      />
                    )}
                  </div>
                  <div
                    ref={pointCommentArea}
                    style={{
                      position: "fixed",
                      bottom: "0",
                      width: "calc(800vw / 12)",
                      right: "0",
                      margin: 0,
                    }}
                  >
                    <PlanPointDetail
                      isPointCommentOpened={isPointCommentOpened}
                      setIsPointCommentOpened={setIsPointCommentOpened}
                      currentPoint={currentPoint}
                      setCurrentPoint={setCurrentPoint}
                      setComment={updatePointComment}
                      mode={mode}
                    />
                  </div>
                </>
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
        {/* <div>
          <input type="text" id="urlinput" />
          <button onClick={() => returnPointsInURL(googleMap, map, addPoints)}>
            import
          </button>
        </div> */}
      </div>
    </>
  );
};
