import { Storage } from "aws-amplify";

export function getCoverageData({ objectKey, setHdmapData, setToggleButton }) {
  Storage.get(objectKey).then((preSignedURL) => {
    fetch(preSignedURL)
      .then((response) => response.json())
      .then((data) => {
        setHdmapData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  });
}
