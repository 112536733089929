import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Card, Col, Row, Form, ListGroup } from "react-bootstrap";
import { HdmapCoverageButton } from "../hdmap/HdmapCoverage";
import { NotTestedButton } from "../hdmap/NotTestedSegments";
import { CircleXIcon, PinButtonIcon } from "../icons";
import { InfoIcon } from "../infoIcon";
import { exportExcelasJson } from "./exportGeoJsonHighwaySection";
import { createPointGeoJsonKML, returnPointsFromKML } from "./kmlParser";
import { useUser } from "../../../hooks/useUser";

import {
  evalPointStyleRandom,
  lineStyle_jsonPath_hover,
  lineStyle_jsonPath_normal,
} from "./lineStylesEvalPoints";
import { pathInfoWindow } from "./pathInfoWindow";
import { createPointGeoJson } from "./pointcsvParser";
import { pointInfoWindow } from "./pointInfoWindow";
import { randomColor } from "./randomColor";

import { ModalPOIDBRegisterMain } from "./poidb/ModalPOIDBRegisterMain";
import { ModalPOIDBDelete } from "./poidb/ModalPOIDBDelete";
import { ModalPOIDBCheck } from "./poidb/ModalPOIDBCheck";
import { ModalPOIDBAddMain } from "./poidb/ModalPOIDBAdd";
import { ModalPOIDBFilterOptionMain } from "./poidb/ModalPOIDBFilterOption";
import { kiwiApi } from "../kiwiApi";
import Amplify, { Storage } from "aws-amplify";
import awsConf from "../../../awsConf";
import * as uuid from 'uuid'
//새로 임포트 한것은 이것뿐
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import _ from "lodash"

const awsConfStrage = {
  ...awsConf,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_REGION, //OPTIONAL -  Amazon service region
    },
  },
};

Amplify.configure(awsConfStrage);

/**
 * 評価ポイントcsv読み込みなどするコンポーネント
 * @param {google.maps.Map} map
 * @param {google} googleMap
 */
export function EvalPointsDetail({ map, googleMap, addPoints, fleetId }) {
  const { user } = useUser();
  const refSelectPOIDB = useRef(null);
  const poidbRegisterFileNameRef = useRef(null);
  const inputPassword = useRef(null);
  const [poidbInputFile, setPoidbInputFile] =useState(null);
  const [poidbCheckStringRef, setPoidbCheckStringRef] = useState("loading...");
  const [poidbRegisteredList, setPoidbRegisteredList] = useState([]);
  const [poidbAddSelectRef, setPoidbAddSelectRef] = useState("");
  const [poidbSelectedId, setPoidbSelectedId] = useState("");
  const [poidbDeleteId, setPoidbDeleteId] = useState("");
  const [poidbCheckedIdContent, setPoidbCheckedIdContent] = useState([]);
  const [poidbFilterOptionList, setPoidbFilterOptionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [poidbRegisterMainModalShow, setPoidbRegisterMainModalShow] = useState(false);
  const [poidbDeleteModalShow, setPoidbDeleteModalShow] = useState(false);
  const [poidbCheckModalShow, setPoidbCheckModalShow] = useState(false);
  const [poidbAddModalShow, setPoidbAddModalShow] = useState(false);
  const [poidbFilterMainModalShow, setPoidbFilterMainModalShow] = useState(false);
  const [poidbAllCluster, setPoidbAllCluster] = useState([])
  const [clusterListeners, setClusterListeners] = useState([])
  const [filesInfo, setFilesInfo] = useState([]);
  const [a,b] = useState(null)

  const registerPassword = "poidb-register-5523"
  const deletePassword = "poidb-delete-5523"
  const [poidbWorkSpaceList, setPoidbWorkSpaceList] = useState(()=>{
    //localStorage.removeItem("poiData")
    const saved = localStorage.getItem("poiData");
    
    if(saved != null){
      return JSON.parse(saved);
    }else{
      return []
    }
  });
  const [poidbFOContentsChecker, setPoidbFOContentsChecker] = useState(null);
  /*filesInfoは下記objectの配列(filesの要素例)
  {
    lastModified: 1603870196117,
    lastModifiedDate: Wed Oct 28 2020 16:29:56 GMT+0900 (日本標準時) {},
    name: "9-陸前高田長部IC-唐桑町只越IC (1).json",
    size: 26492,
    type: "application/json",
    webkitRelativePath: ""
    visible: Boolean(defaultはTrue)
  }
  File名で認識しているので拡張子含めて全く同じファイル名だと連動してしまう。注意。
  */

  useEffect(()=>{
    (async () => {
      const poiListData = await kiwiApi(user.token).getPois()
      poiListData.sort(function(a,b) {
        if(a.title > b.title) return 1
        else if(a.title == b.title) return 0
        else if(a.title < b.title) return -1
      })
      setPoidbRegisteredList(poiListData)
    })();
  },[poidbCheckStringRef])

  const sampleRef = useRef(null);
  useEffect(() => {
    if (map === null || map === undefined || filesInfo.length === 0) {
      return;
    }
    //fileaInfoのVisibleに伴ってmap上の表示・非表示を切り替える
    filesInfo.forEach((file) => {
      const { name, visible } = file;
      // console.log(name);
      map.data.forEach((feature) => {
        if (feature.getProperty("fileName") === name) {
          map.data.overrideStyle(feature, {
            visible,
          });
        }
      });
    });
  }, [filesInfo, map]);

  //Hdmap情報Excelを読み込んで区間をGeoJsonにして出力
  function exportGeoJsonHighwaySection(e) {
    const file = e.target.files[0];
    var reader = new FileReader();

    reader.onload = function (e) {
      exportExcelasJson(e, googleMap);
    };
    reader.readAsArrayBuffer(file);
  }

  //fileを読み込んでaddGeoJsonする関数
  function drawingFilesData(e) {
    const files = e.target.files;
    const importFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = e.target.files[i];
      file.visible = true;
      const pointColor = randomColor();
      file.pointColor = pointColor;
      importFiles.push(file);

      const fileName = file.name;
      const fileExtension = file.name.split(".")[1];
      const reader = new FileReader();

      reader.onload = function (e) {
        if (file.type === "application/json") {
          // pathのjsonファイル
          const pathGeoJson = JSON.parse(reader.result);
          pathGeoJson.id = `importedFromJSON${fileName}`;
          pathGeoJson.properties.fileName = fileName;
          map.data.addGeoJson(pathGeoJson);
        } else if (fileExtension === "csv") {
          // csvの場合
          const pointsGeoJson = createPointGeoJson(fileName, reader.result);
          //idを追加
          const _addFeatureID = pointsGeoJson.features.map((feature) => {
            const id = `importedFromCSV${fileName}${feature.geometry.coordinates[0]}${feature.geometry.coordinates[1]}`;
            return { ...feature, id };
          });
          pointsGeoJson.features = _addFeatureID;
          map.data.addGeoJson(pointsGeoJson);
        } else if (fileExtension === "kml") {
          //kmlの場合
          const fileName = file.name;
          const kmlStr = reader.result;
          const kmlPoints = returnPointsFromKML({ kmlStr, fileName });
          const geoJson = createPointGeoJsonKML(kmlPoints);
          //idを追加
          const _addFeatureID = geoJson.features.map((feature) => {
            const id = `importedFromKML${fileName}${feature.geometry.coordinates[0]}${feature.geometry.coordinates[1]}`;
            return { ...feature, id };
          });
          geoJson.features = _addFeatureID;
          map.data.addGeoJson(geoJson);
        } else {
          console.warn("対応していないfile形式");
        }

        //線のスタイルなどを変更する

        //lineのgeojsonの場合のスタイル変更
        if (fileExtension === "json") {
          const feature = map.data.getFeatureById(
            `importedFromJSON${fileName}`
          );
          // console.log(feature);
          const featureGeoType = feature.getGeometry().getType();
          // console.log(featureGeoType);
          if (featureGeoType === "MultiLineString" || "LineString") {
            map.data.overrideStyle(feature, {
              strokeColor: pointColor,
              strokeWeight: "5",
            });
          }
        }

        //csvやkmlに書かれたpointの場合のスタイル変更
        //各ポイント1つ1つをpointのgeojsonでaddFeatureしているため、forEachで回している
        //各ポイントに吹き出しを付けるために、1つずつpointで定義して描画している
        map.data.forEach((feature) => {
          const featureId = feature.getId();
          const pathNo = feature.getProperty("pathNo");
          //const type = feature.getProperty("type");

          const featureIdStartsWith =
            fileExtension === "csv"
              ? `importedFromCSV${fileName}`
              : fileExtension === "kml"
              ? `importedFromKML${fileName}`
              : `none`;

          if (featureId.startsWith(featureIdStartsWith)) {
            map.data.overrideStyle(
              feature,
              evalPointStyleRandom(googleMap, pointColor)
            );
          }

          if (pathNo !== undefined) {
            map.data.overrideStyle(feature, lineStyle_jsonPath_normal);
          }
          // } else if (type === "csvPoint" || type === "kmlPoint") {
          //   //map.data.overrideStyle(feature, evalPointStyle(googleMap));
          // }
          //mouseover時の挙動
          map.data.addListener("mouseover", function (event) {
            // const featureType = event.feature.getGeometry().getType();
            //propertyがあるもののみスタイル変更
            if (event.feature.getProperty("pathNo") !== undefined) {
              if (!event.feature.getProperty("isHover")) {
                //hover一回目だけ表示にする
                const pathInfowindow = pathInfoWindow(googleMap, event);
                pathInfowindow.addListener("closeclick", function () {
                  event.feature.setProperty("isHover", false);
                });
                pathInfowindow.open(map);

                event.feature.setProperty("isHover", true);
              }
              map.data.overrideStyle(event.feature, lineStyle_jsonPath_hover);
            }
          });
          //mouseout時の挙動
          map.data.addListener("mouseout", function (event) {
            // map.data.revertStyle();
            if (event.feature.getProperty("pathNo") !== undefined) {
              map.data.overrideStyle(event.feature, lineStyle_jsonPath_normal);
            }
          });
          //click時の挙動
          map.data.addListener("click", function (event) {
            const type = event.feature.getProperty("type");
            //const featureProperties = event.feature.getProperty("caseNo");
            //console.log(featureProperties);
            if (type === "csvPoint" || type === "kmlPoint") {
              const isOpend = event.feature.getProperty("isOpend");
              if (!isOpend) {
                event.feature.setProperty("isOpend", true);
                const pointInfo = pointInfoWindow(googleMap, event);
                pointInfo.addListener("closeclick", function () {
                  event.feature.setProperty("isOpend", false);
                });
                pointInfo.open(map);
              }
            }
          });
        });
      };
      reader.readAsText(file);
    }
    const newFiles = filesInfo.concat(importFiles);
    setFilesInfo(newFiles);

    //console.log(sampleRef.current.value);
    //ファイルinputの履歴をリセットする
    sampleRef.current.value = null;
  }
  //指定されたFileのLatLngをもとにPINを追加する
  function convertFilePoints2PINs(selectedFileName, addPoints) {
    //console.log(selectedFileName);
    const latLngPoints = [];
    map.data.forEach((feature) => {
      const fileName = feature.getProperty("fileName");
      if (fileName === selectedFileName) {
        const commentOfPoint = feature.getProperty("comment");
        feature.getGeometry().forEachLatLng((each) => {
          //console.log(each);
          const lat = each.lat();
          const lng = each.lng();
          //console.log(commentOfPoint);
          //commentがない場合はimportファイル名のみをコメントに追加
          const comment = `${
            (commentOfPoint === undefined) | (commentOfPoint === "")
              ? ""
              : commentOfPoint
          }`;
          //commentを追加すればコメント付PINをaddできる!!
          latLngPoints.push({ lat, lng, comment });
        });

        //latLngPoints.push(feature);
        //console.log(latLngPoints);
      }
    });
    //console.log(latLngPoints);
    //あとはここからaddPointsをすればOK。
    addPoints(latLngPoints);
  }

  function changeFileVisible(e) {
    //該当ファイルのvisibleを変更する
    const fileName = e.target.id;
    const newfilesInfo = filesInfo.map((file) => {
      if (file.name === fileName) {
        //console.log(fileName);
        file.visible = !file.visible;
        return file;
      } else {
        return file;
      }
    });

    setFilesInfo(newfilesInfo);
  }
  function removeFile(fileName) {
    //console.log("remove", fileName);
    const removeFileInfo = filesInfo.filter((f) => f.name !== fileName);
    // console.log(removeFileInfo);
    setFilesInfo(removeFileInfo);
    map.data.forEach((feature) => {
      if (feature.getProperty("fileName") === fileName) {
        map.data.remove(feature);
      }
    });
  }

  //POIDB Register
  async function poidbRegisterButtonClick(){
    const input =inputPassword.current.value

    if(input != registerPassword){
      setPoidbCheckStringRef("Wrong Password")
      setPoidbCheckModalShow(true)
      return
    }
    
    setIsLoading(true)

    function openChecker(message){
      setIsLoading(false)
      setPoidbRegisterMainModalShow(false);
      setPoidbFilterOptionList([])
      setPoidbInputFile(null)
      setPoidbCheckStringRef(message)
      setPoidbCheckModalShow(true);
    }

    const file = poidbInputFile
    if(file == null){
      setIsLoading(false)
      setPoidbCheckStringRef("[ERROR]No file Selected")
      setPoidbCheckModalShow(true);
      return
    }
    if(poidbRegisteredList.filter(item => item.title == poidbRegisterFileNameRef.current.value).length != 0){
      setIsLoading(false)
      setPoidbCheckStringRef("[ERROR]Same Name can't registration")
      setPoidbCheckModalShow(true);
      return
    }

    //console.log(file.name)
    const uuidName = uuid.v4()
    try{
      const result = await Storage.put("pois/"+uuidName,file,{
        contentType: "text/csv"
      })
      .catch(res =>
        res.json().then(text => {throw text["message"]})
      )
    }catch(error){
      console.log("fail to upload:",error)
      openChecker(`[ERROR] Fail Upload file: ${error}`)
      return
    }
    try{
      const postResult = await kiwiApi(user.token).createPoi({
        newPoi: {s3_filename: uuidName, title: poidbRegisterFileNameRef.current.value},
      })
      .catch(res =>
        res.json().then(text => {throw text["message"]})
      )
    }catch(error){
      openChecker(`[ERROR] Fail Register: ${error}`)
      return
    }
    openChecker("Register Success!")
  }

  function poidbRegisterCancelButtonClick(){
    setPoidbRegisterMainModalShow(false);
    setPoidbFilterOptionList([])
    setPoidbInputFile(null)
  }

  function poidbFileSelect(e) {
    setPoidbFilterOptionList([])

    const file = e.target.files[0];
    //console.log(file.name)

    setPoidbInputFile(file)

    //File Read(컬럼 속성 읽고 표시하는 부분)
    // const csvFileReader = new FileReader();
    // setIsLoading(true)
    // setPoidbFilterOptionList(["Loading..."])

    // csvFileReader.readAsText(file)
    // csvFileReader.onload = function (e) {
    //   const lines = csvFileReader.result.split(/\n(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/)
      
    //   lines.pop()
    //   let tempColumns = lines.shift().split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/)

    //   let columnItems=lines.shift().split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/)
    //   for(let i=0; i<tempColumns.length; i++){
    //     columnItems[i]=columnItems[i].replace(/\n/g, " ").replace(/\r/g, "")
    //     tempColumns[i]=tempColumns[i].replace(/\n/g, " ").replace(/\r/g, "")
    //   }
    //   const columns = tempColumns
    
    //   lines.map(e =>{
    //     const lineSplit =e.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/)
          
    //       for(let i=0; i<columns.length; i++){
    //         columnItems[i]=columnItems[i]+","+lineSplit[i].replace(/\n/g, " ").replace(/\r/g, "")
    //       }
  
    //   })

    //   let filterOptionList =[]
    //   for(let i=0; i<columns.length; i++){
    //     let columnItemSet=new Set(columnItems[i].split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/))
    //     if(columnItemSet.size <= 100){
    //       filterOptionList.push(columns[i])
    //     }
    //   }
    //   setIsLoading(false)
    //   setPoidbFilterOptionList(filterOptionList)
    // }
    

    poidbRegisterFileNameRef.current.value=file.name.split(".csv")[0]
  }

  //POIDB Delete
  function poidbDeleteModalCancelClick(){
    setPoidbDeleteModalShow(false)
  }

  function poidbDeleteListChange(e){
    const deleteID = e.target.value
    setPoidbDeleteId(deleteID)
  }

  async function poidbDeleteModalDeleteClick(){
    const input =inputPassword.current.value

    if(input != deletePassword){
      setPoidbCheckStringRef("Wrong Password")
      setPoidbCheckModalShow(true)
      return
    }
    if(poidbDeleteId == ""){
      setPoidbCheckStringRef("Please Select Item")
      setPoidbCheckModalShow(true)
      return
    }
    const deleteResponse = await kiwiApi(user.token).deletePoi({poiId: poidbDeleteId})

    const dataList = poidbWorkSpaceList.filter(data=>data.poi_id != poidbDeleteId)
    const adjustArray = poidbCheckedIdContent.filter(data=>data.poi_id != poidbDeleteId)
    setPoidbCheckedIdContent(adjustArray)
    localStorage.setItem("poiData",JSON.stringify(dataList))
    setPoidbWorkSpaceList(JSON.parse(localStorage.getItem("poiData")))

    setPoidbDeleteModalShow(false)
    setPoidbCheckStringRef("Delete Complete")
    setPoidbCheckModalShow(true)
  }

  //POIDB Check
  function poidbCheckOKButtonClick(){
    setPoidbCheckModalShow(false);
    setPoidbCheckStringRef("...Loading")
  }

  //POIDB Add
  function poidbAddSelectItem(e){
    if(e.target.options[e.target.selectedIndex].text == "-- Select --"){return}
    setPoidbAddSelectRef(e.target.options[e.target.selectedIndex].text)

    let count = 0
    for(let i=0;i<poidbWorkSpaceList.length;i++){
      if(poidbWorkSpaceList[i].poi_id == e.target.value){
        count++
      }
    }

    if(count ==0){
      setPoidbSelectedId(e.target.value)
      setPoidbAddModalShow(true)
    }else{
      refSelectPOIDB.current.value=null
      setPoidbCheckStringRef("You already have this item in WorkSpace")
      setPoidbCheckModalShow(true)
    }
    count=0
  }

  async function poidbAddButtonClick(){
    refSelectPOIDB.current.value=null

    const selectedPoiData = await kiwiApi(user.token).getPoi({poiId: poidbSelectedId})
    let keysList = Object.keys(selectedPoiData.filtered_columns)
    keysList.sort()
    const itemColor = randomColor()

    for(let i =0; i<keysList.length; i++){
      selectedPoiData.color = itemColor
      const contents = selectedPoiData.filtered_columns[keysList[i]]
      contents.sort()
      delete selectedPoiData.filtered_columns[keysList[i]]
      selectedPoiData.filtered_columns[keysList[i]]={checked : contents, options : contents}
    }
    localStorage.setItem("poiData",JSON.stringify([...poidbWorkSpaceList,selectedPoiData]))
    setPoidbWorkSpaceList(JSON.parse(localStorage.getItem("poiData")))

    setPoidbAddModalShow(false);
    setPoidbSelectedId("")
  }

  function poidbAddCancelButtonClick(){
    refSelectPOIDB.current.value=null
    setPoidbSelectedId("")

    setPoidbAddModalShow(false);
  }

  //POIDB WorkSpace
  function poidbWorkSpaceCheckboxClick(e){
    const targetId = e.target.id
    const isChecked = e.target.checked
    
    const targetContent = poidbWorkSpaceList.filter(data => data.poi_id == targetId)

    if (isChecked){
      setPoidbCheckedIdContent([...poidbCheckedIdContent, targetContent[0]])
    }else{
      const adjustArray = poidbCheckedIdContent.filter(data=>data.poi_id != targetId)
      setPoidbCheckedIdContent(adjustArray)
    }
  }

  function poidbWorkSpaceDeleteButtonClick(e){
    const deleteID = e.target.id
    const dataList = poidbWorkSpaceList.filter(data=>data.poi_id != deleteID)
    const adjustArray = poidbCheckedIdContent.filter(data=>data.poi_id != deleteID)
    setPoidbCheckedIdContent(adjustArray)

    localStorage.setItem("poiData",JSON.stringify(dataList))
    setPoidbWorkSpaceList(JSON.parse(localStorage.getItem("poiData")))
  }

  //POIDB FilterOption
  function poidbFilterOptionButtonClick(){
    let tempArray= new Array()
    for(let i=0; i<poidbWorkSpaceList.length; i++){
      let tempObject = new Object()
      const id = `${poidbWorkSpaceList[i].title}`
      const filterOptions = Object.keys(poidbWorkSpaceList[i].filtered_columns)
      tempObject[id]={
        checked:false,
        columns: new Object()
      }
      for(let j=0; j<filterOptions.length; j++){
        tempObject[id].columns[filterOptions[j]]={
          checked:false,
          allChecked:true,
          option: new Object()
        }
        const optionList = poidbWorkSpaceList[i].filtered_columns[filterOptions[j]].options
        const optionChecked = poidbWorkSpaceList[i].filtered_columns[filterOptions[j]].checked
        for(let k=0; k<optionList.length; k++){
          const checker = optionChecked.filter(item => item == optionList[k])
          if(checker.length == 0){
            tempObject[id].columns[filterOptions[j]].option[optionList[k]]={checked:false}
            tempObject[id].columns[filterOptions[j]].allChecked=false
          }else{
            tempObject[id].columns[filterOptions[j]].option[optionList[k]]={checked:true}
          }
        }
      }
      tempArray.push(tempObject)
    }
    setPoidbFOContentsChecker(tempArray)
    setPoidbFilterMainModalShow(true)
  }

  function poidbFilterMainOKButtonClick(){
    setPoidbWorkSpaceList((before)=>{
      const contents = [...before]
      contents.map(before =>{
        const content =before
        const filterOptions = Object.keys(content.filtered_columns)
        const checker = poidbFOContentsChecker.filter(item => Object.keys(item)[0] == content.title)[0]
        for(let i=0; i<filterOptions.length; i++){
          let optionList =content.filtered_columns[filterOptions[i]].options
          const checkedList = []
          for(let j=0; j<optionList.length; j++){
            if(checker[content.title].columns[filterOptions[i]].option[optionList[j]].checked){
              checkedList.push(optionList[j])
            }
          }
          content.filtered_columns[filterOptions[i]].checked = checkedList
        }
        return content
      })
      localStorage.setItem("poiData",JSON.stringify(contents))
      return contents
    })
    setPoidbFilterMainModalShow(false);
  }

  //POIDB Read
  function clusterClearer(){
    for(let i=0; i<poidbAllCluster.length; i++){
      poidbAllCluster[i].clearMarkers()
    }
    for(let i =0; i<clusterListeners.length; i++){
      googleMap.maps.event.removeListener(clusterListeners[i])
    }
    // googleMap.maps.event.clearInstanceListeners(map);
    // googleMap.maps.event.clearListeners(map, 'zoom_changed');
    // googleMap.maps.event.clearListeners(map, 'dragend');
  }

  function pointFilterer(content, allPoints){
    const filterOptionsList = Object.keys(content.filtered_columns)
    let filteredPoints =allPoints
    for(let f=0; f<filterOptionsList.length; f++){
        const checkedList = content.filtered_columns[filterOptionsList[f]].checked
        filteredPoints = filteredPoints.filter(point => checkedList.includes(point[filterOptionsList[f]])==true)
    }
    return filteredPoints
  }

  async function poidbReadButtonClick(){

    clusterClearer()

    let clusters = []
    let clusterListenersTemp = []
    for(let i=0; i<poidbCheckedIdContent.length; i++){
      const result = await Storage.get("pois/"+poidbCheckedIdContent[i].s3_filename+".json" ,{download: true})
      const allPoints = JSON.parse(await result.Body.text())
      
      const filteredPoints = pointFilterer(poidbCheckedIdContent[i],allPoints)

      let mark
      const markers = filteredPoints.map((point=>{
        const objectKeys = Object.keys(point).filter(key => key!="lat_gps"||key!="lon_gps")
        let tempText =''
        for(let j=0; j<objectKeys.length; j++){
          tempText= tempText + objectKeys[j] + " : " + point[objectKeys[j]] + '<br/>'
        }
        const contentText = tempText
        mark = new googleMap.maps.Marker({
          position: {lat:point.lat_gps,lng:point.lon_gps},
          icon: {
            path: googleMap.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            fillColor: poidbCheckedIdContent[i].color,
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 1.7,
            scale: 5
          }
        })
        // mark.setMap(map)
        mark.addListener("click", (event) => {
          const originalMapCenter = new googleMap.maps.LatLng(point.lat_gps,point.lon_gps);
          const infowindow = new googleMap.maps.InfoWindow({
            content: contentText,
            position: originalMapCenter,
          });
        
          infowindow.open(map);
        });
        return mark
      }))
      
      const clusterOptions = {
        render: function ({ count, position }) {
          const color = poidbCheckedIdContent[i].color;
          const svg=window.btoa(
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
              <defs>
                <g id="a" transform="rotate(45)">
                  <path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.65"/>
                  <path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.4"/>
                </g>
              </defs>
              <g fill="${color}" >
                <circle r="42" stroke="black" stroke-width="2"/>
                <use xlink:href="#a"/>
                <g transform="rotate(120)">
                  <use xlink:href="#a"/>
                </g>
                <g transform="rotate(240)">
                  <use xlink:href="#a"/>
                </g>
              </g>
            </svg>`
          );

          //'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '" stroke="black" stroke-width="3"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>'
          //'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><g fill="' + color + '" stroke="black" stroke-width="3"><circle r="60"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>'

          return new googleMap.maps.Marker({
              position,
              icon: {
                  url: `data:image/svg+xml;base64,${svg}`,
                  scaledSize: new googleMap.maps.Size(75, 75),
              },
              label: {
                  text: String(count),
                  color: "rgba(255,255,255,1)",
                  fontSize: "17px",
              },
              zIndex: Number(googleMap.maps.Marker.MAX_ZINDEX) + count,
          });
        }
      }

      // const markerCluster= new MarkerClusterer({markers, map, renderer:clusterOptions})
      const clusterer = new MarkerClusterer({ map, renderer:clusterOptions});
      const updateCluster = _.debounce(() => {
        const bounds = map.getBounds();
        clusterer.clearMarkers();
        const visibleMarkers = markers.filter((marker) => {
          return marker.getVisible() && bounds.contains(marker.getPosition());
        });
        clusterer.addMarkers(visibleMarkers);
      }, 100);
    
      const clusterListener1 = map.addListener('dragend', updateCluster);
      const clusterListener2 = map.addListener('zoom_changed', updateCluster);
      updateCluster();

      clusters.push(clusterer)
      clusterListenersTemp.push(clusterListener1)
      clusterListenersTemp.push(clusterListener2)
      // clusters.push(markerCluster)
    }
    setPoidbAllCluster(clusters)
    setClusterListeners(clusterListenersTemp)
    // console.log(clusters)
  }

  return (
    <>
      <Card>
        {/* <Card.Header
          className="titlecustom"
          style={{ color: "#585858", fontSize: "18px"}}
        > */}
        <Accordion.Toggle
          as={Card.Header}
          eventKey="1"
          className="titlecustom"
          style={{ color: "#585858", fontSize: "18px" }}
        >
          Points
        </Accordion.Toggle>
        {/* </Card.Header> */}
        <Accordion.Collapse eventKey="1">
          <Card.Body id="sample_card">
            <Form.Group>
              <Form.Label
                className="titlecustom"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Export Json for HDmap JPN
              </Form.Label>
              <InfoIcon message="HDmap整備エリアExcelに記載された区間をJson形式でExportする。読み込み可能ファイル固定(2020.10.28時点)" />
              <Form.File
                // label="HDmap Excel to Json"
                onChange={exportGeoJsonHighwaySection}
                accept=".xlsx"
              />
              {/* </OverlayTrigger> */}
            </Form.Group>
            <hr/>
            <Form.Group>
              <Form.Label
                className="titlecustom"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Import Files
              </Form.Label>
              <InfoIcon message="This is available for a specified csv and kml files." />
              <Form.File
                id="custom-file"
                variant="grey"
                custom
                style={{ margin: 0, padding: 0, fontSize: "8px" }}
              >
                <Form.File.Label>
                  {`files: ${filesInfo.length}`}
                  <Form.File.Input
                    multiple="true"
                    onChange={drawingFilesData}
                    ref={sampleRef}
                  />
                </Form.File.Label>
              </Form.File>
            </Form.Group>
            <Form.Group>
              <Form.Label
                className="titlecustom"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                File Lists
              </Form.Label>
              <ListGroup variant="flush" style={{ fontSize: "12px" }}>
                {filesInfo.map((file, key) => (
                  <ListGroup.Item key={key}>
                    <Form.Check
                      type="checkbox"
                      checked={file.visible}
                      onChange={changeFileVisible}
                      id={file.name}
                      //label={file.name}
                      style={{
                        margin: "5px",
                        //background: `linear-gradient(transparent 90%, ${file.pointColor} 90%)`,
                      }}
                    />
                    <span
                      style={{
                        background: `linear-gradient(transparent 80%, ${file.pointColor} 80%)`,
                      }}
                    >
                      {file.name}
                    </span>
                    <Button
                      size="sm"
                      style={{ fontSize: "5px", margin: "2px" }}
                      variant="red"
                      onClick={() => convertFilePoints2PINs(file.name, addPoints)}
                      title="add Pins to map"
                      disabled={!file.visible}
                    >
                      <PinButtonIcon />
                    </Button>
                    <Button
                      size="sm"
                      style={{ fontSize: "5px", margin: "2px" }}
                      variant="grey"
                      onClick={() => removeFile(file.name)}
                      title="remove file"
                    >
                      <CircleXIcon />
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
            <hr/>
            {/*----POIDB----*/}
            {/* {user.groups.length==0 ?  */}
            <Form.Group visible={false}>
              <Form.Label
                className="titlecustom"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                POI DB
              </Form.Label>
              <InfoIcon message="This is available for a specified csv files." />
              &nbsp;&nbsp;
              <Button
                size="sm"
                style={{ fontSize: "10px", margin: "2px" }}
                onClick={() => setPoidbRegisterMainModalShow(true)}
                variant="dark"
                title="Register" 
              >
                Register
              </Button>
              <Button
                size="sm"
                style={{ fontSize: "10px", margin: "2px" }}
                onClick={() => setPoidbDeleteModalShow(true)}
                variant="outline-dark"
                title="Delete" 
              >
                Delete
              </Button>
              <Form.Control
                as="select"
                onChange={poidbAddSelectItem}
                ref={refSelectPOIDB}
              >
                <option value={""} key={""}> -- Select --</option>
                {poidbRegisteredList.map((e)=>(
                  <option value={e.poi_id} key={e.poi_id}>{e.title}</option>
                ))}

              </Form.Control>
              <ListGroup variant="flush" style={{ fontSize: "11px" }}>
                {poidbWorkSpaceList.length == 0? <>Nothing Saved</>:poidbWorkSpaceList.map((e)=>(
                  <ListGroup.Item key={e.poi_id} style={{padding: "4px 0px"}}>
                    <Form.Check
                      type="checkbox"
                      onChange={poidbWorkSpaceCheckboxClick}
                      id={e.poi_id}
                      label={e.title}
                      style={{
                        display: "flex",
                        float:"left",
                        margin: "5px",
                        background: `linear-gradient(transparent 85%, ${e.color} 80%)`,
                      }}
                    />
                    <Button
                      size="sm"
                      style={{ display: "flex", float:"left", fontSize: "6px", margin: "1px" }}
                      id={e.poi_id}
                      variant="outline-dark"
                      onClick={poidbWorkSpaceDeleteButtonClick}
                      title="remove file"
                    >
                      x
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <Button
                onClick={() => poidbFilterOptionButtonClick()}
                size="sm"
                variant="outline-dark"
                title="Filter-Option" 
              >
                Filter Option
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                onClick={()=>poidbReadButtonClick()}
                size="sm"
                variant="outline-dark"
                title="Read" 
              >
                Plot
              </Button>
              <hr/>
            </Form.Group>
            {/* :<></> */}
            {/* } */}

            <HdmapCoverageButton
              map={map}
              googleMap={googleMap}
              fleetId={fleetId}
            />

            <NotTestedButton map={map} googleMap={googleMap} fleetId={fleetId} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <ModalPOIDBRegisterMain
        poidbMainModalShow={poidbRegisterMainModalShow}
        poidbCancelRegisterClick={poidbRegisterCancelButtonClick}
        poidbRegisterClick={poidbRegisterButtonClick}
        poidbRegisterFile={poidbFileSelect}
        poidbRegisterFileNameRef={poidbRegisterFileNameRef}
        poidbCanFilterOption={poidbFilterOptionList}
        poidbRegisterPassword={inputPassword}
        loadingState={isLoading}
      />
      <ModalPOIDBDelete
        poidbDeleteModalShow={poidbDeleteModalShow}
        poidbDeleteModalCancelClick={poidbDeleteModalCancelClick}
        poidbDeleteModalDeleteClick={poidbDeleteModalDeleteClick}
        poidbDeleteInputPassword={inputPassword}
        poidbDeleteList={poidbRegisteredList}
        poidbDeleteListChange={poidbDeleteListChange}
      />
      <ModalPOIDBCheck
        poidbCheckModalShow={poidbCheckModalShow}
        poidbCheckModalOKClick={poidbCheckOKButtonClick}
        poidbCheckModalContentsRef={poidbCheckStringRef}
      />
      <ModalPOIDBAddMain
        poidbAddMainModalShow={poidbAddModalShow}
        poidbCancelAddClick={poidbAddCancelButtonClick}
        poidbSelectRef={poidbAddSelectRef}
        poidbAddClick={poidbAddButtonClick}
      />
      <ModalPOIDBFilterOptionMain
        poidbFilterMainModalShow={poidbFilterMainModalShow}
        poidbFilterMainOKClick={poidbFilterMainOKButtonClick}
        poidbFOContentsChecker={poidbFOContentsChecker}
        setPoidbFOContentsChecker={setPoidbFOContentsChecker}
      />
    </>
  );
}
