import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useUser } from "../hooks/useUser";

function PrivateRoute(props: RouteProps) {
  const { component, ...rest } = props;
  const { isAuthenticated, status } = useUser({ autoSessionCheck: true });

  if (status === "idle" && !isAuthenticated) {
    return <p>Not signed in yet</p>;
  }

  if (status === "retrieving") {
    return <p>checking session...</p>;
  }

  return (
    <Route
      {...rest}
      render={(innerProps) =>
        isAuthenticated ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: innerProps.location },
            }}
          />
        )
      }
    />
  );
}

export { PrivateRoute };
