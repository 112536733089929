import { kiwiApi } from "../kiwiApi";
import { lineStyle_result_normal } from "./lineStyles";

/**
 * 実績名一覧を取得する
 *
 */
// interface getVecResultsProps {
//   fleetId: string;
//   carId: string;
//   user: User;
//   setResultRoutes: (resultRourte: (Result | ResultMeta)[]) => void;
//   map: any;
//   getPathResults: (Promise<Result> | undefined)[];
// }

export async function getVecResults({
  fleetId,
  carId,
  user,
  setResultRoutes,
  setGetResultStatus,
  map,
}) {
  setGetResultStatus(true);
  kiwiApi(user.token)
    .getResults({
      fleetId,
      carId,
    })
    .then((listResults) => {
      setResultRoutes(listResults);
      //console.debug("getResultsNames", listResults);
      const getPathResults = listResults.map((r) => {
        const resultId = r.result_id;

        return kiwiApi(user.token)
          .getResult({ resultId })
          .then((resultPath) => {
            return resultPath;
          });
      });
      drawResultRoutepath({
        getPathResults,
        map,
        setResultRoutes,
        setGetResultStatus,
      });
    })
    .catch((error) => {
      console.debug("getResultsNames", error);
      setGetResultStatus(false);
    });
}

/**
 *
 * @param {Promise[]} getPathResults
 * @param {google.maps.Map} map
 */

async function drawResultRoutepath({
  getPathResults,
  map,
  setResultRoutes,
  setGetResultStatus,
}) {
  const _results = []; //取得したplanRoutePath結果を格納
  for await (let r of getPathResults) {
    //console.log(r);
    _results.push(r);

    //地図に表示
    if (r.path_geojson !== undefined) {
      const category = "result";
      const comment = r.comment;
      const distance = r.distance;
      const result_id = r.result_id;
      const result_name = r.result_name;
      const time = r.time;

      r.path_geojson.properties = {
        category,
        comment,
        distance,
        time,
        result_id,
        result_name,
      };
      //pathから(0,0)を除外する処理
      //0629追加
      //MapECUkmlではGPSが飛ぶ際に(lng===0 && lat===0)ではなく限りなく0に近い値の場合がある
      //⇒-1 < lat/lon < 1 は除外する処理にする
      const _removeZero = [...r.path_geojson.geometry.coordinates];
      //console.log("pre", _removeZero.length);
      const removeZero = _removeZero.filter((coord) => {
        //return !(coord[0] === 0 && coord[1] === 0);
        return !(
          -1 < coord[0] &&
          coord[0] < 1 &&
          -1 < coord[1] &&
          coord[1] < 1
        );
      });
      r.path_geojson.geometry.coordinates = removeZero;
      //console.log(removeZero.length);
      r.path_geojson.id = result_id; //FeatureIDを追加する
      map.data.addGeoJson(r.path_geojson);
      const thisFeature = map.data.getFeatureById(result_id);
      map.data.overrideStyle(thisFeature, lineStyle_result_normal);
    }
  }
  //(0,0)をスキップしたデータがsetされている
  setResultRoutes(_results);
  setGetResultStatus(false);

  //地図をresultの出発点に移動する
  if (
    _results.length !== 0 &&
    _results[0].path_geojson !== undefined &&
    _results[0].path_geojson.geometry.coordinates !== undefined
  ) {
    const lat = _results[0].path_geojson.geometry.coordinates[0][1];
    const lng = _results[0].path_geojson.geometry.coordinates[0][0];
    map.panTo({ lat, lng });
  }
}
