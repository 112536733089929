import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { UserContextProvider } from "./components";
import { FleetsSelect } from "./components/fleet/FleetsSelect";
import { Header } from "./components/Header";
import { PrivateRoute } from "./components/PrivateRoute";
import { FieldTestPage } from "./components/route/fieldTest/FieldtestPage";
import { PlanPage } from "./components/route/plan/PlanPage";
import { ResultsPage } from "./components/route/results/ResultsPage";
import { SummaryPage } from "./components/route/resultsSummary/SummaryPage";
import { SignInForms } from "./components/user/SignIn";
import { VecSelectList } from "./components/vec/Vec";

function App() {
  return (
    <Router>
      <UserContextProvider>
        <Header />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <SignInForms />
          </Route>
          <PrivateRoute exact path="/Fleets" component={FleetsSelect} />
          <PrivateRoute
            path="/:fleetName/:fleetid/:vecName/:vecid/Plan"
            component={PlanPage}
          ></PrivateRoute>
          <PrivateRoute
            path="/:fleetName/:fleetid/:vecName/:vecid/Results"
            component={ResultsPage}
          ></PrivateRoute>
          <PrivateRoute
            path="/:fleetName/:fleetid/:vecName/:vecid/FieldTest"
            component={FieldTestPage}
          ></PrivateRoute>
          <PrivateRoute
            path="/:fleetName/:fleetid/summary"
            component={SummaryPage}
          ></PrivateRoute>
          <PrivateRoute
            path="/:fleetName/:fleetid/Vecs"
            component={VecSelectList}
          />
          <PrivateRoute path="/Fleets" component={FleetsSelect} />
          {/* 404のコンポーネント*/}
        </Switch>
      </UserContextProvider>
    </Router>
  );
}

export default App;
