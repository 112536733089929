import axios from 'axios';

const BASE_URL = process.env["REACT_APP_API_URL"]

//projects
export const kiwiApiProjects = async (token) => {
  try {
    const api = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `${token}`,
        },
    });

    const response = await api.get(`/projects`);
    return response.data;
  } catch (error) {
    console.error('Error projects API:', error);
    throw error;
  }
};

//vehicles
export const kiwiApiVehicles = async (token, projectid) => {
  try {
    const api = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `${token}`,
        },
    });

    const response = await api.get(`/vehicles`, {
      params: {
        fleet_id: projectid,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error vehicles API:', error);
    return "error";
  }
};

//get all vec plan result
export const kiwiApiGetAllVecPlanResult = async (token, projectid) => {
  try {
    const api = axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `${token}`,
        },
    });

    const response = await api.get(`/all_vec`, {
      params: {
        fleet_id: projectid,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error vehicles API:', error);
    return "error";
  }
};