export const lineStyle_plan_normal = /** @type {google.maps.Data.StyleOptions} */ {
  strokeColor: "#A10202",
  strokeWeight: 4,
  strokeOpacity: 0.5,
  // zIndex: 0,
};

export const lineStyle_result_normal = /** @type {google.maps.Data.StyleOptions} */ {
  strokeColor: "#0252A1",
  strokeWeight: 3,
  strokeOpacity: 0.7,
  zIndex: 10,
};

export const lineStyle_selected = /** @type {google.maps.Data.StyleOptions} */ {
  strokeColor: "green",
  strokeWeight: 10,
  strokeOpacity: 0.3,
  // zIndex: 100,
};
