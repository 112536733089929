/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HdmapMeta
 */
export interface HdmapMeta {
    /**
     * 
     * @type {string}
     * @memberof HdmapMeta
     */
    coverage_key: string;
    /**
     * 
     * @type {string}
     * @memberof HdmapMeta
     */
    provider: string;
    /**
     * 
     * @type {string}
     * @memberof HdmapMeta
     */
    fleet_id: string;
    /**
     * 
     * @type {string}
     * @memberof HdmapMeta
     */
    version: string;
}

export function HdmapMetaFromJSON(json: any): HdmapMeta {
    return HdmapMetaFromJSONTyped(json, false);
}

export function HdmapMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): HdmapMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coverage_key': json['coverage_key'],
        'provider': json['provider'],
        'fleet_id': json['fleet_id'],
        'version': json['version'],
    };
}

export function HdmapMetaToJSON(value?: HdmapMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coverage_key': value.coverage_key,
        'provider': value.provider,
        'fleet_id': value.fleet_id,
        'version': value.version,
    };
}


