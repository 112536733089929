import React, { createContext, useReducer } from "react";
import {
  UserState,
  UserDispatch,
  reducer as userReducer,
  initialState,
} from "./user";

interface UserContextProps {
  state: UserState;
  dispatch: UserDispatch;
}

export const UserContext = createContext({
  state: initialState,
  dispatch: (...args: any[]) => {
    console.debug("[dispatch]", args);
  }, // 初期化時は何もしないdispatch関数とする
});

export function UserContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(userReducer, initialState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
}
