import React from "react";
import { Button, Modal } from "react-bootstrap";

export function ModalPointDistanceCheck({
    distanceCheckModalShow,
    distanceCheckModalOKClick,
    distanceCheckModalContents
  }) {
    return (
      <>
        <Modal
            backdrop ='static'
            keyboard = {false}
            show={distanceCheckModalShow}
            onHide={distanceCheckModalOKClick}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
            <Modal.Title className="titlecustom">
                Distance Check
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#EDEDED", fontSize: "x-large" }}>
            <pre>
                {distanceCheckModalContents}
            </pre>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
            <Button variant="dark" onClick={distanceCheckModalOKClick}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }