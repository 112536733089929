import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { usePlaceSearchbox } from "../../hooks/usePlaceSearchbox";
import { InfoIcon } from "./infoIcon";
import { ModalConfirm } from "./ModalConfirm";
import {
  createRouteGeoJsonbyRoutePath,
  createSearchRouteRequest,
  DirectionsServiceGoogle,
  returnSumDistance,
  returnSumRouteGeoJson,
  returnSumTime,
  returnTotalDistanceTime,
  separateRoutePoints,
} from "./plan/SearchDirections";

/**
 *地図上での検索ボックスコンポーネント
 * @param {string} mode 'plan'||'results'|| 'fieldTest'
 */
function MapSearchBox({
  googleMap,
  setRoute,
  map,
  points,
  setDistanceTimePath,
  updatePointsOrder,
  setDistanceTimePathWaypoints,
  routeOptimization,
  setRouteOptimization,
  mode,
  addPoint,
  setPointDistance,
}) {
  const placeSearchInputRef = useRef(null);
  const avoidHighwayRef = useRef(null);
  const routeOptimizationRef = useRef(null);
  usePlaceSearchbox({ googleMap, map, placeSearchInputRef, addPoint });
  const [duringRouteSearch, setDuringRouteSearch] = useState(false);
  const [searchRouteModalShow, setSearchRouteModalShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [optimizationLimit, setOptimizationLimit] = useState(false);

  useEffect(() => {
    if (mode === "fieldTest" || mode === "results") {
      //routePlanモード以外はここ
      return;
    }
    if (points.filter((point) => point.type === "waypoint").length >= 26) {
      //中継点が26以上なら強制的にoptimization OFF
      setRouteOptimization(false);
      setOptimizationLimit(true);
    } else {
      setOptimizationLimit(false);
    }
  }, [mode, points, setRouteOptimization]);

  function clickDeleteRoute() {
    setRoute((route)=>({...route, path_geojson:undefined}))
  }  

  function clickRouteSearch() {
    //console.debug("Route Search", points);
    const org_point = points.filter((point) => point.type === "origin");
    const dest_point = points.filter((point) => point.type === "destination");
    const waypoints = points.filter((point) => point.type === "waypoint");

    if (org_point.length === 0 || dest_point.length === 0) {
      setSearchRouteModalShow(true);
      setErrorMsg(
        "The origin and destination is not selected. These points is necessary for searching routes."
      );
    } else if (org_point.length !== 1 || dest_point.length !== 1) {
      setSearchRouteModalShow(true);
      setErrorMsg(
        "Too many origin and destination in selected points. These points are allowed one of each."
      );
    } else if (optimizationLimit) {
      //waypoints26以上の時の検索ロジック

      setDuringRouteSearch(true); //ルートSeach開始
      //console.log(points.length);
      const reorderPoints = org_point.concat(waypoints).concat(dest_point);
      const requestPointsGroup = separateRoutePoints(reorderPoints);
      //console.log(requestPointsGroup);

      //requestをgooglemapに投げるPromise関数を作成する
      const requests = requestPointsGroup.map((requestPoints) => {
        // const requestPoints = reorderPoints.slice(27 * i, 27 * i + 27);
        const orgpos = requestPoints[0];
        const destpos = requestPoints[requestPoints.length - 1];
        const waypos = requestPoints.slice(1, requestPoints.length - 1);
        const optimizeWaypoints = routeOptimization;
        const avoidHighways = avoidHighwayRef.current.checked;

        const request = createSearchRouteRequest({
          orgpos,
          destpos,
          waypos,
          googleMap,
          optimizeWaypoints,
          avoidHighways,
        });

        return DirectionsServiceGoogle(googleMap, request);
      });
      //console.log(requests);

      //一気にルート検索リクエストを投げる
      Promise.all(requests)
        .then((results) => {
          //console.log(results);
          const errorResults = results.filter((r) => r.status !== "OK");

          if (errorResults.length > 0) {
            function createErrorMSG(errorResults) {
              let msg = "";
              errorResults.forEach((er) => {
                msg += `${er.status}.`;
              });
              return msg;
            }
            const errorMsg = createErrorMSG(errorResults);
            throw new Error(errorMsg); // promise を rejects
          } else {
            //エラー無し
            //when this work?
            const pickUpResults = results.map((result) => {
              const routeGeoJson = createRouteGeoJsonbyRoutePath(result);
              const result_time_distance = returnTotalDistanceTime(result);
              return { routeGeoJson, result_time_distance };
            });
            //console.log(pickUpResults);
            // const errorResultes = pickUpResults.filter((r) => r.error);

            setDistanceTimePath(
              returnSumDistance(pickUpResults),
              returnSumTime(pickUpResults),
              returnSumRouteGeoJson(pickUpResults)
            );
            setDuringRouteSearch(false); //ルート検索終了
          }
          // console.log("all distance", returnSumDistance(pickUpResults));
          // console.log("all time", returnSumTime(pickUpResults));
          // console.log("all geoJson", returnSumRouteGeoJson(pickUpResults));
        })
        .catch((error) => {
          //console.log(error);
          setDuringRouteSearch(false); //ルート検索終了
          //Errorメッセージを表示
          setSearchRouteModalShow(true);
          console.warn("route search NG over 25", error);
          const errorMsg = `Route Not Found ( ${error.message} )`;
          setErrorMsg(errorMsg);
        });
    } else {
      //waypoints25以下で一度に検索可能。
      //console.log(routeOptimization);

      setDuringRouteSearch(true); //ルート検索開始
      const orgpos = org_point[0];
      const destpos = dest_point[0];
      const waypos = waypoints;
      const optimizeWaypoints = routeOptimization;
      const avoidHighways = avoidHighwayRef.current.checked;
      const request = createSearchRouteRequest({
        orgpos,
        destpos,
        waypos,
        googleMap,
        optimizeWaypoints,
        avoidHighways,
      });

      const d = new googleMap.maps.DirectionsService(); // ルート検索オブジェクト
      d.route(request, function (result, status) {
        if (status === googleMap.maps.DirectionsStatus.OK) {
          //console.log("route search result: result", result);
          //directionsRenderer.setDirections(result);
          const routeGeoJson = createRouteGeoJsonbyRoutePath(result);
          const result_time_distance = returnTotalDistanceTime(result);
          // console.debug("waypoint order", result.routes[0].waypoint_order);
          //waypoint_index_orderedは[]でwaypointの順序が記載されている。before = index , after = value

          //ルート検索前後の配列indexの対応objectを作成
          // [{before: before_index, after: after_index}, ...]
          const waypointOrderInfo = result.routes[0].waypoint_order.map(
            (o, key) => {
              const before = o;
              const after = key;
              return { before, after };
            }
          );
          //waypointsOrderInfoをafterで並び替えする
          waypointOrderInfo.sort(function (first, second) {
            if (first.after < second.after) {
              return -1;
            } else if (first.after > second.after) {
              return 1;
            } else {
              return 0;
            }
          });
          const orderedWaypoints = [];
          //afterの順番でwaypointsの配列を再生成する
          waypointOrderInfo.forEach((order) => {
            orderedWaypoints.push(waypoints[order.before]);
          });

          const tempNewPoints = org_point
            .concat(orderedWaypoints)
            .concat(dest_point);

          const newPoints = setPointDistance(result.routes[0].legs,tempNewPoints);
          //route.pointsを更新
          updatePointsOrder(newPoints);

          setDistanceTimePathWaypoints(
            result_time_distance.distance_m,
            result_time_distance.time_sec,
            routeGeoJson,
            newPoints
          );
          //ルート検索後は必ずoffにする
          setRouteOptimization(false);
          setDuringRouteSearch(false); //ルート検索終了
        } else {
          //deleteMarkers();
          setDuringRouteSearch(false); //ルート検索終了
          //エラーメッセージを表示
          setSearchRouteModalShow(true);
          //console.log("route search result: status not OK", result);
          const errorMsg =
            result.status === "MAX_WAYPOINTS_EXCEEDED"
              ? "Too many waypoints in selected points. The maximum allowed waypoints is 25."
              : result.status === "ZERO_RESULTS"
              ? "Route is not found. Please confirm search condition."
              : result.status;
          setErrorMsg(errorMsg);
        }
      });
    }
  }

  function closeSearchRouteModal() {
    setSearchRouteModalShow(false);
    setErrorMsg("");
  }

  return (
    <>
      <Card style={{ width: "18rem", backgroundColor: "#EDEDED" }}>
        <Card.Body>
          <Card.Title></Card.Title>
          <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
          <Card.Text></Card.Text>
          <div>
            <div style={{ margin: "5px" }}>
              POI Search{" "}
              <InfoIcon
                message="When searching GPS positon, please put it such as (35.123, 135.222)."
                placement={"top"}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Form.Control
                ref={placeSearchInputRef}
                id="pac-input"
                type="text"
                title="When searching by GNSS value, plese put in like (lat, lng)"
                placeholder="POI or (lat, lng) "
                size="sm"
              />
            </div>
            {mode === "results" || mode === "fieldTest" ? null : (
              <div>
                <div style={{ margin: "5px" }}>
                  Route Menu{" "}
                  <InfoIcon message="powered by Google" placement={"right"} />
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <Button
                      id="btnRoute"
                      variant="red"
                      size="sm"
                      onClick={clickRouteSearch}
                      title="search route"
                      style={{ fontSize: "15px", margin: "5px" }}
                    >
                      Route Search
                      {duringRouteSearch ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                    </Button>
                    <Button
                      id="btnRoute"
                      variant="grey"
                      size="sm"
                      onClick={clickDeleteRoute}
                      title="delete route"
                      style={{ fontSize: "12px", margin: "5px" }}
                    >
                      Delete Route
                    </Button>
                  </div>
                  <div>

                    <Form.Check
                      type="checkbox"
                      ref={avoidHighwayRef}
                      label="avoid Highway"
                      style={{
                        fontSize: "15px",
                        margin: "5px",
                      }}
                      inline
                    />

                    <Form.Check
                      type="checkbox"
                      checked={routeOptimization}
                      onClick={() => setRouteOptimization(!routeOptimization)}
                      ref={routeOptimizationRef}
                      label="optimize Route"
                      style={{
                        fontSize: "15px",
                        margin: "5px",
                      }}
                      inline
                      disabled={optimizationLimit}
                    />

                  </div>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
      <ModalConfirm
        modalTitle={"please Confirm "}
        showModal={searchRouteModalShow}
        closeModal={closeSearchRouteModal}
        message={errorMsg}
      />
    </>
  );
}

export { MapSearchBox };
