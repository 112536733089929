/**
 * point情報表示するInfoWindowを生成する
 * @param {google} googleMap
 * @param {(e: google.maps.Data.MouseEvent) => void} event
 */
export function pointInfoWindow(googleMap, event) {
  //console.log(event.feature);
  const pointType = event.feature.getProperty("type");

  //type === csvPointはcsvから読み込んだPoint
  //type === kmlPoint はkmlから読み込んだPoint
  const content = (pointType) => {
    if (pointType === "csvPoint") {
      const carNo = event.feature.getProperty("carNo");
      const caseNo = event.feature.getProperty("caseNo");
      const category = event.feature.getProperty("category");
      const datetime = event.feature.getProperty("datetime");
      const fileName = event.feature.getProperty("fileName");
      const comment = event.feature.getProperty("comment");

      const issueContent = `<div style="font-size: 12px;">
                        <p>File Name: <span style="font-weight:bold;">${fileName}</p>
                        <p>Category: ${category} </p>
                        <p>Car No: ${carNo}</p>
                        <p>Datetime: ${datetime}</p>
                        <p>Case No: ${caseNo} </p>
                        <p>Comment: ${comment}</p>
                        </div>`;
      return issueContent;
    } else if (pointType === "kmlPoint") {
      const name = event.feature.getProperty("name");
      const comment = event.feature.getProperty("comment");
      const fileName = event.feature.getProperty("fileName");
      const kaigyoComment = comment.replace(/\r?\n/g, "<br>"); //改行を置き換え

      const kmlcontent = `<div style="font-size: 12px;">
                          <p>File Name: <span style="font-weight:bold;">${fileName}</p>
                          <p>Name: ${name} </p>
                          <p>Comment: ${kaigyoComment}</p>
                          </div>`;
      return kmlcontent;
    } else {
      // console.warn("読み込み以外のPoint");
      const empty_content = `<div style="font-size: 12px;">
      <p>Information of this point can not be showed.</p>
      <p>Please contact Kiwi development team.</p>
      </div>`;
      return empty_content;
    }
  };

  const infowindow = new googleMap.maps.InfoWindow({
    content: content(pointType),
    position: event.latLng,
  });
  return infowindow;
}
