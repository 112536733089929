import React, { useEffect } from "react";
import { Button, Modal, Col, Form, } from "react-bootstrap";

export function ModalPOIDBFilterOptionMain({
    poidbFilterMainModalShow,
    poidbFilterMainOKClick,
    poidbFOContentsChecker,
    setPoidbFOContentsChecker,
  }) {

    if (poidbFOContentsChecker == null){return null} 

    function onTitleClick(title,index,checked){
      setPoidbFOContentsChecker((before)=>{
        const after = [...before]
        after[index][title].checked=checked
        return after
      })
    }

    function onColumnClick(title,column,index,checked){
      setPoidbFOContentsChecker((before)=>{
        const after = [...before]
        after[index][title].columns[column].checked=checked
        return after
      })
    }

    function onColumnAllClick(title,column,index,checked){
      setPoidbFOContentsChecker((before)=>{
        const after = [...before]
        after[index][title].columns[column].allChecked=checked
        const optionKeys = Object.keys(after[index][title].columns[column].option)
        for(let i=0; i<optionKeys.length; i++){
          after[index][title].columns[column].option[optionKeys[i]].checked=checked
        }
        return after
      })
    }

    function onOptionClick(title,column,option,index,checked){
      setPoidbFOContentsChecker((before) => {
        const after = [...before]
        after[index][title].columns[column].option[option].checked=checked

        let count = 0
        const optionKeys = Object.keys(after[index][title].columns[column].option)
        for(let i=0; i<optionKeys.length; i++){
          if(!after[index][title].columns[column].option[optionKeys[i]].checked){
            count++
          }
        }
        if(count != 0){
          after[index][title].columns[column].allChecked=false
        }else{
          after[index][title].columns[column].allChecked=true
        }
        return after
      })
    }

    function Renderer({poidbFOContentsChecker}){
      return poidbFOContentsChecker.map((content,index) =>{
        const title = Object.keys(content)[0]
        const columns = Object.keys(content[title].columns)
        return(
          <Form key={title}>    
            <Form.Check 
              type="switch"
              id={title}
              label={title}
              checked={content[title].checked}
              onChange={(e)=>{onTitleClick(title,index,e.target.checked)}}
            />
            <Col>
            {content[title].checked ?
              (columns.map(column =>{
                const options = content[title].columns[column].option
                const optionsList =Object.keys(options)
                const columnId = `${title}//${column}`
                return(
                  <>
                    <Form.Check 
                      type="switch"
                      id={columnId}
                      label={
                        <Form.Check 
                          type="checkbox"
                          id={columnId}
                          label={column}
                          checked={content[title].columns[column].allChecked}
                          onChange={(e)=>{onColumnAllClick(title,column,index,e.target.checked)}}
                        />
                      }
                      checked={content[title].columns[column].checked}
                      onChange={(e)=>{onColumnClick(title,column,index,e.target.checked)}}
                    />
                    <Col>
                    {content[title].columns[column].checked ? (
                      optionsList.map(option=>{
                        const optionId = `${title}//${column}//${option}`
                        return(
                          <Form.Check 
                            type="checkbox"
                            id={optionId}
                            label={option}
                            checked={content[title].columns[column].option[option].checked}
                            onChange={(e)=>{onOptionClick(title,column,option,index,e.target.checked)}}
                          />
                        )
                      })
                      ):<></>
                    }
                    </Col>
                  </>
                )
              }))
              :<></>
            }
            </Col>
          </Form>
        )
      })
      

    }

    return (
      <>
        <Modal
            backdrop ='static'
            keyboard = {false}
            show={poidbFilterMainModalShow}
            onHide={poidbFilterMainOKClick}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
          <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
            <Modal.Title className="titlecustom">
                POIDB Filter Option - Main
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#EDEDED" }}>

          <Renderer poidbFOContentsChecker={poidbFOContentsChecker}/>
          
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
            <Button variant="dark" onClick={poidbFilterMainOKClick}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }