import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//各VEC予実管理ページへ移動するアイコン
export const LinkOutVecResult = ({
  fleetName,
  fleetId,
  car_name,
  car_id,
  history,
}) => {
  function moveToVecResult() {
    //console.log("move to");
    history.push(`/${fleetName}/${fleetId}/${car_name}/${car_id}/Results`);
  }
  return (
    <>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id={`tooltip-right`}>
            You can move to Result Page by click here.
          </Tooltip>
        }
      >
        <div
          style={{ padding: "0px 0px", cursor: "pointer" }}
          onClick={moveToVecResult}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            id="LinkOut"
          >
            <path d="M13.5 10.5L21 3" />
            <path d="M16 3h5v5" />
            <path d="M21 14v5a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h5" />
          </svg>
        </div>
      </OverlayTrigger>
    </>
  );
};
