import React from "react";
import { Button, Modal } from "react-bootstrap";

/**
 *
 * @param {string} modalTitle
 * @param {boolean} showModal
 * closeModalはモーダルを閉じたときに実行する関数
 * @param  {string} message
 */

interface modalConfirmProps {
  modalTitle: string;
  showModal: boolean;
  closeModal: () => void;
  message: string;
}
export function ModalMessage({
  modalTitle,
  showModal,
  closeModal,
  message,
}: modalConfirmProps) {
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="titlecustom">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{message}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="grey" onClick={closeModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
