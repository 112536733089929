import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import style from "./BreadcrumbBar.module.css";

function BreadcrumbBar({ params }) {
  // const style = { backgroundColor: "#D8D8D8", marginBottom: "0rem" };
  if (params === undefined) {
    return (
      <>
        <Breadcrumb className={style.breadcrumbBar}></Breadcrumb>
      </>
    );
  } else {
    const fleetid = params.fleetid;
    const vecid = params.vecid;
    if (fleetid === undefined && vecid === undefined) {
      return (
        <>
          <Breadcrumb className={style.breadcrumbBar}></Breadcrumb>
        </>
      );
    } else if (fleetid !== undefined && vecid === undefined) {
      //console.log("this is Vec Page!!!");
      const linkurl = `/Fleets`;
      const label_fleets = params.fleetName;
      return (
        <>
          <Breadcrumb className={style.breadcrumbBar}>
            <Breadcrumb.Item active>
              <Link to={linkurl}>{label_fleets}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </>
      );
    } else if (fleetid !== undefined && vecid !== undefined) {
      //console.log("this is route Page!!");
      const linkurl_fleets = `/Fleets`;
      const label_fleet = params.fleetName;
      const linkurl_vecs = `/${params.fleetName}/${fleetid}/Vecs`;
      const label_vec = params.vecName;
      return (
        <>
          <Breadcrumb className={style.breadcrumbBar}>
            <Breadcrumb.Item active>
              <Link to={linkurl_fleets}>{label_fleet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <Link to={linkurl_vecs}>{label_vec}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </>
      );
    } else {
      console.log("under construction");
      return (
        <>
          <Breadcrumb className={style.breadcrumbBar}></Breadcrumb>
        </>
      );
    }
  }
}

export { BreadcrumbBar };
