import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { ModalbtnUploadResult } from "./ModalbtnUploadResult";

export const ResultRoutesPane = ({
  resultRoutes,
  setResultRoutes,
  selectedRoute,
  setSelectedRoute,
  map,
  fleetId,
  carId,
  fleetName,
  carName,
  setIsShowMessage,
  setModalMessageConfirm,
  setModalTitleConfirm,
}) => {
  resultRoutes.sort(function (a, b) {return a.result_name.localeCompare(b.result_name);})
  //allCheck status機能
  const [isAllCheckedResult, setIsAllCheckedResult] = useState(true);
  //checkedItems
  const [checkedResultItems, setCheckedResultItems] = useState({});

  const CheckBox = ({ id, value, checked, onChange }) => {
    return (
      <input
        id={id}
        type="checkbox"
        name="inputNames"
        checked={checked}
        onChange={onChange}
        value={value}
        style={{ padding: "2px", width: "15px", height: "15px" }}
      />
    );
  };
  useEffect(() => {
    if (resultRoutes === undefined || resultRoutes === null) {
      return;
    }
    //resultRoutesが代入されたら全てTrueにして格納する
    const iniCheckedItems = {};
    resultRoutes.forEach((r) => {
      iniCheckedItems[r.result_id] = true;
    });
    //console.log(iniCheckedItems);
    setCheckedResultItems(iniCheckedItems);
  }, [resultRoutes]);

  useEffect(() => {
    if (checkedResultItems == {}) {
      return;
    }
    //全てcheckBoxがONか否かを判定する
    const ids = Object.keys(checkedResultItems);
    const _isExistsFalse = ids.some((id) => {
      return checkedResultItems[id] === false;
    });
    //console.log("_isExistsFalse", _isExistsFalse);
    setIsAllCheckedResult(!_isExistsFalse);
  }, [checkedResultItems]);

  function handleChangeResult(e) {
    //checkedItemsのstateをセット
    setCheckedResultItems({
      ...checkedResultItems,
      [e.target.id]: e.target.checked,
    });
    setSelectedRoute({});
  }

  useEffect(() => {
    //checkアイテムのtrue/falseに伴ってパス表示も変更
    if (checkedResultItems == {} || map === null) {
      return;
    }
    const ids = Object.keys(checkedResultItems);
    ids.forEach((id) => {
      const feature = map.data.getFeatureById(id);
      map.data.overrideStyle(feature, {
        visible: checkedResultItems[id],
      });
    });
  }, [checkedResultItems, map]);

  //allCheckBoxをクリックしたときの処理
  function onChangeALLcheckboxResult(isAllCheckedResult) {
    //isAllChecked=trueなら今全選択なので、すべてfalseにする
    const iniCheckedItems = {};
    resultRoutes.forEach((r) => {
      iniCheckedItems[r.result_id] = !isAllCheckedResult;
    });
    setCheckedResultItems(iniCheckedItems);
  }
  return (
    <>
      <Card style={{ height: "33%", padding: 0 }}>
        <Card.Header
          className="titlecustom"
          style={{
            padding: "5px",
            color: "#585858",
            fontSize: "18px",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Result Routes
          </div>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "0",
              paddingRight: "10px",
            }}
          >
            <label
              style={{
                margin: "auto",
              }}
            >
              <input
                type="checkbox"
                checked={isAllCheckedResult}
                onChange={() => {
                  onChangeALLcheckboxResult(isAllCheckedResult);
                }}
                style={{ margin: "2px", width: "15px", height: "15px" }}
              />
              ALL
            </label>
          </div>
        </Card.Header>
        <Card.Body
          style={{ height: "100%", overflowY: "scroll", padding: "5px" }}
        >
          <Table variant="flush">
            <tbody>
              {resultRoutes.map((result) => (
                <tr key={result.result_id}>
                  <td
                    style={{
                      margin: "5px",
                      padding: "5px 10px",
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    <CheckBox
                      id={result.result_id}
                      value={result.result_id}
                      onChange={handleChangeResult}
                      checked={checkedResultItems[result.result_id]}
                    />
                  </td>
                  <td
                    style={{
                      fontSize: "12px",
                      padding: "5px",
                      color:
                        selectedRoute.result_id === result.result_id
                          ? "white"
                          : "black",
                      backgroundColor:
                        selectedRoute.result_id === result.result_id
                          ? "grey"
                          : "white",
                    }}
                    onClick={() => {
                      setSelectedRoute(
                        resultRoutes.find(
                          (r) => r.result_id === result.result_id
                        )
                      );
                    }}
                  >
                    {result.result_name}
                  </td>
                </tr>
              ))}
              <tr key="upload-result">
                <td
                  colSpan="3"
                  style={{
                    fontSize: "5px",
                  }}
                >
                  <ModalbtnUploadResult
                    fleetId={fleetId}
                    carId={carId}
                    fleetName={fleetName}
                    carName={carName}
                    resultRoutes={resultRoutes}
                    setResultRoutes={setResultRoutes}
                    setIsShowMessage={setIsShowMessage}
                    setModalMessageConfirm={setModalMessageConfirm}
                    setModalTitleConfirm={setModalTitleConfirm}
                    map={map}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};
