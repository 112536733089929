import React, { useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useUser } from "../../../hooks/useUser";
import Plus from "../../../image/plus.png";
import { kiwiApi } from "../kiwiApi";

function ModalCreateNewPlan({
  fleetId,
  vecId,
  show,
  onHide,
  setConfirmModalShow,
  plans,
  setPlans,
  setConfirmTitle,
  setConfirmMsg,
}) {
  const planName = useRef(null);
  const [loadStatus, setLoadStatus] = useState(false);
  const { user } = useUser();

  function postNewPlan() {
    const newPlanName = planName.current.value;

    console.debug(
      "post New Plan",
      `fleet ID: ${fleetId}, Vec ID:${vecId}, PlanName: ${newPlanName}`
    );
    setLoadStatus(true);
    kiwiApi(user.token)
      .createPlan({
        newPlan: {
          car_id: vecId,
          fleet_id: fleetId,
          plan_name: newPlanName,
        },
      })
      .then((response) => {
        //console.log(response);
        setLoadStatus(false);
        const newPlans = plans.concat(response);
        //console.log(newPlans);
        setPlans(newPlans);

        //もらったルート一覧を選択肢に入れて、今入れたルートを選ぶ

        onHide();
        setConfirmTitle("SUCESS! create New Route");
        setConfirmMsg("New route has been created successfully!!");
        setConfirmModalShow(true);
      })
      .catch((error) => {
        console.log(error.message);
        setLoadStatus(false);
        onHide();
        setConfirmTitle("ERROR! create New Route");
        setConfirmMsg(error.message);
        setConfirmModalShow(true);
      });
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="titlecustom">Create new route</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>route name:</h4>
        <Form.Control type="text" ref={planName} maxLength="256" />
        <Form.Text className="text-muted">*limits to 250 characters</Form.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={postNewPlan} variant="red">
          Create New Route
          {loadStatus ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Button>
        <Button onClick={onHide} variant="grey">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ModalbtnCreateNewPlan({
  fleetId,
  vecId,
  setConfirmModalShow,
  plans,
  setPlans,
  setConfirmTitle,
  setConfirmMsg,
}) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        size="sm"
        variant="red"
        onClick={() => setModalShow(true)}
        style={
          plans.length === 0
            ? { margin: "5px", width: "80%" }
            : { margin: "5px" }
        }
      >
        <img
          src={Plus}
          width="20"
          height="20"
          alt="create new plan"
          title="create new plan"
        />
      </Button>

      <ModalCreateNewPlan
        fleetId={fleetId}
        vecId={vecId}
        show={modalShow}
        onHide={() => setModalShow(false)}
        setConfirmModalShow={setConfirmModalShow}
        plans={plans}
        setPlans={setPlans}
        setConfirmTitle={setConfirmTitle}
        setConfirmMsg={setConfirmMsg}
      />
    </>
  );
}
