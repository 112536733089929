import React from "react";
import { HdmapCoverageButton } from "../../hdmap/HdmapCoverage";
import { NotTestedButton } from "../../hdmap/NotTestedSegments";

/**
 * HDmapデータを表示するコンポーネント
 * @param {google.maps.Map} map
 * @param {google} googleMap
 */
export function HDmapPart({ map, googleMap, fleetId }) {
  return (
    <>
      {/* <Form.Group> */}
      <HdmapCoverageButton map={map} googleMap={googleMap} fleetId={fleetId} />
      {/* </Form.Group> */}
      <NotTestedButton map={map} googleMap={googleMap} fleetId={fleetId} />
    </>
  );
}
