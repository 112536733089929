import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Card } from "react-bootstrap";
import { maximumNumberOfPoints } from "../../LimitsValue";
import { TrashIcon } from "./icons";
import { ModalConfirm } from "./ModalConfirm";

//中継点の配列順番設定コンポーネント
//routePlanモード・FieldTestモードに対応

/**
 * ルートのポイントの順序入替・Type変更・削除をするコンポーネント.
 * ポイント上限値に達したときのアラートも表示する.
 * @param {object} route
 * @type {import("../../hooks/useCurrentPlan").updatePointsOrder}
 * @type {import("../../hooks/useCurrentPlan").deletePoint}
 * @param {object} currentPoint
 * @type {import("./plan/PlanPage").setCurrentPoint}
 * @type {import("./plan/PlanPage").setIsPointCommentOpened}
 * @param {boolean} maximumPointsAlert
 * @type {import("../../hooks/useCurrentPlan").setMaximumPointAlert}
 * @param {string} mode 'plan' || 'results' || 'fieldTest
 */
export const PlanPointList = ({
  // route,
  map,
  routePoints,
  updatePointsOrder,
  deletePoint,
  currentPoint,
  setCurrentPoint,
  setIsPointCommentOpened,
  maximumPointsAlert,
  setMaximumPointAlert,
  mode,
}) => {
  const [items, setItems] = useState([]);
  const [clickedItem, setClickedItem] = useState(null);

  //steyleに関する関数
  const grid = 1;
  const getItemStyle = (isDragging, draggableStyle, item, clickedItem) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "5px",
    margin: "5px",
    height: "auto",
    fontSize: "12px",
    background: isDragging
      ? "#ba3c3c" //ボタンの赤色
      : Boolean(clickedItem) && clickedItem.id === item.id
      ? "#0ed145" //selectedPinと同じ色
      : item.type === "origin"
      ? "lightpink"
      : item.type === "destination"
      ? "lightblue"
      : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightgrey" : "white",
    padding: grid,
    height: "100%",
    overflowY: "scroll",
  });

  useEffect(() => {
    if (!routePoints) {
      return;
    }
    //console.log(routePoints);

    setItems(routePoints);
    setClickedItem(currentPoint);
  }, [routePoints, currentPoint]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder_items = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    //console.log("before type change", reorder_items);
    const changeTypeItems = reorder_items.map((i, index) => {
      if (index === 0) {
        return { ...i, type: "origin" };
      } else if (index === reorder_items.length - 1) {
        return { ...i, type: "destination" };
      } else {
        return { ...i, type: "waypoint" };
      }
    });
    //console.log("after type change", changeTypeItems);
    setItems(changeTypeItems);
    updatePointsOrder(changeTypeItems);
    // console.log(items);
  }
  function onClickPointItem(item) {
    // setClickedItem(item);
    map.panTo({ lat:item.lat, lng:item.lng })
    setCurrentPoint(item);
    setIsPointCommentOpened(true);
  }
  function deletePointItem(id) {
    deletePoint(id);
    setIsPointCommentOpened(false);
  }
  function closeMaximumPointsAlertModal() {
    // setShowModal(false);
    setMaximumPointAlert(false);
  }

  const modal = maximumPointsAlert ? (
    <ModalConfirm
      modalTitle={"ALERT! Maximum Pins"}
      showModal={true}
      closeModal={() => closeMaximumPointsAlertModal()}
      message={`Pins are limited to ${maximumNumberOfPoints}.Please reduce the Pin.`}
    ></ModalConfirm>
  ) : null;

  if (!routePoints || routePoints.length === 0) {
    return <></>;
  } else if (mode === "fieldTest") {
    //fieldTestモード
    return (
      <>
        <div style={getListStyle(false)}>
          {items.map((item, index) => (
            <Card style={getItemStyle(false, null, item, clickedItem)}>
              <Card.Body
                style={{
                  padding: 0,
                  display: "flex",
                }}
              >
                <Card.Text
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  onClick={() => onClickPointItem(item)}
                >
                  {`index: ${index + 1}`}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </>
    );
  } else if (mode === "plan") {
    //routePlanモード
    return (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          item,
                          clickedItem
                        )}
                      >
                        <Card.Body
                          style={{
                            padding: 0,
                            display: "flex",
                          }}
                        >
                          <Card.Text
                            style={{
                              width: "100%",
                              margin: 0,
                              padding: "10px",
                              fontSize: "12px",
                            }}
                            onClick={() => onClickPointItem(item)}
                          >
                            {`index: ${index + 1}`}<br/>
                            {`${item.comment == undefined? "":`${item.comment}`}`}
                          </Card.Text>

                          <Button
                            size="sm"
                            variant="grey"
                            title="delete"
                            style={{
                              fontSize: "5px",
                              marginLeft: "auto",
                              marginRight: 0,
                            }}
                            onClick={() => deletePointItem(item.id)}
                          >
                            <TrashIcon />
                          </Button>
                        </Card.Body>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {modal}
      </>
    );
  }
};
