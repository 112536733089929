import React from "react";
import { Button, Modal } from "react-bootstrap";

export function ModalPOIDBAddMain({
    poidbAddMainModalShow,
    poidbCancelAddClick,
    poidbSelectRef,
    poidbAddClick
  }) {
    return (
      <>
        <Modal
            show={poidbAddMainModalShow}
            onHide={poidbCancelAddClick}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
            <Modal.Title className="titlecustom">
                POIDB Add
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
            Add "{`${poidbSelectRef}`}" to WorkSpace?
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
            <Button variant="outline-dark" onClick={poidbCancelAddClick}>
              Cancel
            </Button>
            <Button variant="dark" onClick={poidbAddClick}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }