import { GoogleMapsOverlay } from "@deck.gl/google-maps";
// import { GeoJsonLayer } from "@deck.gl/layers";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { useUser } from "../../../hooks/useUser";
import { InfoIcon } from "../infoIcon";
import { kiwiApi } from "../kiwiApi";
import { createGeojsonLayer } from "./createGeojsonLayer";
import { getMatchingData, mapmatchingData } from "./hdmapmatching";

export const NotTestedButton = ({ fleetId, map, googleMap }) => {
  const { user } = useUser();
  const [mapmachingInfo, setMapmatchingInfo] = useState(null); //未走行エリアデータについての情報
  const [lastModified, setlastModified] = useState(null); //未走行エリアjsonの最終更新日
  const [misoukouJson, setMisoukouJson] = useState(null); //misoukou area geojson
  const [misoukouToggle, setMisoukouToggle] = useState(false); //misoukouBotton表示・非表示のトグル
  const [misoukouLayer, setMisoukouLayer] = useState(null);
  const [misoukouFleetId, setMisoukouFleetId] = useState(null); //ユーザーが指定したmisoukouのfleetId
  const [misoukouIchiran, setMisoukouIchiran] = useState([]);

  useEffect(() => {
    kiwiApi(user.token)
      .getHdMap({ fleetId: fleetId })
      .then((res) => {
        const { provider, version } = res;
        setMapmatchingInfo({ provider, version });
        // mapmatchingData({ fleetId, setMisoukouJson, setlastModified });
      })
      .catch((error) => {
        //対応hdmapが存在しない場合はボタンすら見せない
        setMapmatchingInfo(null);
        setMisoukouJson(null);
      });
    getMatchingData({ setMisoukouIchiran });
  }, [fleetId, user.token]);

  useEffect(() => {
    if (misoukouFleetId === null) {
      return;
    }
    mapmatchingData({
      fleetId: misoukouFleetId,
      setMisoukouJson,
      setlastModified,
    });
  }, [misoukouFleetId]);

  useEffect(() => {
    //mapのZoomとdragイベントが起きたらマップ範囲を取得する
    if (map === null) {
      return;
    }
    if (misoukouToggle === false && misoukouJson !== null) {
      if (!!misoukouLayer) {
        misoukouLayer.setMap(null);
      }
    }
    if (misoukouJson !== null && misoukouToggle === true) {
      //表示
      const misoukouLayer = new GoogleMapsOverlay({
        layers: createGeojsonLayer(misoukouJson),
      });
      misoukouLayer.setMap(map);
      setMisoukouLayer(misoukouLayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMap, map, misoukouToggle, misoukouJson]);

  if (mapmachingInfo === null) {
    return <></>;
  }
  return (
    <>
      <div
        className="titlecustom"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          padding: 0,
          margin: "5px",
        }}
      >
        not tested Segment
      </div>
      <div>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            Select Fleet No
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {misoukouIchiran.map((item) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    setMisoukouJson(null); //一度クリアする
                    const _fleetId = item.key
                      .split("fleet_id")[1]
                      .split("_")[0];
                    setMisoukouFleetId(_fleetId);
                  }}
                >
                  {`fleet Id ${item.key.split("fleet_id")[1].split("_")[0]}`}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ display: "flex" }}>
        <div
          className="titlecustom"
          style={{
            margin: "5px",
            background: `linear-gradient(transparent 80%, rgb(255, 20, 147) 80%)`,
          }}
        >
          {misoukouFleetId === null
            ? ""
            : `not tested in fleet Id ${misoukouFleetId}`}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
          }}
        >
          {misoukouFleetId === null ? (
            ""
          ) : (
            <InfoIcon
              message={`It shows rode segments which is not tested. matching map is ${mapmachingInfo.provider}-${mapmachingInfo.version}. Updated at ${lastModified}`}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {misoukouFleetId === null ? (
            ""
          ) : misoukouJson === null ? (
            <Spinner animation="border" size="sm" />
          ) : misoukouJson.geometry ? (
            <BootstrapSwitchButton
              checked={misoukouToggle}
              size="sm"
              onstyle="dark"
              onChange={() => setMisoukouToggle(!misoukouToggle)}
            />
          ) : (
            <>
              <div>unavailable-too many segments</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
