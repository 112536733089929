import moment from "moment";

//plansが変更されたときに表示すべきPlanのIdを判断して返す関数
//初期ロード時：plan配列の0番目
//新Plan作成時：新規作成したplan
//Plan削除時：plan配列の0番目

export function judgeCreatedNewPlan(plans) {
  //どうやって新Plan作成か否かを判断するか
  //==> DBから取得したPlansにはfleet_id, car_idが存在しない
  //==> 新しく作成したplanはクライアント側でplansにconcatされるためfleet_id,Car_idが存在する

  const createdNewPlans = plans.filter((p) => {
    return p.fleet_id !== undefined;
  });
  const isCreatedNewPlan = createdNewPlans.length > 0;

  //console.log(isCreatedNewPlan);
  if (isCreatedNewPlan) {
    // 現在時刻をISO 8601形式の文字列で表示→T,Zはスペースで置換
    const _now = new Date();
    const nowString = _now.toISOString().replace("T", " ").replace("Z", "");
    const now = moment(nowString);

    const addDiffTime = createdNewPlans.map((p) => {
      const created_at = moment(p.created_at);
      const diffmsec = now.diff(created_at); //単位mec
      return { ...p, diffmsec };
    });

    const createdNew = addDiffTime.filter((diff) => {
      //1分以内に作成されたplanをfilter
      return diff.diffmsec <= 6000;
    });

    if (createdNew.length === 0) {
      return plans[0];
    } else if (createdNew.length !== 0) {
      const _sorted = [...createdNew];
      _sorted.sort((a, b) => {
        if (a.diffmsec < b.diffmsec) {
          return -1;
        } else {
          return 1;
        }
      });
      //console.log("sorted", _sorted);
      //一番最新のものを返す
      return _sorted[0];
    }
  } else {
    //一番初めのものを返す
    return plans[0];
  }
}
