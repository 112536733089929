import React from "react"
import {Button, Modal, Form} from "react-bootstrap";

export function ModalMfaInput({
  mfaInputModalVisible,
  mfaInputModalClose,
  mfaInputFormSubmit,
  setMfaInput
}){
  
  return(
    <Modal 
      size='sm'
      backdrop ='static'
      keyboard = {false}
      show={mfaInputModalVisible}
      onHide={mfaInputModalClose}
    >
      <Modal.Header>
          <Modal.Title>MFA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={mfaInputFormSubmit}>
          <Form.Group>
            <Form.Label>Confirm TOTP Code</Form.Label>
            <Form.Control
                type="text"
                onChange={(e)=>setMfaInput(e.target.value)}
                required
            />
          </Form.Group>
      
          <Form.Text className="text-muted">Please Input MFA</Form.Text >

          <Button variant="secondary" className='btn-right' type="submit">
              Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}