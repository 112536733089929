import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { nextPointId } from "../../../hooks/useCurrentPlan";
import { useUser } from "../../../hooks/useUser";
import Copy from "../../../image/copy.png";
import { Plan } from "../../../types";
import { User } from "../../user";
import { kiwiApi } from "../kiwiApi";
import { kiwiApiProjects, kiwiApiVehicles } from "../../../api/kiwiApi/kiwiApis";

function ModalCopyPlan({
  copiedPlanId,
  basePlanName,
  show,
  onHide,
  plans,
  setPlans,
  setConfirmMsg,
  setConfirmModalShow,
  setConfirmTitle,
}) {
  const [allFleets, setAllFleets] = useState();
  const [defaultVec, setDefaultVec] = useState();
  const [selectedFleet, setSelectedFleet] = useState();
  const [vecsOfthisFleets, setVecsOfThisFleets] = useState();
  const [selectedVec, setSelectedVec] = useState();
  const [planName, setPlanName] = useState("");
  useEffect(() => {
    setPlanName(basePlanName)
  }, [basePlanName]);
  const [postStatus, setPostStatus] = useState(false);

  const { user } = useUser();

  useEffect(() =>{
    (async()=>{
      const rawList = await kiwiApiProjects(user.token)
      const fixedList =rawList.map((item) => {
        return {
          code : item.project_name,
          dest : item.destination,
          fleet : item.fleet,
          id : item.project_id.toString(),
          lot : item.lot,
        }      
      })
      setAllFleets(fixedList)

      const rawVecs = await kiwiApiVehicles(user.token, fixedList[0].id)
      const fixedVecs =rawVecs.vehicles_data.map((item) => {
          return {
            fleet_id : item.project_id.toString(),
            car_id : item.vehicle_id.toString(),
            name : item.vec
          }      
      })
      setDefaultVec(fixedVecs)

      setSelectedFleet(fixedList[0]);
      setVecsOfThisFleets(fixedVecs);
      setSelectedVec(fixedVecs[0]);
    })();
  },[])

  function closeModal() {
    //Modalを閉じる＆選択肢の中身の初期化
    onHide();
    setSelectedFleet(allFleets[0]);
    setVecsOfThisFleets(defaultVec);
    setSelectedVec(defaultVec[0]);
  }

  function onChangePlanName(e) {
    const newPlanName = e.target.value;
    setPlanName(newPlanName);
  }

  function onChangeVec(e) {
    const selectedVecId = e.target.value;
    const selectedVec = vecsOfthisFleets.find(
      (vec) => vec.car_id == selectedVecId
    );
    if (selectedVec == undefined) {
      console.warn("vec選択時にバグあり");
      return;
    }
    setSelectedVec(selectedVec);
  }
  
  async function onChangeFleetsCopied(e) {
    const fleet_id = e.target.value;
    // console.log("?", allFleets)
    const thisfleet = allFleets.find((fleet) => fleet.id == fleet_id);//
    // console.log("???", thisfleet.id)
    // console.log("??",thisfleet)
    if (thisfleet == undefined) {
      console.warn("fleet選択変更時にバグあり");
      return;
    }
    setSelectedFleet(thisfleet);
    const projectid = `${thisfleet.id}`
    const rawVecs = await kiwiApiVehicles(user.token, projectid)
    const fixedVecs =rawVecs.vehicles_data.map((item) => {
        return {
          fleet_id : item.project_id.toString(),
          car_id : item.vehicle_id.toString(),
          name : item.vec
        }      
      })
    setVecsOfThisFleets(fixedVecs);
    setSelectedVec(fixedVecs[0]);
  }

  function postPlan() {
    setPostStatus(true);
    if (selectedFleet == undefined || selectedVec == undefined) {
      return;
    }
    const fleet_id = selectedFleet.id
    const car_id = selectedVec.car_id

    //console.log(
    //  `plan id: ${copiedPlanId}  is copied to fleet id: ${fleet_id}, vec id:${car_id}, planName: ${planName}`
    //);

    async function getPlanAndCopyTo({
      planId,
      user
    }) {
      try {
        const origPlan = await kiwiApi(user.token).getPlan({ planId });
        const originalPlanFleetId = origPlan.fleet_id;
        const originalPlanCarId = origPlan.car_id;

        const copiedPlan = { ...origPlan };
        delete (copiedPlan).plan_id;
        copiedPlan.plan_name = planName;
        //console.log(copiedPlan);
        kiwiApi(user.token)
          .createPlan({
            newPlan: {
              ...copiedPlan,
              fleet_id,
              car_id,
              plan_name: planName,
            },
          })
          .then((response) => {
            //console.log(response);
            //Copy元と先のfleet&vecが同じ場合はPointIDを更新してPlansに追加
            if (
              originalPlanFleetId == fleet_id &&
              originalPlanCarId == car_id
            ) {
              response.points = response.points?.map((point) => {
                return { ...point, id: nextPointId() };
              });
              const newPlans = plans.concat(response);
              setPlans(newPlans);
            }
            closeModal();
            setPostStatus(false);
            setConfirmTitle("SUCESS! copy Route");
            setConfirmMsg("The route has been copied successfully!");
            setConfirmModalShow(true);
          })
          .catch((error) => {
            console.log(error);
            closeModal();
            setPostStatus(false);

            setConfirmTitle("ERROR! copy Route");
            setConfirmMsg(error.message);
            setConfirmModalShow(true);
          });
      } catch (error) {
        console.log("debug", error);
        return error;
      }
    }

    getPlanAndCopyTo({planId: copiedPlanId, user});
  }

  return (
    <Modal
      show={show}
      onHide={closeModal}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="titlecustom">
          Copy this route
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
        <Form.Group controlId="Form.copy-proj">
          <Form.Control
            as="select"
            // ref={refSelectFleet}
            onChange={onChangeFleetsCopied}
          >
            {allFleets == undefined? <></> : allFleets.map((fleet, key) => (
              <option
                value={fleet.id}
                key={key}
              >{`${fleet.code}-${fleet.lot}-${fleet.dest}-${fleet.fleet}`}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="Form.copy-vec">
          <Form.Control as="select" onChange={onChangeVec}>
            {vecsOfthisFleets == undefined? <></> : vecsOfthisFleets.map((vec, key) => (
              <option value={vec.car_id} key={key}>
                {vec.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="Form.copy-route-name">
          <h4>route name:</h4>
          <Form.Control
            type="text"
            value = {planName}
            onChange={onChangePlanName}
            maxLength={256}
          />
          <Form.Text className="text-muted">
            *limits to 250 characters
          </Form.Text>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
        <Button onClick={postPlan} variant="red">
          Copy this Route
          {postStatus ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : null}
        </Button>
        <Button onClick={onHide} variant="grey">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}



export function ModalbtnCopyPlan({
  copiedPlanId,
  basePlanName,
  plans,
  setPlans,
  setConfirmModalShow,
  setConfirmTitle,
  setConfirmMsg,
}) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <style type="text/css">
        {`
    .btn-red {
      background-color: #A10202;
      color: white;
    }
    `}
      </style>
      <Button
        size="sm"
        variant="red"
        onClick={() => setModalShow(true)}
        style={{ margin: "5px" }}
      >
        <img
          src={Copy}
          width="20"
          height="20"
          alt="copy this plan"
          title="copy this plan"
        />
      </Button>

      <ModalCopyPlan
        copiedPlanId={copiedPlanId}
        basePlanName={basePlanName}
        show={modalShow}
        onHide={() => setModalShow(false)}
        plans={plans}
        setPlans={setPlans}
        setConfirmModalShow={setConfirmModalShow}
        setConfirmTitle={setConfirmTitle}
        setConfirmMsg={setConfirmMsg}
      />
    </>
  );
}
