import { useEffect, useState } from "react";
//vec画面とResult画面で使用
export function useOverviewMap({
  googleMap,
  mapContainerRef,
  initialConfig,
  mapSearchBoxRef,
  mapMileageBoxRef,
  returnInfoWindowPlan,
  returnInfoWindowResult,
  pointInfoWindow,
  infoWindowsPlan,
  infoWindowsResult,
  infoWindowsFileImport,
}) {
  const [map, setMap] = useState(null);

  useEffect(
    () => {
      // googleMapかmapContainerRefが初期化されてなければ何もしない
      if (!googleMap || !mapContainerRef.current) {
        return;
      }

      const MapTypeIdROADMAP = googleMap.maps.MapTypeId.ROADMAP;
      initialConfig.mapTypeId = MapTypeIdROADMAP;

      //配置場所の定義
      //https://developers.google.com/maps/documentation/javascript/reference/control#ControlPosition

      initialConfig.zoomControlOptions = {
        position: googleMap.maps.ControlPosition.RIGHT_TOP, //拡大縮小コントロールの位置
      };
      initialConfig.streetViewControlOptions = {
        position: googleMap.maps.ControlPosition.RIGHT_TOP, //ストリートビュー コントロールの位置
      };

      const map = new googleMap.maps.Map(
        mapContainerRef.current,
        initialConfig
      );

      //検索Windowを地図に配置
      mapSearchBoxRef.current.style.display = "block";
      map.controls[googleMap.maps.ControlPosition.TOP_RIGHT].push(
        mapSearchBoxRef.current
      );

      //MileagePaneがある時は上部真ん中に配置する
      if (mapMileageBoxRef) {
        mapMileageBoxRef.current.style.display = "block";
        map.controls[googleMap.maps.ControlPosition.TOP_CENTER].push(
          mapMileageBoxRef.current
        );
      }

      setMap(map);
    },
    // googleMapかmapContainerRefが変化したらeffectが発火する。
    // initialConfigは変わったとしても再マウントするとおかしなことになるので更新対象にしない
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googleMap, mapContainerRef.current]
  );

  useEffect(() => {
    if (!map) {
      return;
    }
    const listener = map.data.addListener("click", (event) => {
      //console.log("overvieMap");
      //console.log(event);
      const featureId = event.feature.getId();
      if (featureId.startsWith("selectedRoutePointsPlan")) {
        const type = event.feature.getProperty("type");
        const label = event.feature.getProperty("label");
        const comment = event.feature.getProperty("comment");

        if (label !== undefined) {
          //Pinがクリックされた場合
          const infowindow = returnInfoWindowPlan({
            type,
            label,
            comment,
            googleMap,
            event,
          });
          const isOpend = event.feature.getProperty("isOpend");
          if (!isOpend) {
            event.feature.setProperty("isOpend", true);
            infowindow.addListener("closeclick", function () {
              event.feature.setProperty("isOpend", false);
            });
            infowindow.open(map);
            infoWindowsPlan.push(infowindow);
          }
        }
      } else if (featureId.startsWith("selectedRoutePointsResult")) {
        const issueNo = event.feature.getProperty("issue_no");
        const priority = event.feature.getProperty("priority");
        const label = event.feature.getProperty("label");
        const comment = event.feature.getProperty("comment");

        if (label !== undefined) {
          //const mode = "result";
          //resultの場合
          //infoWindowの表示処理
          const infoWindowResult = returnInfoWindowResult({
            priority,
            issueNo,
            comment,
            googleMap,
            event,
          });
          const isOpend = event.feature.getProperty("isOpend");
          if (!isOpend) {
            event.feature.setProperty("isOpend", true);
            infoWindowResult.addListener("closeclick", function () {
              event.feature.setProperty("isOpend", false);
            });
            infoWindowResult.open(map);
            infoWindowsResult.push(infoWindowResult);
          }
        }
      } else if (
        featureId.startsWith("importedFromCSV") ||
        featureId.startsWith("importedFromKML")
      ) {
        const pointInfo = pointInfoWindow(googleMap, event);
        const fileName = event.feature.getProperty("fileName");
        const isOpend = event.feature.getProperty("isOpend");
        if (!isOpend) {
          event.feature.setProperty("isOpend", true);

          pointInfo.addListener("closeclick", function () {
            event.feature.setProperty("isOpend", false);
          });
          pointInfo.open(map);
          infoWindowsFileImport.push({ fileName, infoWindow: pointInfo });
        }
      }
    });

    return () => {
      listener.remove();
    };
  }, [
    googleMap,
    infoWindowsFileImport,
    infoWindowsPlan,
    infoWindowsResult,
    map,
    pointInfoWindow,
    returnInfoWindowPlan,
    returnInfoWindowResult,
  ]);
  return map;
}
