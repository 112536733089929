import { kiwiApi } from "./kiwiApi";

/**
 * ルート名一覧を取得する
 *
 */
export async function getPlans({
  fleetId,
  carId,
  setPlans,
  user,
  setGetPlanStatus,
}) {
  try {
    setGetPlanStatus(true);
    const planNames = await kiwiApi(user.token).getPlans({
      fleetId,
      carId,
    });
    //console.debug("getPlanNames", planNames);

    setPlans(planNames);
  } catch (error) {
    //console.debug("getPlanNames", error);
    setGetPlanStatus(false);
  }
}
