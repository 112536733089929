import { GeoJsonLayer } from "@deck.gl/layers";

export function createGeojsonLayer(features) {
  return new GeoJsonLayer({
    id: 1,
    data: features,
    extruded: true,
    filled: true,
    // getElevation: 30,
    // getFillColor: [255, 165, 0, 255],
    getLineColor: [255, 20, 147, 120],
    // getLineColor: (f) => {
    //   const hex = f.properties.color;
    //   // convert to RGB
    //   return hex
    //     ? hex.match(/[0-9a-f]{2}/g).map((x) => parseInt(x, 16))
    //     : [0, 0, 0];
    // },
    getLineWidth: 20,
    // getRadius: (pointRadiusMaxPixels) => {
    //   return pointRadiusMaxPixels;
    // },
    // pointRadiusMinPixels: 5,
    // pointRadiusMaxPixels: 5,
    // opacity: 0.2,
    // pointRadiusScale: 0.3,
    // lineJointRounded: false,
    // lineMiterLimit: 4,
    // lineWidthMaxPixels: 50,
    lineWidthMinPixels: 5,
    // lineWidthScale: 20,
    // lineWidthUnits: "meters",
    // material: true,
    // pointRadiusUnits: "pixel",
    // stroked: false,
    // wireframe: false,

    /* props inherited from Layer class */

    // autoHighlight: false,
    // coordinateOrigin: [0, 0, 0],
    // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    // highlightColor: [0, 0, 128, 128],
    // modelMatrix: null,
    // pickable: true,
    // visible: true,
    // wrapLongitude: false,
    // onDataLoad: (_) => {
    //   progress.done(); // hides progress bar
    // },
  });
}
