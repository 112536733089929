import React, {useRef} from "react";
import { Button, Modal, Form } from "react-bootstrap";

export function ModalPOIDBDelete({
    poidbDeleteModalShow,
    poidbDeleteModalCancelClick,
    poidbDeleteModalDeleteClick,
    poidbDeleteInputPassword,
    poidbDeleteList,
    poidbDeleteListChange,
  }) {
    const refSelectPOIDB = useRef(null);

    return (
      <>
        <Modal
            backdrop ='static'
            keyboard = {false}
            show={poidbDeleteModalShow}
            onHide={poidbDeleteModalCancelClick}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Header style={{ backgroundColor: "#EDEDED" }} closeButton>
            <Modal.Title className="titlecustom">
                POIDB Delete
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#EDEDED" }}>
            <Form.Group>
              <Form.Label
                  className="titlecustom"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                  Select Title
              </Form.Label>
              <Form.Control
                as="select"
                onChange={poidbDeleteListChange}
                ref={refSelectPOIDB}
              >
                <option value={""} key={""}> -- Select --</option>
                {poidbDeleteList.map((e)=>(
                  <option value={e.poi_id} key={e.poi_id}>{e.title}</option>
                ))}

              </Form.Control>
              <Form.Label
                  className="titlecustom"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                  PassWord
              </Form.Label>
              <Form.Control type="password" ref={poidbDeleteInputPassword} maxLength="64" placeholder="Password"/>

            </Form.Group>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#EDEDED" }}>
            <Button variant="outline-dark" onClick={poidbDeleteModalCancelClick}>
              Cancel
            </Button>
            <Button variant="dark" onClick={poidbDeleteModalDeleteClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }