import React, { useEffect, useRef } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";

/**
 * ルートのポイント情報を地図下部に表示するコンポーネント
 *
 * @param {boolean} isPointCommentOpened
 * @param {Object} currentPoint
 * @param {string} mode　'plan' || 'results' || 'fieldTest'
 */
export const PlanPointDetail = ({
  isPointCommentOpened,
  setIsPointCommentOpened,
  currentPoint,
  setCurrentPoint,
  setComment,
  mode,
}) => {
  const pointCommentRef = useRef(null);
  // const btnStylePointType = {
  //   margin: "5px",
  //   width: "100px",
  // };
  useEffect(() => {
    if (currentPoint === null) {
      return;
    }
    if (isPointCommentOpened) {
      // console.log(isClickedPoint);
      pointCommentRef.current.value = currentPoint.comment
        ? currentPoint.comment
        : "";
    }
  }, [currentPoint, isPointCommentOpened]);

  function close() {
    setCurrentPoint(null);
    setIsPointCommentOpened(!isPointCommentOpened);
  }

  function changeComment(e) {
    // console.log(e.target.value);
    setComment(currentPoint.id, pointCommentRef.current.value);
  }
  //console.log('can?',currentPoint)
  if (isPointCommentOpened) {
    return (
      <>
        <Card style={{ backgroundColor: "#EDEDED" }}>
          <Card.Body>
            <Container>
              <Row>
                <Col sm={4}>
                  <Card.Title>{`COMMENT`}</Card.Title>
                </Col>
                <Col sm={8}>
                  <Card.Subtitle>{`point: ${currentPoint.id}, (Lat: ${
                    Math.round(currentPoint.lat * 1000000) / 1000000 //小数点6桁までにする
                  }, Lon: ${
                    Math.round(currentPoint.lng * 1000000) / 1000000 //小数点6桁までにする
                  })`}<br/>{`distance from before point: ${currentPoint.distanceFromBefore == 0 || currentPoint.distanceFromBefore == undefined ? '-': `${currentPoint.distanceFromBefore}km`}`}</Card.Subtitle>
                </Col>
              </Row>
              <Form>
                <Row>
                  <Col xl={10}>
                    <Form.Control
                      as="textarea"
                      ref={pointCommentRef}
                      onChange={changeComment}
                      style={{ margin: "5px" }}
                      maxLength="1000"
                      defaultValue={
                        currentPoint.comment ? currentPoint.comment : ""
                      }
                      disabled={mode === "fieldTest"}
                    />
                    {mode === "fieldTest" ? null : (
                      <Form.Text className="text-muted">
                        *limits to 1000 characters
                      </Form.Text>
                    )}
                  </Col>
                  <Col xl={2}>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        onClick={close}
                        variant="grey"
                        style={{ margin: "5px" }}
                      >
                        close
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Card.Body>
        </Card>
      </>
    );
  } else {
    return <></>;
  }
};
