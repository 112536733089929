import { useEffect, useState } from "react";

/**
 * @param {object} props
 * @param {google.maps.Map} props.map
 * @param {google} props.googleMap
 * @param {React.RefObject<HTMLInputElement>} props.placeSearchInputRef
 */
export function usePlaceSearchbox({
  googleMap,
  map,
  placeSearchInputRef,
  addPoint,
}) {
  const [placesMarkers, setPlacesMarkers] = useState([]); //Place検索した結果のマーカーを格納
  const [clickedMarker, setClickedMarker] = useState(null); //clickされたマーカーを格納。PINに置き換え後はnullにする

  useEffect(() => {
    if (!googleMap || !map) {
      return;
    }
    const searchBox = new googleMap.maps.places.SearchBox(
      placeSearchInputRef.current
    );

    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    let placesMarkers = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      // console.log("here is place:",places);
      if (places.length === 0) {
        setClickedMarker(null);
        placesMarkers.forEach((marker) => {
          marker.setMap(null);
        });
        return;
      } // Clear out the old markers.

      placesMarkers.forEach((marker) => {
        marker.setMap(null);
      });
      placesMarkers = []; // For each place, get the icon, name and location.

      const bounds = new googleMap.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        const icon = {
          path: "M -8,-8 8,8 M 8,-8 -8,8",
          strokeColor: "red",
          strokeWeight: 4.0,
        };

        placesMarkers.push(
          new googleMap.maps.Marker({
            map,
            icon,
            title: `${place.name},place_id${place.place_id}`,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      setPlacesMarkers(placesMarkers);
      map.fitBounds(bounds);
    });
  }, [addPoint, googleMap, map, placeSearchInputRef]);

  useEffect(() => {
    if (placesMarkers.length === 0) {
      return;
    }
    placesMarkers.forEach((placeMarker) => {
      const infowin = new googleMap.maps.InfoWindow({
        content: "",
        closeBoxMargin: "0px 0px -30px 0px",
      });
      const lat = Number(placeMarker.position.lat());
      const lng = Number(placeMarker.position.lng());
      const placeName = placeMarker.title.split(",place_id")[0];
      const mode = addPoint !== undefined ? "plan" : "notPlan";

      placeMarker.addListener("mouseout", function () {
        infowin.close();
      });

      placeMarker.addListener("mouseover", function () {
        const infowinELement = document.createElement("div");
        infowinELement.innerHTML = returnInfoWindowElement(
          mode,
          placeName,
          lat,
          lng
        );
        infowin.setContent(infowinELement);
        infowin.open(map, placeMarker);
      });
      //plan画面の場合はclickイベントを指定する
      if (mode === "plan") {
        placeMarker.addListener("click", function (e) {
          setClickedMarker(placeMarker);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placesMarkers]);

  useEffect(() => {
    if (clickedMarker === null) {
      return;
    }
    addPoint(clickedMarker.position.lat(), clickedMarker.position.lng());
    setClickedMarker(null);
  }, [addPoint, clickedMarker]);
}

//winfoWIndowの要素を作成する
function returnInfoWindowElement(mode, placeName, lat, lng) {
  if (mode === "plan") {
    //plan画面
    return (
      `<div style="font-weight: bold; margin:2px;">${placeName}</div>` +
      `<div>(${lat} , ${lng})</div>` +
      `<div style="color: #a10202; font-weight:bold;">You can add PIN by click Marker</div>`
    );
  } else {
    //result,fieldTest画面
    return (
      `<div style="font-weight: bold; margin:2px;">${placeName}</div>` +
      `<div>(${lat} , ${lng})</div>`
    );
  }
}
