import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { DownloadPlans } from "./DownloadPlans";

export const PlanRoutesPane = ({
  planRoutes,
  selectedRoute,
  setSelectedRoute,
  map,
  fleetName,
  carName,
}) => {
  planRoutes.sort(function (a, b) {return a.plan_name.localeCompare(b.plan_name);})
  //allCheck status機能
  const [isAllChecked, setIsAllChecked] = useState(true);

  /**checkedItemsの状態管理
   * checkedItemsのkeyはplan_id.valueはcheckedと連動
   */
  const [checkedItems, setCheckedItems] = useState({});
  const CheckBox = ({ id, value, checked, onChange }) => {
    return (
      <input
        id={id}
        type="checkbox"
        name="inputNames"
        checked={checked}
        onChange={onChange}
        value={value}
        style={{ padding: "2px", width: "15px", height: "15px" }}
      />
    );
  };

  useEffect(() => {
    if (planRoutes === undefined || planRoutes === null) {
      return;
    }
    const iniCheckedItems = {};
    planRoutes.forEach((r) => {
      iniCheckedItems[r.plan_id] = true;
    });
    //console.log(iniCheckedItems);
    setCheckedItems(iniCheckedItems);
  }, [planRoutes]);

  useEffect(() => {
    if (checkedItems == {}) {
      return;
    }
    const ids = Object.keys(checkedItems);
    const _isExistsFalse = ids.some((id) => {
      return checkedItems[id] === false;
    });
    //console.log("_isExistsFalse", _isExistsFalse);
    setIsAllChecked(!_isExistsFalse);
  }, [checkedItems]);

  useEffect(() => {
    if (checkedItems == {} || map === null) {
      return;
    }
    const ids = Object.keys(checkedItems);
    ids.forEach((id) => {
      const feature = map.data.getFeatureById(id);
      map.data.overrideStyle(feature, {
        visible: checkedItems[id],
      });
    });
  }, [checkedItems, map]);

  function handleChange(e) {
    //checkedItemsのstateをセット
    setCheckedItems({
      ...checkedItems,
      [e.target.id]: e.target.checked,
    });
    setSelectedRoute({});
  }
  function onChangeALLcheckbox(isAllChecked) {
    //isAllChecked=trueなら今全選択なので、すべてfalseにする
    const iniCheckedItems = {};
    planRoutes.forEach((r) => {
      iniCheckedItems[r.plan_id] = !isAllChecked;
    });
    setCheckedItems(iniCheckedItems);
  }

  return (
    <>
      <Card style={{ height: "33%", padding: 0 }}>
        <Card.Header
          className="titlecustom"
          style={{
            padding: "5px",
            color: "#585858",
            fontSize: "18px",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Plan Routes
          </div>

          <DownloadPlans
            planRoutes={planRoutes}
            checkedItems={checkedItems}
            fleetName={fleetName}
            carName={carName}
          />

          <div
            style={{
              marginLeft: "auto",
              marginRight: "0",
              paddingRight: "10px",
            }}
          >
            <label
              style={{
                margin: "auto",
              }}
            >
              <input
                type="checkbox"
                checked={isAllChecked}
                onChange={() => {
                  onChangeALLcheckbox(isAllChecked);
                }}
                style={{
                  margin: "2px",
                  width: "15px",
                  height: "15px",
                }}
              />
              ALL
            </label>
          </div>
        </Card.Header>
        <Card.Body
          style={{ height: "100%", overflowY: "scroll", padding: "5px" }}
        >
          <Table variant="flush">
            <tbody>
              {planRoutes.map((plan) => (
                <tr key={plan.plan_id}>
                  <td
                    style={{
                      margin: "5px",
                      padding: "5px 10px",
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    <CheckBox
                      id={plan.plan_id}
                      value={plan.plan_id}
                      onChange={handleChange}
                      checked={checkedItems[plan.plan_id]}
                    />
                  </td>
                  <td
                    style={{
                      fontSize: "12px",
                      padding: "5px",
                      width: "80%",
                      color:
                        selectedRoute.plan_id === plan.plan_id
                          ? "white"
                          : "black",
                      backgroundColor:
                        selectedRoute.plan_id === plan.plan_id
                          ? "grey"
                          : "white",
                    }}
                    onClick={() => {
                      setSelectedRoute(
                        planRoutes.find((r) => r.plan_id === plan.plan_id)
                      );
                    }}
                  >
                    {plan.plan_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};
