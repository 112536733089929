import React from "react";
import { Accordion } from "react-bootstrap";
import { EvalPointsDetail } from "./evalPoints/EvalPointsDetail";
import { TestPositionPane } from "./fieldTest/TestPositionPane";
import { PlanDetail } from "./PlanDetail";

/**
 * 画面一番左のルート選択＆情報表示部分のコンポーネント
 *
 * @param {string} fleetId
 * @param {string} vecId
 * @param {object} route
 * @param {object} location
 * @param {string} mode 'plan' || 'fieldTest'
 */
export function NaviPane({
  fleetId,
  vecId,
  plans,
  setPlans,
  route,
  setRoute,
  setName,
  setRouteComment,
  map,
  googleMap,
  setGetPlanStatus,
  getPosition, //only FTmode
  location, //only FTmode
  mode,
  addPoints,
  watchId, //only FTmode
  stopWatch, //only FTmode
}) {
  if (plans === null) {
    return <p>Loading routes....</p>;
  }
  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <Accordion defaultActiveKey="0">
        <PlanDetail
          fleetId={fleetId}
          vecId={vecId}
          plans={plans}
          setPlans={setPlans}
          route={route}
          setRoute={setRoute}
          setName={setName}
          setRouteComment={setRouteComment}
          map={map}
          googleMap={googleMap}
          setGetPlanStatus={setGetPlanStatus}
          mode={mode}
        />

        {mode === "fieldTest" ? (
          <TestPositionPane
            getPosition={getPosition}
            location={location}
            watchId={watchId}
            stopWatch={stopWatch}
            route={route}
          />
        ) : mode === "plan" ? (
          <EvalPointsDetail
            googleMap={googleMap}
            map={map}
            addPoints={addPoints}
            fleetId={fleetId}
          />
        ) : null}
      </Accordion>
    </div>
  );
}
