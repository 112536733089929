/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllVec
 */
export interface AllVec {
    /**
     * 
     * @type {string}
     * @memberof AllVec
     */
    types: AllVecTypesV4Compat;
    /**
     * 
     * @type {string}
     * @memberof AllVec
     */
    fleet_id: string;
    /**
     * 
     * @type {string}
     * @memberof AllVec
     */
    car_id: string;
}

/**
* @export
* @enum {string}
*/
export enum AllVecTypesV4Compat {
    Plans = 'plans',
    Results = 'results'
}

export function AllVecFromJSON(json: any): AllVec {
    return AllVecFromJSONTyped(json, false);
}

export function AllVecFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllVec {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': json['types'],
        'fleet_id': json['fleet_id'],
        'car_id': json['car_id'],
    };
}

export function AllVecToJSON(value?: AllVec | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'types': value.types,
        'fleet_id': value.fleet_id,
        'car_id': value.car_id,
    };
}


