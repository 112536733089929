/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssuePoint
 */
export interface IssuePoint {
    /**
     * 
     * @type {number}
     * @memberof IssuePoint
     */
    issue_no: number;
    /**
     * 
     * @type {number}
     * @memberof IssuePoint
     */
    lng: number;
    /**
     * 
     * @type {string}
     * @memberof IssuePoint
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof IssuePoint
     */
    styleurl: string;
    /**
     * 
     * @type {string}
     * @memberof IssuePoint
     */
    priority: string;
    /**
     * 
     * @type {number}
     * @memberof IssuePoint
     */
    lat: number;
}

export function IssuePointFromJSON(json: any): IssuePoint {
    return IssuePointFromJSONTyped(json, false);
}

export function IssuePointFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssuePoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_no': json['issue_no'],
        'lng': json['lng'],
        'description': json['description'],
        'styleurl': json['styleurl'],
        'priority': json['priority'],
        'lat': json['lat'],
    };
}

export function IssuePointToJSON(value?: IssuePoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_no': value.issue_no,
        'lng': value.lng,
        'description': value.description,
        'styleurl': value.styleurl,
        'priority': value.priority,
        'lat': value.lat,
    };
}


