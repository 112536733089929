import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Container,
  Spinner,
  Image,
} from "react-bootstrap";
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import styles from "./SignIn.module.css";
import Icon from "../../image/yellow.jpg";
import { ModalMfaInput } from "./ModalMfaInput";
import { ModalMessage } from "./ModalMessage";

export function SignInForms() {
  const { status, error, signIn, resetStatus } = useUser();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mfaInput, setMfaInput] = useState("");
  const [user, setUser] = useState();

  const [mfaInputModalVisible, setMfaInputModalVisible] = useState(false)
  const mfaInputModalClose = () => setMfaInputModalVisible(false)
  const mfaInputModalShow = () => setMfaInputModalVisible(true)

  const [messageModalVisible, setMessageModalVisible] = useState(false)
  const messageModalClose = () => setMessageModalVisible(false)
  const messageModalshow = () => setMessageModalVisible(true)
  const [messageModalMessage, setMessageModalMessage] = useState("")

  const onEmailChanged: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setEmail(e.target.value);
  const onPasswordChanged: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setPassword(e.target.value);

  async function onFormSubmitted(e: React.FormEvent) {
    e.preventDefault();
    try{
      const cognitoUser = await Auth.signIn(email, password);
      setUser(cognitoUser)
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        setMessageModalMessage("[Access Denied] Do password setting first!!")
        messageModalshow()
        return
      }
      if (cognitoUser.challengeName === "MFA_SETUP") {
        setMessageModalMessage("[Access Denied] Do MFA setting first!!")
        messageModalshow()
        return
        
      }
      if (cognitoUser.challengeName === "SOFTWARE_TOKEN_MFA") {
        mfaInputModalShow()
        return
      }
      const succeeded = await signIn(cognitoUser);
      if (succeeded) {
        history.push("/Fleets");
      }
    }catch(err: any){
      console.log("login Failed: ", err)
      setMessageModalMessage(`Failed Login: \n${err.message.toString()}`)
      messageModalshow()
    }
  }

  async function mfaInputFormSubmit(e: React.FormEvent) {
    e.preventDefault()
    try{
      const mfaUser = await Auth.confirmSignIn(user, mfaInput,"SOFTWARE_TOKEN_MFA");
      // console.log("mfa",mfaUser)
      const succeeded = await signIn(mfaUser);
      if (succeeded) {
        history.push("/Fleets");
      }
    }catch(err: any){
      mfaInputModalClose()
      console.log("fail to login: ", err)
      setMessageModalMessage(`fail to login: \n${err.message.toString()}`)
      messageModalshow()
    }
  }

  const pending = status === "signinning";
  const failed = status === "signin_failure";
  return (
    <Col
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "50px",
        maxWidth: "700px",
      }}
    >
      <Container>
        <Row>
          <Col md={"auto"} className={` ${styles["signinTitle"]}`}>
            <Image style={{ border: "none" }} src={Icon} thumbnail />
          </Col>
        </Row>
        <div className={styles.signinCardBody}>
          <Card>
            <Card.Header className="titlecustom">LOGIN</Card.Header>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">
                Please enter your user name and password.
              </Card.Subtitle>
              <Form onSubmit={onFormSubmitted}>
                <Form.Group>
                  <Form.Label>Email Adress</Form.Label>
                  <Form.Control
                    type="email"
                    autoComplete="email"
                    onChange={onEmailChanged}
                    onBlur={resetStatus}
                    disabled={pending}
                    isInvalid={failed}
                    required
                  />
                  <Form.Text className="text-muted">
                    {/* We'll never share your email with anyone else. */}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    autoComplete="current-password"
                    onChange={onPasswordChanged}
                    onBlur={resetStatus}
                    disabled={pending}
                    isInvalid={failed}
                    required
                  />
                </Form.Group>

                <Container>
                  <Row style={{ flexFlow: "row-reverse" }}>
                    <Col md={4} style={{ padding: "0" }}>
                      <Button
                        variant="red"
                        block
                        type="submit"
                        disabled={pending}
                      >
                        LOGIN
                        {pending && (
                          <Spinner
                            animation={"border"}
                            size="sm"
                            className={styles["spinner"]}
                          />
                        )}
                      </Button>
                    </Col>
                    {failed && (
                      <Col style={{ alignSelf: "center" }}>
                        <Form.Text className={styles["errorMessage"]}>
                          {error}
                        </Form.Text>
                      </Col>
                    )}
                  </Row>
                </Container>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
      <ModalMfaInput
        mfaInputModalVisible={mfaInputModalVisible}
        mfaInputModalClose={mfaInputModalClose}
        mfaInputFormSubmit={mfaInputFormSubmit}
        setMfaInput={setMfaInput}
      />
      <ModalMessage
        messageModalVisible={messageModalVisible}
        messageModalOKClick={messageModalClose}
        messageModalMessage={messageModalMessage}
      />
    </Col>
  );
}
