/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IssuePoint,
    IssuePointFromJSON,
    IssuePointFromJSONTyped,
    IssuePointToJSON,
    PathGeoJSON,
    PathGeoJSONFromJSON,
    PathGeoJSONFromJSONTyped,
    PathGeoJSONToJSON,
} from './';

/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    distance: number;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    created_by: string;
    /**
     * 
     * @type {PathGeoJSON}
     * @memberof Result
     */
    path_geojson: PathGeoJSON;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    result_name: string;
    /**
     * 
     * @type {object}
     * @memberof Result
     */
    result_id: object;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    fleet_id: string;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    time: number;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    car_id: string;
    /**
     * 
     * @type {Array<IssuePoint>}
     * @memberof Result
     */
    issue_points?: Array<IssuePoint>;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    comment?: string;
}

export function ResultFromJSON(json: any): Result {
    return ResultFromJSONTyped(json, false);
}

export function ResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): Result {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'distance': json['distance'],
        'created_at': json['created_at'],
        'created_by': json['created_by'],
        'path_geojson': PathGeoJSONFromJSON(json['path_geojson']),
        'updated_at': json['updated_at'],
        'result_name': json['result_name'],
        'result_id': json['result_id'],
        'updated_by': json['updated_by'],
        'fleet_id': json['fleet_id'],
        'time': json['time'],
        'car_id': json['car_id'],
        'issue_points': !exists(json, 'issue_points') ? undefined : ((json['issue_points'] as Array<any>).map(IssuePointFromJSON)),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function ResultToJSON(value?: Result | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'distance': value.distance,
        'created_at': value.created_at,
        'created_by': value.created_by,
        'path_geojson': PathGeoJSONToJSON(value.path_geojson),
        'updated_at': value.updated_at,
        'result_name': value.result_name,
        'result_id': value.result_id,
        'updated_by': value.updated_by,
        'fleet_id': value.fleet_id,
        'time': value.time,
        'car_id': value.car_id,
        'issue_points': value.issue_points === undefined ? undefined : ((value.issue_points as Array<any>).map(IssuePointToJSON)),
        'comment': value.comment,
    };
}


