interface kmlParserProps {
  lat: number;
  lng: number;
  name: string;
  comment: string;
  fileName: string;
  type: string;
  kmlStr?: string;
}

class KmlPoint {
  lat: number;
  lng: number;
  name: string;
  comment: string;
  fileName: string;
  type: string;
  constructor({ lat, lng, name, comment, fileName, type }: kmlParserProps) {
    this.lat = lat;
    this.lng = lng;
    this.name = name;
    this.comment = comment;
    this.fileName = fileName;
    this.type = type;
  }
}
//featureのクラス
class GeoJsonFeatureKML {
  type: string;
  properties: object;
  geometry: object;
  constructor({ lat, lng, name, comment, fileName, type }: kmlParserProps) {
    this.type = "Feature";
    this.properties = { comment, fileName, name, type };
    this.geometry = { type: "Point", coordinates: [lng, lat] };
  }
}
//featureCollectionのクラス
class GeoJsonFeatureCollection {
  type: string;
  features: GeoJsonFeatureKML[];
  constructor(features: GeoJsonFeatureKML[]) {
    this.type = "FeatureCollection";
    this.features = features;
  }
}

//kmlからPoint情報を取得してくる
export function returnPointsFromKML({ kmlStr, fileName }: kmlParserProps) {
  const points: KmlPoint[] = [];
  const parser = new DOMParser();
  if (kmlStr === undefined) {
    return;
  }
  const dom = parser.parseFromString(kmlStr, "application/xml");
  //console.log(dom);
  const placeMarks = dom.getElementsByTagName("Placemark");
  //placeMarksはHTMLcollectionでArrayライクなObjectらしい
  //ArrayライクなObjectは以下でforEachできる
  Array.prototype.forEach.call(placeMarks, function (mark) {
    const name = mark.getElementsByTagName("name")[0].innerHTML;
    const comment =
      mark.getElementsByTagName("description")[0] === undefined
        ? ""
        : mark.getElementsByTagName("description")[0].innerHTML;
    //commentの内容については、kmlのどのタグに存在するのか、要確認。
    const type =
      mark.getElementsByTagName("Point")[0] !== undefined
        ? "Point"
        : mark.getElementsByTagName("LineString")[0] !== undefined
        ? "LineString"
        : "other";
    if (type !== "Point") {
      return;
    } else {
      const coordinates = mark
        .getElementsByTagName("Point")[0]
        .getElementsByTagName("coordinates")[0].innerHTML;
      const lng = Number.parseFloat(coordinates.split(",")[0]);
      const lat = Number.parseFloat(coordinates.split(",")[1]);
      const point = new KmlPoint({
        lat,
        lng,
        name,
        comment,
        fileName,
        type: "kmlPoint",
      });

      points.push(point);
    }
  });
  return points;
}

export function createPointGeoJsonKML(points: KmlPoint[]) {
  const features = points.map((p) => {
    return new GeoJsonFeatureKML(p);
  });

  const pointsGeoJson = new GeoJsonFeatureCollection(features);
  //console.log(pointsGeoJson);
  return pointsGeoJson;
}
