/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PathGeoJSONGeometry,
    PathGeoJSONGeometryFromJSON,
    PathGeoJSONGeometryFromJSONTyped,
    PathGeoJSONGeometryToJSON,
} from './';

/**
 * 
 * @export
 * @interface PathGeoJSON
 */
export interface PathGeoJSON {
    /**
     * 
     * @type {PathGeoJSONGeometry}
     * @memberof PathGeoJSON
     */
    geometry?: PathGeoJSONGeometry;
    /**
     * 
     * @type {string}
     * @memberof PathGeoJSON
     */
    type?: PathGeoJSONTypeV4Compat;
}

/**
* @export
* @enum {string}
*/
export enum PathGeoJSONTypeV4Compat {
    Feature = 'Feature'
}

export function PathGeoJSONFromJSON(json: any): PathGeoJSON {
    return PathGeoJSONFromJSONTyped(json, false);
}

export function PathGeoJSONFromJSONTyped(json: any, ignoreDiscriminator: boolean): PathGeoJSON {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometry': !exists(json, 'geometry') ? undefined : PathGeoJSONGeometryFromJSON(json['geometry']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function PathGeoJSONToJSON(value?: PathGeoJSON | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry': PathGeoJSONGeometryToJSON(value.geometry),
        'type': value.type,
    };
}


